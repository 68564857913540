import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Row, Col, Form } from 'antd';
import { EtaButton, EtaInput, EtaSelect, EtaInputMask, EtaForm } from 'components/ui';
import { notifications } from 'core/utils/common/notifications';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCountriesCities, getCitiesTaxOffices, getCitiesTowns } from 'store/slice/location.slice';
import { getBusinessSectors } from 'store/slice/customer.slice';
import { getIntro } from 'store/slice/person.slice';
import { getBanks, getBranches } from 'store/slice/bank.slice';
import { ibanValidator } from 'core/utils/validators';
import { clearIban } from 'core/utils/cleaners';
import { putAsCustomer, putAsSupplier } from 'store/slice/account.slice';
import { useAuth } from 'hooks/auth/use-auth';
import { RoutePaths } from 'router/paths';

const RegisterAsCompany = (props) => {
    const { onCancel, accountType } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { onSelectCompany } = useAuth();

    const [selectValue, setSelectValue] = useState({});
    const [loading, setLoading] = useState(false);
    const [asCustomer, setCompany] = useState(accountType == 8);

    const [ form ] = EtaForm.useForm();

    const { countriesCities } = useSelector((state) => state.location);
    const { businessSectors } = useSelector((state) => state.customer);
    const { banks, branches } = useSelector((state) => state.bank);

    useLayoutEffect(() => {
        if (asCustomer) {
            dispatch(getBusinessSectors());
        }else{
            dispatch(getCountriesCities());
            dispatch(getBanks());
        }
    }, []);

    useEffect(() => {
        if (selectValue?.bankCityId) {
            getBankBranches();
        }
    }, [selectValue?.bankCityId]);

    useEffect(() => {
        if (selectValue?.taxOfficeCity) {
            dispatch(getCitiesTaxOffices({cityId:selectValue?.taxOfficeCity}));
            form.resetFields(['taxOfficeId']);
            setSelectValue({ ...selectValue, taxOfficeId: null });
        }
    }, [selectValue?.taxOfficeCity]);

    const getBankBranches = useCallback(async () => {
        await dispatch(getBranches({
            accountId: selectValue?.bankId,
            data: { cityId: selectValue?.bankCityId, limit: 200 }
        }));
    }, [dispatch, selectValue]);

    const onFinish = useCallback(async (values) => {
        setLoading(true);

        if (!asCustomer) values.iban = clearIban(values.iban);

        const service = asCustomer ? putAsCustomer : putAsSupplier;
        const action = await dispatch(service(values));
        if (!service.fulfilled.match(action)) {
            setLoading(false);
            return notifications.error(action?.payload[0]?.message);
        }

        const intro = await dispatch(getIntro({requestNewToken: true}));
        if (!getIntro.fulfilled.match(intro)) {
            return navigate(RoutePaths.AUTH.LOGIN);
        }
               
        onCancel(true);
        
        return onSelectCompany(props.accountType, true);
    }, [dispatch, navigate, asCustomer]);

    const onChangeCity = (value, setField, removeField) => {
        if (setField !== 'bankCityId') dispatch(getCitiesTowns({cityId: value}));
        form.resetFields([removeField]);
        form.setFieldsValue({ [setField]: value });
        setSelectValue({
            ...selectValue,
            [setField]: value,
            [removeField]: null
        });
    };

    const onChangeBank = (value) => {
        setSelectValue({
            ...selectValue,
            bankId: value,
            bankCityId: null,
            bankLocationId: null
        });
        form.setFieldsValue({ bankId: value });
        form.resetFields(['bankCityId', 'bankBranchId']);
    };

    const onChangeSelect = (value, name) => {
        setSelectValue({ ...selectValue, [name]: value });
        form.setFieldsValue({ [name]: value });
    };

    return (
        <EtaForm
            onFinish={onFinish}
            form={form}
            layout="vertical"
            initialValues={{paymentType: 13}}
        >
            {
                asCustomer ? 
                (
                    <Row gutter={10}>
                        <Col flex={1}>
                            <EtaForm.Item
                                name="businessSectorId"
                                label="Sektör"
                                className="form-item"
                                rules={[{required: true, message: 'Lütfen bu alanı boş bırakmayın.'}]}
                            >
                                <EtaSelect
                                    size="large"
                                    placeholder="Seçiniz"
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    options={businessSectors?.map((item) => ({
                                        label: item?.name,
                                        value: item?.id
                                    }))}
                                />
                            </EtaForm.Item>
                        </Col>
                    </Row>
                ) : (
                    <Row gutter={10}>
                        <Col span={24}>
                            <EtaForm.Item hidden name="paymentType" ><EtaInput /></EtaForm.Item>
                            <EtaForm.Item
                                name="bankId"
                                label="Banka Adı"
                                className="form-item"
                                rules={[{required: true, message: 'Lütfen bu alanı boş bırakmayın.'}]}
                            >
                                <EtaSelect
                                    size="large"
                                    placeholder="Seçiniz"
                                    showSearch
                                    onChange={(value) => onChangeBank(value)}
                                    value={selectValue?.bankId}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    options={banks?.map((item) => ({
                                        label: item?.name,
                                        value: item?.accountId
                                    }))}
                                />
                            </EtaForm.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                            <EtaForm.Item
                                name="bankCityId"
                                label="İl"
                                className="form-item"
                                rules={[{required: true, message: 'Lütfen bu alanı boş bırakmayın.'}]}
                            >
                                <EtaSelect
                                    size="large"
                                    placeholder="Seçiniz"
                                    showSearch
                                    onChange={(value) => onChangeCity(value, 'bankCityId', 'bankLocationId')}
                                    value={selectValue?.bankCityId}
                                    disabled={!selectValue?.bankId}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    options={countriesCities?.map((item) => ({
                                        label: item?.name,
                                        value: item?.id
                                    }))}
                                />
                            </EtaForm.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                            <EtaForm.Item
                                name="bankLocationId"
                                label="Şube Adı"
                                className="form-item"
                                rules={[{required: true, message: 'Lütfen bu alanı boş bırakmayın.'}]}
                            >
                                <EtaSelect
                                    size="large"
                                    placeholder="Seçiniz"
                                    showSearch
                                    onChange={(value) => onChangeSelect(value, 'bankLocationId')}
                                    value={selectValue?.bankLocationId}
                                    disabled={!selectValue?.bankCityId}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    options={branches?.map((item) => ({
                                        label: item?.name,
                                        value: item?.locationId
                                    }))}
                                />
                            </EtaForm.Item>
                        </Col>
                        <Col span={24}>
                            <EtaForm.Item
                                name="bankAccountNumber"
                                label="Hesap No"
                                className="form-item"
                                rules={[{required: true, message: 'Lütfen bu alanı boş bırakmayın.'}]}
                            >
                                <EtaInput size="large" placeholder="Hesap No"/>
                            </EtaForm.Item>
                        </Col>
                        <Col span={24}>
                            <EtaForm.Item
                                name="iban"
                                label="Iban"
                                className="form-item"
                                rules={[
                                    {required: true, message: 'Lütfen bu alanı boş bırakmayın.'},
                                    {validator: ibanValidator, message: 'Lütfen geçerli bir iban giriniz.'}
                                ]}
                            >
                                <EtaInputMask mask={'TR99 9999 9999 9999 9999 9999 99'}>
                                    <EtaInput size="large" placeholder="Iban"/>
                                </EtaInputMask>
                            </EtaForm.Item>
                        </Col>
                    </Row>
                )
            }
            <Row justify="end" gutter={10}>
                <Col span={12}>
                    <EtaButton size="large" type="secondary-outline" block onClick={() => onCancel(true)} htmlType="button">İptal</EtaButton>
                </Col>
                <Col span={12}>
                    <EtaButton size="large" htmlType="submit" loading={loading} type="primary" block>Kaydet</EtaButton>
                </Col>
            </Row>
        </EtaForm>
    );
};

export default RegisterAsCompany;