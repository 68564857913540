import styled from 'styled-components';

const StyledTabs = (ComponentName) => styled(ComponentName)`
    &.ant-tabs {
        .ant-tabs-tab {
            font-size: 12px; 
            font-weight: 500;
            padding: 16px 0px;
        }
        .ant-tabs-tab-active {

            .ant-tabs-tab-btn{
                color: #000;
            }
            .ant-badge{
                .ant-badge-count {
                    background-color: #EE5A18;
                    color: #FFFFFF;

                    &:before{
                        background-color: #EE5A18;

                    }
                }
            }
        }
        .ant-tabs-tabpane {
            overflow-x: auto;
        }
        .ant-tabs-ink-bar {
            background-color: #1890FF;
        }

      .ant-tabs-nav {
        margin: unset;

        .ant-tabs-nav-list {
          &:last-child {
            padding-right: 40px;
          }
        }
      }

        .ant-badge {
            display: flex;
            align-items: center;

            &.ant-badge-has-count {
                padding-right: 36px;
            }

            .ant-badge-count {
                display: block;
                background-color: #DBE0E4;
                color: #4C6278 ;
                font-weight: 600;
                font-size: 11px;
                border-radius: 4px;
                overflow: initial;
                min-width: 24px;
                top: 6px;
                right: 12px;

                &:before{
                    content: "";
                    position: absolute;
                    left: -2px;
                    top: calc(50% - 2px);
                    display: inline-flex;
                    height: 4px;
                    width: 4px;   
                    background-color: #DBE0E4;
                    rotate: calc(45deg);
                }
            }
        }
    }
`;

export {
    StyledTabs
};