import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import trailerService from '../../core/services/trailer.service';

export const getTrailTypes = createAsyncThunk(
    'getTrailTypes',
    async (vehicleType, { rejectWithValue }) => {
        try {
            return await trailerService.getTypes(vehicleType);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getTypeWityKey = createAsyncThunk(
    'getTypeWityKey',
    async (key, { rejectWithValue }) => {
        try {
            return await trailerService.getTypeWityKey(key);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getFloorTypes = createAsyncThunk(
    'getFloorTypes',
    async (_, { rejectWithValue }) => {
        try {
            return await trailerService.getFloorTypes();
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getFloorTypesWithKey = createAsyncThunk(
    'getFloorTypesWithKey',
    async (key, { rejectWithValue }) => {
        try {
            return await trailerService.getFloorTypesWithKey(key);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getTypesProperties = createAsyncThunk(
    'getTypesProperties',
    async (params, { rejectWithValue }) => {
        try {
            return await trailerService.getTypesProperties(params);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);
export const getLastLocation = createAsyncThunk(
    'getLastLocation',
    async (params, { rejectWithValue }) => {
        try {
            return await trailerService.getLastLocation(params);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);


const initialState = {
    trailerTypes: [],
    typesWithKey: [],
    floorTypes: [],
    floorTypesWithKey: [],
    typesProperties: [],
    lastLocations: [],
};

export const trailerSlice = createSlice({
    name: 'trailer',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getTrailTypes.fulfilled, (state, action) => {
            state.trailerTypes = {
                ...state.trailerTypes,
                [action.meta.arg]: action.payload?.data?.data || []
            };
        });
        builder.addCase(getTypeWityKey.fulfilled, (state, action) => {
            state.typesWithKey = action.payload?.data?.data || [];
        });
        builder.addCase(getFloorTypes.fulfilled, (state, action) => {
            state.floorTypes = action.payload?.data?.data || [];
        });
        builder.addCase(getFloorTypesWithKey.fulfilled, (state, action) => {
            state.floorTypesWithKey = action.payload?.data?.data || [];
        });
        builder.addCase(getTypesProperties.fulfilled, (state, action) => {
            state.typesProperties = {
                ...state.typesProperties,
                [action.meta.arg.VehicleType]: action.payload?.data?.data || []
            };
        });
        builder.addCase(getLastLocation.fulfilled, (state, action) => {
            state.lastLocations = action.payload?.data?.data?.locationList || [];
        });

    }
});






