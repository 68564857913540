import dayjs from 'dayjs';

export const phoneValidator = async (field, value) => {
  try {
    const allPhoneRegex = /^(5(0[5-7]|[3-5]\d)\s\d{3}\s\d{2}\s\d{2})$|^(5(0[5-7]|[3-5]\d)\s\d{7})$|^(5(0[5-7]|[3-5]\d)\d{3}\d{2}\d{2})$|^([2-4]\d{2}\s\d{3}\s\d{2}\s\d{2})$|^([2-4]\d{2}\s\d{7})$|^([2-4]\d{2}\d{3}\d{2}\d{2})$/;
    const trimValue = value?.replaceAll('_', '');
    if (!value || allPhoneRegex.test(trimValue)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('error'));
  } catch (e) {
    return Promise.reject(new Error('error'));
  }
};

export const phoneMobileValidator = async (field, value) => {
  try {
    const phoneRegex = /^(5(0[5-7]|[3-5]\d)\s\d{3}\s\d{2}\s\d{2})$|^(5(0[5-7]|[3-5]\d)\s\d{7})$|^(5(0[5-7]|[3-5]\d)\d{3}\d{2}\d{2})$/;
    const trimValue = value?.replaceAll('_', '');
    if (!value || phoneRegex.test(trimValue)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('error'));
  } catch (e) {
    return Promise.reject(new Error('error'));
  }
};

export const mailValidator = async (field, value) => {
  try {
    const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (!value || regex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error());
  } catch (e) {
    return Promise.reject(new Error());
  }
};

export const tcNoValidator = async (field, value) => {
  try {
    const regex = /^[1-9]{1}[0-9]{9}[02468]{1}$/;
    if (!value || regex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error());
  } catch (e) {
    return Promise.reject(new Error());
  }
};

export const passwordValidator = async (field, value) => {
  try {
    const isValid = value?.length >= 5 && value?.length <= 20;
    if (!value || isValid) {
      return Promise.resolve();
    }
    return Promise.reject(new Error());
  } catch (e) {
    return Promise.reject(new Error());
  }
};

export const birthDateValidator = async (field, value) => {
  let diff = dayjs().diff(value, 'years');
  try {
    const isValid = diff >= 18 && diff <= 80;
    if (!value || isValid) {
      return Promise.resolve();
    }
    return Promise.reject(new Error());
  } catch (e) {
    return Promise.reject(new Error());
  }
};

export const checkboxValidator = async (field, value) => {
  try {
    if (value) {
      return Promise.resolve();
    }
    return Promise.reject(new Error());
  } catch (e) {
    return Promise.reject(new Error());
  }
};

export const ibanValidator = async (field, value) => {
  try {
    const regex = /TR[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){1}([0-9]{1})([a-zA-Z0-9]{3}\s?)([a-zA-Z0-9]{4}\s?){3}([a-zA-Z0-9]{2})\s?/;
    if (!value || regex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error());
  } catch (e) {
    return Promise.reject(new Error());
  }
};

export const cardNumberValidator = async (field, value) => {
  try {
    const regex = /^([0-9]{4}[- ]?){3}[0-9]{4}$/;
    if (!value || regex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error());
  } catch (e) {
    return Promise.reject(new Error());
  }
};

export const valueMatchValidator = (form, fieldName, message) => ({
  validator(field, value) {
    try {
      if (!value || form.getFieldValue(fieldName) === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('error'));
    } catch (e) {
      return Promise.reject(new Error('error'));
    }
  },
  message: message,
});

export const acceptOnlyWordValidator = async (field, value) => {
  try {
    const regex = /^[a-zA-ZğüşöçıİĞÜŞÖÇ ]+$/;
    if (!value || regex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error());
  } catch (e) {
    return Promise.reject(new Error());
  }
};

export const conditionValidator = async (limit, value, condition) => {
  const message = (condition === 'greater') ? 'büyük' : 'küçük';
  try {
    if ((condition === 'greater') ? (limit >= value) : (limit <= value)) {
      return Promise.resolve();
    } else {
      return Promise.reject(`Girilen değer ${limit}'den ${message} olamaz `);
    }
  } catch (error) {
    return Promise.reject(`Girilen değer ${limit}'den ${message} olamaz `);
  }
};