import React from 'react';
import { Routes, Route } from 'react-router-dom';
import HomeLayout from 'layouts/home.layout';
import AuthLayout from 'layouts/auth.layout';
import ProtectedRoute from './protected.route';

import {
  Home,
  Login,
  LoginBlocked,
  Register,
  Profile,
  Agreements,
  PhoneVerification,
  ForgotPassword,
  RegisterToCompany,
  RegisterApproval,
  RegisterTemporaryCompany,
  RegisterCompany,
  JoinDemands,
  NotCompany,
  NotFound,
  UnAuthorized,
  QuoteCreate,
  QuoteCreateVehicle,
  QuoteCreateRoute,
  QuoteCreateLoad,
  QuoteCreateSummary,
  QuoteCreateComplate,
  InvoicingChoices,
  Users,
  AddressBook,
  Notifications,
  SupplierCompany,
  CustomerDashboard,
  CustomerCompany,
  CustomerShipment,
  CustomerShipmentDetail,
  CustomerShipmentPreview,
  Quotes,
  QuoteDetail,
  Favorite,
  Invoice,
  BalancePayments,
  StoreCards,
  Cart,
  Auctions,
  DiscountVouchers,
  BidsExisting,
  BidsSms,
  SupplierShipment,
  Vehicles,
  SupplierBalancePayments,
  CustomerTrailerTracking,
  SupplierShipmentDetail,
  SupplierDashboard,
  LoadDemands,
  RefreshAuthorized,
  NotCompleted,
  VehicleDetail,
  EscalationApproval,
  EscalationApprovalDetail,
  ContractApproval,
  ContractApprovalDetail,
  InvoiceApproval,
  InvoiceApprovalDetail,
  AdvantageWallet,
  AdvantageWalletIntro,
  AdvantageWalletDocument,
  AdvantageWalletAgreement,
  AdvantageWalletPayment,
  AdvantageWalletHistory,
  AdvantageWalletDashboard,
  AdvantageWalletError,
  VehicleDocuments,
  CustomerProcessGuide,
  BulkDemand
} from 'pages';

const Router = () => {
  return (
    <Routes>
      <Route index element={<Home />} />
      <Route element={<HomeLayout />}>
        <Route path="hesap">
          <Route index path="profil" element={<Profile />} />
          <Route index path="firmaya-kayit-ol" element={<RegisterToCompany />} />
          <Route index path="sozlesmelerim" element={<Agreements />} />
          <Route index path="katilim-talebi" element={<JoinDemands />} />
          <Route index path="firma-kaydi-olustur" element={<RegisterCompany />} />
          <Route index path="gecici-firma-kaydi" element={<RegisterTemporaryCompany />} />
          <Route index path="firma-kayit-onayi" element={<RegisterApproval />} />
          <Route index path="firma-bulunamadi" element={<NotCompany />} />
        </Route>
        {/*<Route path='onay-bekleyen-islemler'>
          <Route path='eskalasyon-onayi' element={<EscalationApproval />} />
          <Route path='eskalasyon-onayi/detay/:id' element={<EscalationApprovalDetail />} />
          <Route path='sozlesme-onayi' element={<ContractApproval />} />
          <Route path='sozlesme-onayi/detay/:id' element={<ContractApprovalDetail />} />
          <Route path='fatura-onayi' element={<InvoiceApproval />} />
          <Route path='fatura-onayi/detay/:id' element={<InvoiceApprovalDetail />} />
        </Route>*/}
        <Route path="bildirimler" element={<Notifications />} />
      </Route>
      <Route path="tedarikci" element={<HomeLayout />}>
        <Route index element={<ProtectedRoute roles={['SupplierAdmin', 'Trucker', 'SupplierTemporary']}><SupplierDashboard /></ProtectedRoute>} />
        <Route path="hesap">
          <Route path="firma-profili" element={<ProtectedRoute roles={['SupplierAdmin', 'Trucker']}><SupplierCompany /></ProtectedRoute>} />
          <Route path="kullanicilar" element={<ProtectedRoute roles={['SupplierAdmin']}><Users /></ProtectedRoute>} />
        </Route>
        <Route path="ihaleler" element={<ProtectedRoute roles={['SupplierAdmin', 'Trucker']}><Auctions /></ProtectedRoute>} />
        <Route path="teklifler" element={<ProtectedRoute roles={['SupplierAdmin', 'Trucker']}><BidsExisting /></ProtectedRoute>} />
        <Route path="sms-teklifleri" element={<ProtectedRoute roles={['SupplierAdmin']}><BidsSms /></ProtectedRoute>} />
        <Route path="seferler" element={<ProtectedRoute roles={['SupplierAdmin', 'Trucker']}><SupplierShipment /></ProtectedRoute>} />
        <Route path="sefer/detay/:id" element={<ProtectedRoute roles={['SupplierAdmin', 'Trucker']}><SupplierShipmentDetail /></ProtectedRoute>} />
        <Route path="araclar" element={<ProtectedRoute roles={['SupplierAdmin', 'Trucker']}><Vehicles /></ProtectedRoute>} />
        <Route path="arac/detay/:id?" element={<ProtectedRoute roles={['SupplierAdmin', 'Trucker']}><VehicleDetail /></ProtectedRoute>} />
        <Route path="arac/belgeler/:id" element={<ProtectedRoute roles={['SupplierAdmin', 'Trucker']}><VehicleDocuments /></ProtectedRoute>} />
        <Route path="faturalar" element={<ProtectedRoute roles={['SupplierAdmin']}><Invoice /></ProtectedRoute>} />
        <Route path="odemeler" element={<ProtectedRoute roles={['SupplierAdmin']}><SupplierBalancePayments /></ProtectedRoute>} />
        <Route path="yuk-iste" element={<ProtectedRoute roles={['SupplierAdmin', 'Trucker']}><LoadDemands /></ProtectedRoute>} />
      </Route>
      <Route path="musteri" element={<HomeLayout />}>
        <Route index element={<ProtectedRoute roles={['CustomerAdmin', 'CustomerPersonel', 'CustomerTemporary']}><CustomerDashboard /></ProtectedRoute>} />
        <Route path="hesap">
          <Route path="firma-profili" element={<ProtectedRoute roles={['CustomerAdmin', 'CustomerPersonel']}><CustomerCompany /></ProtectedRoute>} />
          <Route path="adresler" element={<ProtectedRoute roles={['CustomerAdmin', 'CustomerPersonel', 'CustomerTemporary']}><AddressBook /></ProtectedRoute>} />
          <Route path="kayitli-kartlar" element={<ProtectedRoute roles={['CustomerAdmin', 'CustomerPersonel']}><StoreCards /></ProtectedRoute>} />
          <Route path="indirim-ceklerim" element={<ProtectedRoute roles={['CustomerAdmin', 'CustomerPersonel']}><DiscountVouchers /></ProtectedRoute>} />
          <Route path="faturalama-tercihleri" element={<ProtectedRoute roles={['CustomerAdmin']}><InvoicingChoices /></ProtectedRoute>} />
          <Route path="kullanicilar" element={<ProtectedRoute roles={['CustomerAdmin']}><Users /></ProtectedRoute>} />
        </Route>
        <Route path="dorse-takip" element={<ProtectedRoute roles={['CustomerAdmin', 'CustomerPersonel']}><CustomerTrailerTracking /></ProtectedRoute>} />
        <Route path="talepler" element={<ProtectedRoute roles={['CustomerAdmin', 'CustomerPersonel', 'CustomerTemporary']}><Quotes /></ProtectedRoute>} />
        <Route path="toplu-talep" element={<ProtectedRoute roles={['CustomerAdmin', 'CustomerPersonel', 'CustomerTemporary']}><BulkDemand /></ProtectedRoute>} />
        <Route path="talep/detay/:id" element={<ProtectedRoute roles={['CustomerAdmin', 'CustomerPersonel', 'CustomerTemporary']}><QuoteDetail /></ProtectedRoute>} />
        <Route path="sepet" element={<ProtectedRoute roles={['CustomerAdmin', 'CustomerPersonel', 'CustomerTemporary']}><Cart /></ProtectedRoute>} />
        <Route path="favoriler" element={<ProtectedRoute roles={['CustomerAdmin', 'CustomerPersonel', 'CustomerTemporary']}><Favorite /></ProtectedRoute>} />
        <Route path="seferler" element={<ProtectedRoute roles={['CustomerAdmin', 'CustomerPersonel']}><CustomerShipment /></ProtectedRoute>} />
        <Route path="sefer/detay/:id" element={<ProtectedRoute roles={['CustomerAdmin', 'CustomerPersonel']}><CustomerShipmentDetail /></ProtectedRoute>} />
        <Route path="faturalar" element={<ProtectedRoute roles={['CustomerAdmin']}><Invoice /></ProtectedRoute>} />
        <Route path="bakiye-ve-odemeler" element={<ProtectedRoute roles={['CustomerAdmin']}><BalancePayments /></ProtectedRoute>} />
        <Route path="arac-talep" element={<ProtectedRoute roles={['CustomerAdmin', 'CustomerPersonel', 'CustomerTemporary']}><QuoteCreate /></ProtectedRoute>}>
          <Route index path="arac-ekle" element={<QuoteCreateVehicle />} />
          <Route index path="rota-secimi" element={<QuoteCreateRoute />} />
          <Route index path="yuk-secimi" element={<QuoteCreateLoad />} />
          <Route index path="talep-ozeti" element={<QuoteCreateSummary />} />
          <Route index path="talep-bilgileri" element={<QuoteCreateComplate />} />
        </Route>

        <Route path="eta-puan" element={<ProtectedRoute roles={['CustomerAdmin', 'CustomerPersonel']}><AdvantageWallet /></ProtectedRoute>}>
          <Route index path="hesap" element={<AdvantageWalletDashboard />} />
          <Route index path="katil" element={<AdvantageWalletIntro />} />
          <Route index path="dokuman" element={<AdvantageWalletDocument />} />
          <Route index path="sozlesme" element={<AdvantageWalletAgreement />} />
          <Route index path="hata" element={<AdvantageWalletError />} />
        </Route>
        <Route index path="eta-puan/harcama-noktalari" element={<ProtectedRoute roles={['CustomerAdmin', 'CustomerPersonel']}><AdvantageWalletPayment /></ProtectedRoute>} />
        <Route index path="eta-puan/kullanim-puan-gecmisi" element={<ProtectedRoute roles={['CustomerAdmin', 'CustomerPersonel']}><AdvantageWalletHistory /></ProtectedRoute>} />
        <Route path="eta-nasil-calisir">
          <Route index path="islem-rehberi" element={<ProtectedRoute roles={['CustomerAdmin', 'CustomerPersonel', 'CustomerTemporary']}><CustomerProcessGuide/></ProtectedRoute>}/>
        </Route>
      </Route>
      <Route path="uyelik" element={<AuthLayout />}>
        <Route index path="giris-yap" element={<Login />} />
        <Route index path="giris-engellendi" element={<LoginBlocked />} />
        <Route index path="kayit-ol" element={<Register />} />
        <Route index path="sifremi-unuttum" element={<ForgotPassword />} />
        <Route index path="telefon-onayi" element={<PhoneVerification />} />
        <Route index path="firmaya-kayit-ol" element={<RegisterToCompany />} />
        <Route index path="firma-kayit-onayi" element={<RegisterApproval />} />
        <Route index path="firma-kaydi-olustur" element={<RegisterCompany />} />
        <Route index path="gecici-firma-kaydi" element={<RegisterTemporaryCompany />} />
        <Route index path="kayit-tamamlanmadi" element={<NotCompleted />} />
        <Route index path="firma-bulunamadi" element={<NotCompany />} />
      </Route>

      <Route path="*" element={<NotFound />} />
      <Route path="yetkisiz-erisim" element={<UnAuthorized />} />
      <Route path="yeni-erisim" element={<RefreshAuthorized />} />
      <Route path="sefer/onizleme/:id" element={<CustomerShipmentPreview />} />
    </Routes>
  );
};

export default Router;