
const loadState = () => {
    try {
        const serializedState = localStorage.getItem('state');
        if (serializedState) {
            return JSON.parse(serializedState);
        } else{
            return {};
        }
    } catch (error) {
        return console.log(error);
    }
};

const saveState = (state) => {
    try { 
        const serializedState = JSON.stringify(state);
        localStorage.setItem('state', serializedState);
    } catch (error) {
        return console.log(error);
    }
};

export {
    loadState,
    saveState
};