import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import agreementService from '../../core/services/agreement.service';
import { publicLogin } from './auth.slice';
import { Cookies } from 'react-cookie';

export const get = createAsyncThunk(
    'getAgreement',
    async (type, { rejectWithValue }) => {
        try {
            return await agreementService.get(type);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getTypes = createAsyncThunk(
    'getTypes',
    async (params, { rejectWithValue }) => {
        try {
            return await agreementService.getTypes(params);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getDistanceSalesContract = createAsyncThunk(
    'getDistanceSalesContract',
    async (_, { rejectWithValue }) => {
        try {
            return await agreementService.getDistanceSalesContract();
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

const initialState = {
    agreements: [],
    agreementHtml:[]
};

export const agreementSlice = createSlice({
    name: 'agreement',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getTypes.fulfilled, (state, action) => {
            state.agreements = action?.payload?.data?.data;
        });
        builder.addCase(get.fulfilled, (state, action) => {
            state.agreementHtml = action?.payload?.data?.data;
        });
        builder.addCase(getDistanceSalesContract.fulfilled, (state, action) => {
            state.agreementHtml = action?.payload?.data?.data;
        });
    }
});