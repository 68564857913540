import { Spin } from 'antd';
import styled from 'styled-components';

const LoadingWrapper = styled(Spin)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  .ant-spin-dot-item {
    background-color: #1890ff;
  }
`;
const Loading = (props) => {
  return (
    <LoadingWrapper
      {...props}
      size="large"
    />
  );
};

export default Loading ;