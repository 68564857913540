import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Cookies } from 'react-cookie';
import { setToken } from 'core/utils/helpers/converter';
import personService from 'core/services/person.service';
import GA, { eventTypes } from 'tools/analytics';

const cookie = new Cookies();

export const get = createAsyncThunk(
    'getPerson',
    async (_, { rejectWithValue }) => {
        try {
            return await personService.get();
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const post = createAsyncThunk(
    'postPerson',
    async (data, { rejectWithValue }) => {
        try {
            return await personService.post(data);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const putEmail = createAsyncThunk(
    'putEmail',
    async (data, { rejectWithValue }) => {
        try {
            return await personService.putEmail(data);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const putPhone = createAsyncThunk(
    'putPhone',
    async (data, { rejectWithValue }) => {
        try {
            return await personService.putPhone(data);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const putPassword = createAsyncThunk(
    'putPassword',
    async (data, { rejectWithValue }) => {
        try {
            return await personService.putPassword(data);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getIntro = createAsyncThunk(
    'getIntro',
    async (params, { rejectWithValue }) => {
        try {
            if (params?.requestNewToken) {
                cookie.remove('accessToken', { path: '/' });
            }
            return await personService.getIntro();
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getJoinDemands = createAsyncThunk(
    'getPersonJoinDemands',
    async (params, { rejectWithValue }) => {
        try {
            return await personService.getJoinDemands(params);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const deleteJoinDemand = createAsyncThunk(
    'deleteJoinDemand',
    async (accountJoinDemandId, { rejectWithValue }) => {
        try {
            return await personService.deleteJoinDemand(accountJoinDemandId);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getTypes = createAsyncThunk(
    'getTypes',
    async (_, { rejectWithValue }) => {
        try {
            return await personService.getTypes();
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getNotifications = createAsyncThunk(
    'getNotifications',
    async (params, { rejectWithValue }) => {
        try {
            return await personService.getNotifications(params);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getHeaderNotifications = createAsyncThunk(
    'getHeaderNotifications',
    async (params, { rejectWithValue }) => {
        try {
            return await personService.getNotifications(params);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getNotificationCount = createAsyncThunk(
    'getNotificationCount',
    async (params, { rejectWithValue }) => {
        try {
            return await personService.getNotificationCount(params);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getNotificationById = createAsyncThunk(
    'getNotificationById',
    async (notificationId, { rejectWithValue }) => {
        try {
            return await personService.getNotificationById(notificationId);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const putNotificationRead = createAsyncThunk(
    'putNotificationRead',
    async (notificationId, { rejectWithValue }) => {
        try {
            return await personService.putNotificationRead(notificationId);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getNotificationsSettings = createAsyncThunk(
    'getNotificationsSettings',
    async (_, { rejectWithValue }) => {
        try {
            return await personService.getNotificationsSettings();
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const putNotificationsSettings = createAsyncThunk(
    'putNotificationsSettings',
    async (params, { rejectWithValue }) => {
        try {
            return await personService.putNotificationsSettings(params);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const putJoinRoles = createAsyncThunk(
    'putJoinRoles',
    async (_, { rejectWithValue }) => {
        try {
            return await personService.putJoinRoles();
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const putNotificationsRead = createAsyncThunk(
    'putNotificationsRead',
    async (params, { rejectWithValue }) => {
        try {
            return await personService.putNotificationsRead(params);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const deleteNotifications = createAsyncThunk(
    'deleteNotifications',
    async (params, { rejectWithValue }) => {
        try {
            return await personService.deleteNotifications(params);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const postCustomerGainDocument = createAsyncThunk(
    'postCustomerGainDocument',
    async ({ documentId }, { rejectWithValue }) => {
        try {
            return await personService.postCustomerGainDocument(documentId);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getCustomerGainAccessibility = createAsyncThunk(
    'getCustomerGainAccessibility',
    async (_, { rejectWithValue }) => {
        try {
            return await personService.getCustomerGainAccessibility();
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const postApproveCustomerGainAggrements = createAsyncThunk(
    'postApproveCustomerGainAggrements',
    async (_, { rejectWithValue }) => {
        try {
            return await personService.postApproveCustomerGainAggrements();
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

const initialState = {
    intro: {},
    person: {},
    personTypes: [],
    personJoinDemands: {},
    isLoading: false,
    notifications: [],
    notificationPageProperty: {},
    notificationSetting: {},
    notificationById: {},
    headerNotifications: [],
    notificationCount: 0,
    isLoadingLoadMore: false,
    customerGainAccessibility: {},
};

export const personSlice = createSlice({
    name: 'person',
    initialState,
    reducers: {
        setClearNotifications: (state, action) => {
            state.notifications = [];
        },
    },
    extraReducers: (builder) => {
        builder.addCase(get.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(get.fulfilled, (state, action) => {
            state.person = action?.payload?.data?.data;
            state.isLoading = false;
        });
        builder.addCase(get.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(post.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(post.fulfilled, (state, action) => {
            state.isLoading = false;
            setToken(action?.payload?.data?.data);
            GA.event(eventTypes.PAGE_REQUEST, 'Kayıt Ol', 'Başarılı');
        });
        builder.addCase(post.rejected, (state) => {
            state.isLoading = false;
            GA.event(eventTypes.PAGE_REQUEST, 'Kayıt Ol', 'Başarısız');
        });
        builder.addCase(getIntro.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getIntro.fulfilled, (state, action) => {
            state.intro = action?.payload?.data?.data;
            state.isLoading = false;
        });
        builder.addCase(getIntro.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getTypes.fulfilled, (state, action) => {
            state.personTypes = action?.payload?.data?.data || [];
        });
        builder.addCase(getJoinDemands.fulfilled, (state, action) => {
            state.personJoinDemands = action?.payload?.data?.data || [];
        });
        builder.addCase(getNotifications.pending, (state, action) => {
            state.isLoadingLoadMore = true;
        });
        builder.addCase(getNotifications.fulfilled, (state, action) => {
            const newData = action?.payload?.data?.data;
            if (newData) {
                state.notifications = [...state.notifications, ...newData];
                state.notificationPageProperty = {
                    totalCount: action?.payload?.data?.totalCount,
                    limit: action?.payload?.data?.limit,
                    next: action?.payload?.data?.next,
                    prev: action?.payload?.data?.prev,
                };
            }
            state.isLoadingLoadMore = false;
        });
        builder.addCase(getNotifications.rejected, (state, action) => {
            state.isLoadingLoadMore = false;
        });
        builder.addCase(getHeaderNotifications.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getHeaderNotifications.fulfilled, (state, action) => {
            state.headerNotifications = action?.payload?.data?.data || [];
            state.isLoading = false;
        });
        builder.addCase(getHeaderNotifications.rejected, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(getNotificationCount.fulfilled, (state, action) => {
            state.notificationCount = action?.payload?.data?.data || 0;
        });
        builder.addCase(getNotificationById.fulfilled, (state, action) => {
            state.notificationById = action?.payload?.data?.data || {};
        });
        builder.addCase(getNotificationsSettings.fulfilled, (state, action) => {
            state.notificationSetting = action?.payload?.data?.data || {};
        });
        builder.addCase(getCustomerGainAccessibility.fulfilled, (state, action) => {
            state.customerGainAccessibility = action?.payload?.data?.data || {};
        });
    }
});

export const { setClearNotifications } = personSlice.actions;
