import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import cartService   from 'core/services/cart.service';

export const getCart = createAsyncThunk(
    'getCart',
    async (_, { rejectWithValue }) => {
        try {
            return await cartService.getCart();
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const putCart = createAsyncThunk(
    'putCart',
    async (data, { rejectWithValue }) => {
        try {
            return await cartService.putCart(data);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getCheckout = createAsyncThunk(
    'getCheckout',
    async (params, { rejectWithValue }) => {
        try {
            return await cartService.getCheckout(params);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const postCartWithQuoteId = createAsyncThunk(
    'postCartWithQuoteId',
    async (data, { rejectWithValue }) => {
        try {
            return await cartService.postCartWithQuoteId(data);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const deleteCartLines = createAsyncThunk(
    'deleteCartLines',
    async (cartLineId, { rejectWithValue }) => {
        try {
            return await cartService.deleteCartLines(cartLineId);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const postPaymentsCreditLimit = createAsyncThunk(
    'postPaymentsCreditLimit',
    async ({data, cartId}, { rejectWithValue }) => {
        try {
            return await cartService.postPaymentsCreditLimit(data, cartId);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const postPaymentsWireTransfer = createAsyncThunk(
    'postPaymentsWireTransfer',
    async ({data, cartId}, { rejectWithValue }) => {
        try {
            return await cartService.postPaymentsWireTransfer(data, cartId);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const postPaymentsVirtualPos = createAsyncThunk(
    'postPaymentsVirtualPos',
    async ({data, cartId}, { rejectWithValue }) => {
        try {
            return await cartService.postPaymentsVirtualPos(data, cartId);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getCartSold = createAsyncThunk(
    'getCartSold',
    async (cartId, { rejectWithValue }) => {
        try {
            return await cartService.getCartSold(cartId);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

const initialState = {
    cart: {},
    checkout: {},
    isFetching: false,
    tabs: '1',
    paymentType: 0,
    cartCount: 0,
    cartSold: {}
};
export const cartSlice = createSlice({
    name: 'cart',
    initialState: initialState,
    reducers: {
        setTabs (state, action) {
            state.tabs = action.payload;
        },
        setPaymentType (state, action) {
            state.paymentType = action.payload;
        },
        setcartCount (state, action) {
            state.cartCount = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getCart.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(getCart.fulfilled, (state, action) => {
            state.isFetching = false;
            state.cart = action.payload?.data?.data || {};
            state.cartCount = action.payload?.data?.data?.cartLines?.length || 0;
        });
        builder.addCase(getCart.rejected, (state) => {
            state.isFetching = false;
            state.cart = {};
            state.cartCount = 0;
        });
        builder.addCase(getCheckout.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(getCheckout.fulfilled, (state, action) => {
            state.checkout = action.payload?.data?.data?.cartTotal ? action.payload?.data?.data : {};
            state.isFetching = false;
        });
        builder.addCase(getCheckout.rejected, (state) => {
            state.checkout = {};
            state.isFetching = false;
        });
        builder.addCase(getCartSold.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(getCartSold.fulfilled, (state, action) => {
            state.isFetching = false;
            state.cartSold = action.payload?.data?.data || {};
        });
        builder.addCase(getCartSold.rejected, (state) => {
            state.isFetching = false;
            state.cartSold = {};
        });
    }
});

export const { setTabs, setPaymentType, setcartCount } = cartSlice.actions;
