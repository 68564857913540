
import { MessageOutlined, PhoneOutlined } from '@ant-design/icons';
import { Col, Form, Row, Space } from 'antd';
import { EtaButton, EtaDrawer, EtaForm, EtaInput, EtaInputMask, EtaSelect, EtaTextArea } from 'components/ui';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { mailValidator, phoneMobileValidator } from 'core/utils/validators';
import { clearPhoneNumber } from 'core/utils/cleaners';
import { MessageButton, MessageWrapper } from './message.styles';
import { useEffect } from 'react';
import { get } from 'store/slice/person.slice';
import { sendMessage } from 'store/slice/message.slice';
import { notifications } from 'core/utils/common/notifications';

const ACCOUNT_TYPE = [
    { key: 1, label: 'Müşteri', value: 'customer' },
    { key: 2, label: 'Tedarikçi', value: 'supplier' },
];

const Message = () => {
    const dispatch = useDispatch();
    const [contactForm] = Form.useForm();

    const { isAuth } = useSelector(state => state.auth);
    const { view, company } = useSelector(state => state.app);
    const { person, intro } = useSelector(state => state.person);

    const [modal, setModal] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);

    useEffect(() => {
        isAuth && dispatch(get());
    }, [dispatch, isAuth]);

    const onFinish = async (values) => {
        setButtonLoading(true);
        const data = isAuth 
            ?  { ...values,
                accountType: company.value === 'customer' ? 1 : 2,
                name: `${person.name} ${person.surname}`,
                companyName: intro.accountIntro.title,
                phoneMobile: clearPhoneNumber(person.phoneNumber),
                email: person.email,
                isMember: true 
            }
            :   { ...values,
                phoneMobile: clearPhoneNumber(values.phoneMobile),
                isMember: false 
            };

        const action = await dispatch(sendMessage(data));
        if (sendMessage.fulfilled.match(action)) {
            notifications.success('Mesajınız başarıyla iletilmiştir.');
            contactForm.resetFields();
            setModal(false);
        } else {
            notifications.error(action?.payload[0]?.message);
        }
        setButtonLoading(false);
    };

    return (
        <MessageWrapper>
            <MessageButton onClick={() => setModal(true)}>
                { isAuth ? <MessageOutlined /> :  <PhoneOutlined /> }
            </MessageButton>
            <EtaDrawer
                title={isAuth ? 'Mesaj Bırakın' : 'Sizi Arayalım'}
                width={view === 'MobileView' ? '100%' : 450}
                open={modal}
                maskClosable={false}
                onClose={() => setModal(false)}
            >
                <EtaForm
                    layout="vertical"
                    form={contactForm}
                    onFinish={onFinish}
                >
                    <Row>
                        {
                            !isAuth &&
                            <>
                                <Col span={24}>
                                    <EtaForm.Item
                                        name="accountType"
                                        className="form-item"
                                        label="Firma Tipi"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Lütfen firma tipini seçiniz.'
                                            }
                                        ]}
                                    >
                                        <EtaSelect
                                            placeholder="Firma Tipi Seçiniz"
                                            size="large"
                                            options={ACCOUNT_TYPE.map((account) => {
                                                return {
                                                    label: account.label,
                                                    value: account.key
                                                };
                                            })} />
                                    </EtaForm.Item>
                                </Col>
                                <Col span={24}>
                                    <EtaForm.Item
                                        name="name"
                                        className="form-item"
                                        label="Adınız Soyadınız"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Lütfen adınızı ve soyadınızı giriniz.'
                                            }
                                        ]}
                                    >
                                        <EtaInput size="large" />
                                    </EtaForm.Item>
                                </Col>
                                <Col span={24}>
                                    <EtaForm.Item
                                        name="companyName"
                                        className="form-item"
                                        label="Firma Ünvan Bilgisi"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Lütfen firma ünvan bilgisini giriniz.'
                                            }
                                        ]}
                                    >
                                        <EtaInput size="large" />
                                    </EtaForm.Item>
                                </Col>
                                <Col span={24}>
                                    <EtaForm.Item
                                        name="phoneMobile"
                                        className="form-item"
                                        label="Telefon"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Lütfen telefon giriniz.'
                                            },
                                            {
                                                validator: phoneMobileValidator,
                                                message: 'Lütfen geçerli bir telefon giriniz.'
                                            }
                                        ]}
                                    >
                                        <EtaInputMask mask={'999 999 99 99'}>
                                            <EtaInput
                                                size="large"
                                                prefix={<Space>+90</Space>}
                                            />
                                        </EtaInputMask>
                                    </EtaForm.Item>
                                </Col>
                                <Col span={24}>
                                    <EtaForm.Item
                                        name="email"
                                        className="form-item"
                                        label="E-Posta Adresi"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Lütfen eposta giriniz.'
                                            },
                                            {
                                                validator: mailValidator,
                                                message: 'Lütfen geçerli bir eposta giriniz.'
                                            }
                                        ]}
                                    >
                                        <EtaInput size="large" />
                                    </EtaForm.Item>
                                </Col>
                            </>
                        }
                        <Col span={24}>
                            <EtaForm.Item
                                name="message"
                                className="form-item"
                                label="Mesaj Bırakın"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Lütfen mesajınızı giriniz.'
                                    },
                                ]}
                            >
                                <EtaTextArea rows={4} />
                            </EtaForm.Item>
                        </Col>
                        {/* <Col span={24}>
                            <EtaForm.Item
                                name="isEmailRequest"
                                className="form-item"
                            >
                                <EtaCheckbox>Kampanya ve duyurularla ilgili e-posta almak istiyorum.</EtaCheckbox>
                            </EtaForm.Item>
                        </Col> */}
                        <Col span={24}>
                            <EtaButton size="large" type="primary" htmlType="submit" block loading={buttonLoading}>Gönder</EtaButton>
                        </Col>
                    </Row>
                </EtaForm>
            </EtaDrawer>
        </MessageWrapper>
    );
};

export default Message;