import styled from 'styled-components';

const StyledSelect = (ComponentName) => styled(ComponentName)`
    width: 100%;
`;
const StyledTreeSelect = (ComponentName) => styled(ComponentName)`
    width: 100%;
`;

export {
    StyledSelect,
    StyledTreeSelect
};