import dataApi from '../apis/data.api';

const get = async (type) => {
  return dataApi.get(`agreements/${type}`);
};

const getTypes = async (params) => {
  return dataApi.get('agreements/types', { params });
};

const getDistanceSalesContract = async () => {
    return dataApi.get('agreements/distance-sales-contract');
};

const agreementService = {
  get,
  getTypes,
  getDistanceSalesContract
};

export default agreementService;
