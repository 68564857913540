import { css } from 'styled-components';
import { breakpoints } from './variables';

export const mediaResponseMax = Object.keys(breakpoints).reduce((accumulator, label) => {
  accumulator[label] = (...args) => css`
      @media (max-width: ${breakpoints[label]}) {
        ${css(...args)};
      }
    `;
  return accumulator;
}, {});

export const mediaResponseMin = Object.keys(breakpoints).reduce((accumulator, label) => {
  accumulator[label] = (...args) => css`
      @media (min-width: ${breakpoints[label]}) {
        ${css(...args)};
      }
    `;
  return accumulator;
}, {});