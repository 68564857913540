export const clearIban = (value) => {
  return value.replace(/ /g, '');
};

export const clearPhoneNumber = (value) => {
  let newValue = value;
  newValue= newValue
          .replace(/\)/g, '')
          .replace(/\(/g, '')
          .replace(/-/g, '')
          .replace(/ /g, '')
          .replace(/\+90/g, '')
          .replace(/_/g, '');
  return newValue;
};
  