import dataApi from '../apis/data.api';

const getShipmentCancelReason = async (getShipmentCancelReason) => {
  return dataApi.get(`shipments/cancel-reasons/${getShipmentCancelReason}`);
};

const putShipmentCancel = async (shipmentId, data) => {
  return dataApi.put(`shipments/${shipmentId}/cancel`, data);
};

const shipmentService = {
  getShipmentCancelReason,
  putShipmentCancel,
};

export default shipmentService;
