import React from 'react';
import { ImageWrapper } from './index.styles';

const EtaImage = (props) => {
    return (
        <ImageWrapper {...props}>
            <img src={`/assets/images/${props.source}`} alt={props.alt} />
        </ImageWrapper>
    );
};
export default EtaImage;