import styled from 'styled-components';

const StyledList = (ComponentName) => styled(ComponentName)`
    &.ant-list {
        border-radius: 4px;

        .ant-list-header {
            font-size: 14px;
            font-weight: 500;
            color: #000;
            padding-inline: 15px;
            padding-block: 11px;
        }
        .ant-list-item {
            font-size: 14px;
            font-weight: 500;
            color: #4C6278;
        }
        .ant-list-item-action {
            display: flex;
            align-items: center;

            .ant-list-item-action-split {
                display: none;
            }
            .anticon {
                font-size: 20px;
                &.add {
                    cursor: pointer;
                }
            }
            & > li {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0px;
                &:not(:last-child) {
                    margin-right: 12px;
                }
            }
            & > li > span {
                font-size: 13px;
                font-weight: 500;
                color: #1890FF;
            }
        }
    }
`;

export {
    StyledList
};