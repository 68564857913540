import dataApi from '../apis/data.api';

const getTypes = () => {
  return dataApi.get('invoicings/types');
};

const getConstraintsStages = () => {
  return dataApi.get('invoicings/constraints/stages');
};

const getConstraintsPeriods = () => {
    return dataApi.get('invoicings/constraints/periods');
};

const getConstraintsDays = () => {
    return dataApi.get('invoicings/constraints/days');
};

const invoicingService = {
    getTypes,
    getConstraintsStages,
    getConstraintsPeriods,
    getConstraintsDays
};

export default invoicingService;