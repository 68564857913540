import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import paymentService from 'core/services/payment.service';

export const getPaymentTypes = createAsyncThunk(
    'getPaymentTypes',
    async ({isCustomerPayment, isSupplierPayment}, { rejectWithValue }) => {
        try {
            return await paymentService.getPaymentTypes(isCustomerPayment, isSupplierPayment);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

const initialState = {
    paymentTypeList: [],
};

export const paymentSlice = createSlice({
    name: 'payment',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getPaymentTypes.fulfilled, (state, action) => {
            state.paymentTypeList = action?.payload?.data?.data;
        });
    }
});

