import dataApi from '../apis/data.api';

const get = async (params) => {
    return dataApi.get('banks', { params });
};

const getBranches = async (accountId, cityId = null, params) => {
    return dataApi.get(`banks/${accountId}/branches${cityId ? ('?CityId=' + cityId) : ''}`, { params });
};

const getEtaBankAccounts = async () => {
    return dataApi.get('eta-bank-accounts');
};

const bankService = {
    get,
    getBranches,
    getEtaBankAccounts
};

export default bankService;
