import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import auctionService from 'core/services/auction.service';
import dayjs from 'dayjs';

export const getAuctions = createAsyncThunk(
    'getAuctions',
    async (params, { rejectWithValue }) => {
        try {
            return await auctionService.getAuctions(params);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getAuctionById = createAsyncThunk(
    'getAuctionById',
    async (auctionId, { rejectWithValue }) => {
        try {
            return await auctionService.getAuctionById(auctionId);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getSearchTemplates = createAsyncThunk(
    'getSearchTemplates',
    async (params, { rejectWithValue }) => {
        try {
            return await auctionService.getSearchTemplates(params);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const postSearchTemplates = createAsyncThunk(
    'postSearchTemplates',
    async (data, { rejectWithValue }) => {
        try {
            return await auctionService.postSearchTemplates(data);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const deleteSearchTemplates = createAsyncThunk(
    'deleteSearchTemplates',
    async (templateId, { rejectWithValue }) => {
        try {
            return await auctionService.deleteSearchTemplates(templateId);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const putSearchTemplates = createAsyncThunk(
    'putSearchTemplates',
    async ({id, data }, { rejectWithValue }) => {
        try {
            return await auctionService.putSearchTemplates(id, data);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const defaultFilteredDate = {
    startDate:dayjs().format('YYYY-MM-DD'),
    finishDate:dayjs().add(1, 'week').format('YYYY-MM-DD'),
};

const initialState = {
    auctions: [],
    auctionsPageProperty: {},
    auctionDetail: {},
    auctionFilter: defaultFilteredDate,
    isFetching: false,
    isDetailFetching: false,
    searchTemplates:{},
};

export const auctionSlice = createSlice({
    name: 'auction',
    initialState,
    reducers: {
        setAuctionFilter: (state, action) => {
            state.auctionFilter = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAuctions.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(getAuctions.fulfilled, (state, action) => {
            state.isFetching = false;
            state.auctions = action?.payload?.data?.data || [];
            state.auctionsPageProperty = {
                totalCount: action?.payload?.data?.totalCount,
                limit: action?.payload?.data?.limit,
                next: action?.payload?.data?.next,
                prev: action?.payload?.data?.prev,
            } || {};
        });
        builder.addCase(getAuctions.rejected, (state) => {
            state.isFetching = false;
            state.auctions = [];
            state.auctionsPageProperty = {};
        });
        builder.addCase(getAuctionById.pending, (state) => {
            state.isDetailFetching = true;
        });
        builder.addCase(getAuctionById.fulfilled, (state, action) => {
            state.auctionDetail = action?.payload?.data?.data || {};
            state.isDetailFetching = false;
        });
        builder.addCase(getAuctionById.rejected, (state) => {
            state.isDetailFetching = false;
            state.auctionDetail = {};
        });
        builder.addCase(getSearchTemplates.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(getSearchTemplates.fulfilled, (state, action) => {
            state.searchTemplates = action?.payload?.data || {};
            state.isFetching = false;
        });
        builder.addCase(getSearchTemplates.rejected, (state) => {
            state.searchTemplates = {};
            state.isFetching = false;
        });
    }
});

export const { setAuctionFilter } = auctionSlice.actions;
