import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { modals } from 'core/utils/common/modals';
import { notifications } from 'core/utils/common/notifications';
import { parse } from 'core/utils/helpers/parse';
import { EtaButton } from 'components/ui';
import { useHubs } from 'core/hubs/provider';
import dataApi from 'core/apis/data.api';

const NotificationHub = () => {
    const navigate = useNavigate();
    const { hubStart, hubConnection, hubDispose } = useHubs();
    const { isAuth } = useSelector(state => state.auth);

    useEffect(() => {
        if (isAuth) {
            hubStart('notifications');

            const connection = hubConnection('notifications');
            if (connection) {
                connection.on('ToastMessageReceived', message => {
                    notifications.default(message.toastType, message.isBodyHtml ? parse.stringToHtml(message.content) : message.content, message.title,{
                        placement: message.toastPosition
                    });
                });
                
                connection.on('DialogueMessageReceived', message => {
                    var modal = modals.confirm(message.title, message.isBodyHtml ? parse.stringToHtml(message.content) : message.content, {
                        footer: message.buttons.reverse().map((a,i) => <EtaButton key={i} type={a.type} onClick={() => {
                            switch(a.action){
                                case 2:
                                    a.link.includes('http://', 'https://') 
                                        ? location.href = a.link 
                                        : navigate(a.link);
                                    break;
                                case 1:
                                    dataApi({url: a.link,method: a.method.toUpperCase(),data: a.payload});
                                    break;
                                case 0:
                                    modal.destroy();
                                    break;
                            }
                        } }>{a.text}</EtaButton> )
                    });
                });
            }
        }
        else{
            hubDispose('notifications');
        }
    }, [isAuth]);
};

export default NotificationHub;