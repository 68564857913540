import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

export const modals = {
  info: (title, content) => Modal.info({ title: title, content: content }),
  success: (title, content) => Modal.success({ title: title, content: content }),
  error: (title, content) => Modal.error({ title: title, content: content }),
  warning: (title, content) => Modal.warning({ title: title, content: content }),
  confirm: (title, content, options = {}, width = 370) => Modal.confirm({
    width: width,
    title: title,
    content: content,
    icon: options.icon || <ExclamationCircleOutlined />,
    okText: options.okText || 'Evet',
    cancelText: options.cancelText || 'Hayır',
    okType: options.okType || 'primary',
    onOk: options.handleOk,
    onCancel: options.handleCancel,
    ...(options.footer && { footer: options.footer })
  })
};