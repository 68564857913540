import styled from 'styled-components';

const StyledButton = (ComponentName) => styled(ComponentName)`
    font-weight: 500;
    
    &.ant-btn-only-text {
        color: #94A1AE;
        padding: 0;
        background-color: #fff;
        border: unset;
        box-shadow: unset;
        height: auto;
    }

    &.ant-btn-primary-outline {
        color: #ff460f;
        background: #fff;
        border: 1px solid #ff460f;
    }

    &.ant-btn-secondary {
        color: #DAECFF;
        background-color: #556E87;
    }

    &.ant-btn-secondary-outline {
        color: #556e87;
        background: #fff;
        border-color: #dbe0e4;
    }

    &.ant-btn-light {
        color: #4c6278;
        background-color: #f3f3f3;
    }

    &.ant-btn-light-outline {
        color: #000000;
        background: #fff;
        border: 1px solid #d9d9d9;
    }

    &.ant-btn-success {
        color: #fff;
        background-color: #198754;
    }

    &.ant-btn-success-outline {
        color: #198754;
        background: #fff;
        border: 1px solid #a3cfbb;
    }

    &.ant-btn-info {
        color: #fff;
        background-color: #1677ff;
    }

    &.ant-btn-info-outline {
        color: #1890FF;
        background: #fff;
        border: 1px solid #1890FF;
    }

    &.ant-btn-warning {
        color: #fff;
        background-color: #faad14;
      
      :disabled {
        background-color: #ffe58f;
        cursor: not-allowed;
      }
    }

    &.ant-btn-warning-outline {
        color: #F46424;
        background: #fff;
        border: 1px solid #F46424;
    }

    &.ant-btn-danger {
        color: #fff;
        background-color: #b02a37;
    }

    &.ant-btn-danger-outline {
        color: #b02a37;
        background: #fff;
        border: 1px solid #f1aeb5;
    }

    &.ant-btn-text {
        color: #94A1AE;

        &.text-gray {
            color: #4C6278;
        }
        &.text-secondary{
            color: #1890FF;
        }
        &.text-success{
            color: #198754;
        }
        &.text-danger{
            color: #FF4D4F;
        }
        &.text-info{
            color: #1677ff;
        }
        &.text-warning{
            color: #faad14;
        }
    }
    
    &.ant-btn-xl {
        height: 50px;
        font-weight: 700;
        font-size: 16px;
        padding: 8px 18px;
    }
    .delete-draft {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .excel-export-button {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &:disabled{
        opacity: 0.5;
        cursor: not-allowed;
    }
`;

export {
    StyledButton
};