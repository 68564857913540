const RoutePaths = {
    HOME: '/',
    ACCOUNT: {
        PROFILE: '/hesap/profil',
        REGISTER_TO_COMPANY: '/hesap/firmaya-kayit-ol',
        REGISTER_COMPANY: '/hesap/firma-kaydi-olustur',
        AGREEMENTS: '/hesap/sozlesmelerim',
        REGISTER_TEMPORARY_COMPANY: '/hesap/gecici-firma-kaydi',
        REGISTER_APPROVAL: '/hesap/firma-kayit-onayi',
        JOIN_DEMANDS: '/hesap/katilim-talebi',
        NOT_COMPANY: '/hesap/firma-bulunamadi',
    },
    PENDING_APPROVAL_PROCESS: {
        ESCALATION_APPROVAL: '/onay-bekleyen-islemler/eskalasyon-onayi',
        CONTRACT_APPROVAL: '/onay-bekleyen-islemler/sozlesme-onayi',
        INVOICE_APPROVAL: '/onay-bekleyen-islemler/fatura-onayi'
    },
    NOTIFICATIONS: '/bildirimler',
    SUPPLIER: {
        SUPPLIER: '/tedarikci',
        ACCOUNT: {
            COMPANY: '/tedarikci/hesap/firma-profili',
            USERS: '/tedarikci/hesap/kullanicilar',
        },
        AUCTIONS: '/tedarikci/ihaleler',
        AUCTION_BIDS: '/tedarikci/teklifler',
        QUOTE_BIDS: '/tedarikci/sms-teklifleri',
        SHIPMENTS: '/tedarikci/seferler',
        SHIPMENT_DETAIL: (id) => `/tedarikci/sefer/detay/${id}`,
        VEHICLES: '/tedarikci/araclar',
        VEHICLE_DETAIL: (id) => `/tedarikci/arac/detay${id ? ('/' + id) : ''}`,
        VEHICLE_DOCUMENTS: (id) => `/tedarikci/arac/belgeler/${id}`,
        INVOICES: '/tedarikci/faturalar',
        PAYMENTS: '/tedarikci/odemeler',
        LOAD_DEMANDS: '/tedarikci/yuk-iste',
    },
    CUSTOMER: {
        CUSTOMER: '/musteri',
        ACCOUNT: {
            COMPANY: '/musteri/hesap/firma-profili',
            ADDRESS_BOOK: '/musteri/hesap/adresler',
            STORE_CARDS: '/musteri/hesap/kayitli-kartlar',
            DISCOUNT_VOUCHERS: '/musteri/hesap/indirim-ceklerim',
            INVOICING_CHOICES: '/musteri/hesap/faturalama-tercihleri',
            USERS: '/musteri/hesap/kullanicilar',
        },
        QUOTES: '/musteri/talepler',
        QUOTE_DETAIL: (id) => `/musteri/talep/detay/${id}`,
        BULK_DEMAND: '/musteri/toplu-talep',
        CART: '/musteri/sepet',
        FAVORITES: '/musteri/favoriler',
        SHIPMENTS: '/musteri/seferler',
        SHIPMENT_DETAIL: (id) => `/musteri/sefer/detay/${id}`,
        INVOICES: '/musteri/faturalar',
        BALANCE_AND_PAYMENTS: '/musteri/bakiye-ve-odemeler',
        QUOTE_CREATE: {
            VEHICLE: '/musteri/arac-talep/arac-ekle',
            ROUTE: '/musteri/arac-talep/rota-secimi',
            LOAD: '/musteri/arac-talep/yuk-secimi',
            SUMMARY: '/musteri/arac-talep/talep-ozeti',
            COMPLATE: '/musteri/arac-talep/talep-bilgileri',
        },
        ADVANTAGE_WALLET: '/musteri/eta-puan',
        ADVANTAGE_WALLET_DASHBOARD: '/musteri/eta-puan/hesap',
        ADVANTAGE_WALLET_INTRO: '/musteri/eta-puan/katil',
        ADVANTAGE_WALLET_DOCUMENT: '/musteri/eta-puan/dokuman',
        ADVANTAGE_WALLET_AGREEMENT: '/musteri/eta-puan/sozlesme',
        ADVANTAGE_WALLET_PAYMENT: '/musteri/eta-puan/harcama-noktalari',
        ADVANTAGE_WALLET_HISTORY: '/musteri/eta-puan/kullanim-puan-gecmisi',
        ADVANTAGE_WALLET_ERROR: '/musteri/eta-puan/hata',
        HOW_DOES_ETA_WORK: {
            PROCESS_GUIDE: '/musteri/eta-nasil-calisir/islem-rehberi',
        },
        LOCATION_TRACKING: '/musteri/dorse-takip',
    },
    AUTH: {
        LOGIN: '/uyelik/giris-yap',
        REGISTER: '/uyelik/kayit-ol',
        FORGOT_PASSWORD: '/uyelik/sifremi-unuttum',
        PHONE_VERIFICATION: '/uyelik/telefon-onayi',
        REGISTER_TO_COMPANY: '/uyelik/firmaya-kayit-ol',
        REGISTER_COMPANY: '/uyelik/firma-kaydi-olustur',
        REGISTER_TEMPORARY_COMPANY: '/uyelik/gecici-firma-kaydi',
        REGISTER_APPROVAL: '/uyelik/firma-kayit-onayi',
        JOIN_DEMANDS: '/uyelik/katilim-talebi',
        NOT_COMPLETED: '/uyelik/kayit-tamamlanmadi',
        NOT_COMPANY: '/uyelik/firma-bulunamadi',
        LOGIN_BLOCKED: '/uyelik/giris-engellendi',
    },
    UNAUTHORIZED: '/yetkisiz-erisim',
    REFRESH_AUTHORIZED: '/yeni-erisim',
    CUSTOMER_SHIPMENT_PREVIEW: (id) => `/sefer/onizleme/${id}`
};

export { RoutePaths };