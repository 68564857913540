import { keyframes } from 'styled-components';

// Transition
export function transition(timing = 0.3) {
    return `
        -webkit-transition: all ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
        -moz-transition: all ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
        -ms-transition: all ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
        -o-transition: all ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
        transition: all ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
    `;
}
// Border Radius
export function borderRadius(radius = 0) {
    return `
        -webkit-border-radius: ${radius};
        -moz-border-radius: ${radius};
        -ms-transition: ${radius};
        -o-border-radius: ${radius};
        border-radius: ${radius};
    `;
}
// Box Shadow
export function boxShadow(shadow = 'none') {
    return `
        -webkit-box-shadow: ${shadow};
        -moz-box-shadow: ${shadow};
        box-shadow: ${shadow};
    `;
}

export const createBoxShadowAsItemLength = (length) => {
    const shadowWidth = 5;

    let boxShadow = '';
    let offset = shadowWidth;
    for (let i = 2; i <= length; i++) {
        boxShadow += `${offset}px ${offset}px 0 -1px #ffffff, ${offset}px ${offset}px #f1f1f4`;
        offset += shadowWidth;
        if (i !== length) {
            boxShadow += ', ';
        }
    }
    return `
    box-shadow: ${boxShadow};
    `;
};

// Keyframes
export const slideInLeft = keyframes`
    from {
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
    }
    to {
        transform: translate3d(0, 0, 0);
    }
`;
export const slideOutLeft = keyframes`
    from {
        transform: translate3d(0, 0, 0);
    }
    to {
        visibility: hidden;
        transform: translate3d(-100%, 0, 0);
    }
`;
export const opacityOutAnimation = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;