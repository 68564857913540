import styled from 'styled-components';

const StyledDropdown = (ComponentName) => styled(ComponentName)`
    &.ant-dropdown-trigger {
        .ant-space {
            gap: 6px !important ;
        }
        .ant-space-item {
            font-size: 13px;
            font-weight: 600;
            line-height: 20px;
            color: #4C6278;
        }
    }
`;

export {
    StyledDropdown
};