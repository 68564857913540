import { lazy } from 'react';

//#region Auth
const Login = lazy(() => import('./auth/login'));
const LoginBlocked = lazy(() => import('./auth/login/login-blocked'));
const PhoneVerification = lazy(() => import('./auth/phone-verification'));
const ForgotPassword = lazy(() => import('./auth/forgot-password'));
const Register = lazy(() => import('./auth/register'));
const RegisterToCompany = lazy(() => import('./auth/register/register-to-company'));
const RegisterApproval = lazy(() => import('./auth/register/register-approval'));
const RegisterTemporaryCompany = lazy(() => import('./auth/register/register-temporary-company'));
const RegisterCompany = lazy(() => import('./auth/register/register-company'));
const NotCompleted = lazy(() => import('./auth/register/not-completed'));
const NotCompany = lazy(() => import('./auth/register/not-company'));
const JoinDemands = lazy(() => import('./auth/register/join-demands'));
//#endregion

//#region Common
const Home = lazy(() => import('./home'));
const NotFound = lazy(() => import('./errors/not-found'));
const UnAuthorized = lazy(() => import('./errors/un-authorized'));
const RefreshAuthorized = lazy(() => import('./errors/refresh-authorized'));
const Profile = lazy(() => import('./account/profile'));
const Agreements = lazy(() => import('./account/agreements'));
const Users = lazy(() => import('./account/users'));
const AddressBook = lazy(() => import('./account/address-book'));
const InvoicingChoices = lazy(() => import('./account/billing-preferences'));
const StoreCards = lazy(() => import('./store-cards'));
const Invoice = lazy(() => import('./invoices'));
const Notifications = lazy(() => import('./notifications'));

const EscalationApproval = lazy(() => import('./pending-approval/escalation'));
const EscalationApprovalDetail = lazy(() => import('./pending-approval/escalation/detail'));
const ContractApproval = lazy(() => import('./pending-approval/contract'));
const ContractApprovalDetail = lazy(() => import('./pending-approval/contract/detail'));
const InvoiceApproval = lazy(() => import('./pending-approval/invoice'));
const InvoiceApprovalDetail = lazy(() => import('./pending-approval/invoice/detail'));
//#endregion

//#region Customer
const CustomerDashboard = lazy(() => import('./customer/dashboard'));
const CustomerCompany = lazy(() => import('./customer/company'));
const CustomerShipment = lazy(() => import('./customer/shipments'));
const CustomerShipmentDetail = lazy(() => import('./customer/shipments/detail'));
const CustomerShipmentPreview = lazy(() => import('./customer/shipments/preview'));
const Quotes = lazy(() => import('./quotes'));
const QuoteDetail = lazy(() => import('./quotes/detail'));
const QuoteCreate = lazy(() => import('./quotes/create'));
const QuoteCreateVehicle = lazy(() => import('./quotes/create/vehicle'));
const QuoteCreateRoute = lazy(() => import('./quotes/create/route'));
const QuoteCreateLoad = lazy(() => import('./quotes/create/load'));
const QuoteCreateSummary = lazy(() => import('./quotes/create/summary'));
const QuoteCreateComplate = lazy(() => import('./quotes/create/complate'));
const Favorite = lazy(() => import('./favorites/index'));
const BalancePayments = lazy(() => import('../components/partials/dashboard/balance-payments'));
const Cart = lazy(() => import('./cart/index'));
const DiscountVouchers = lazy(() => import('./account/discount-vouchers'));
const AdvantageWallet = lazy(() => import('./advantage-wallet'));
const AdvantageWalletDashboard = lazy(() => import('./advantage-wallet/dashboard'));
const AdvantageWalletIntro = lazy(() => import('./advantage-wallet/intro'));
const AdvantageWalletDocument = lazy(() => import('./advantage-wallet/document'));
const AdvantageWalletAgreement = lazy(() => import('./advantage-wallet/agreement'));
const AdvantageWalletPayment = lazy(() => import('./advantage-wallet/payment'));
const AdvantageWalletHistory = lazy(() => import('./advantage-wallet/history'));
const AdvantageWalletError = lazy(() => import('./advantage-wallet/error'));
const CustomerProcessGuide = lazy(() => import('./customer/process-guide'));
const BulkDemand = lazy(() => import('./quotes/bulk-demand'));
const CustomerTrailerTracking = lazy(() => import('./customer/trailer-tracking'));
//#endregion

//#region Supplier
const SupplierDashboard = lazy(() => import('./supplier/dashboard'));
const SupplierCompany = lazy(() => import('./supplier/company'));
const Auctions = lazy(() => import('./supplier/auctions'));
const BidsExisting = lazy(() => import('./supplier/bids/auction-bids'));
const BidsSms = lazy(() => import('./supplier/bids/quote-bids'));
const SupplierShipment = lazy(() => import('./supplier/shipments'));
const SupplierShipmentDetail = lazy(() => import('./supplier/shipments/detail'));
const SupplierBalancePayments = lazy(() => import('./supplier/payments'));
const Vehicles = lazy(() => import('./supplier/vehicles'));
const VehicleDetail = lazy(() => import('./supplier/vehicles/detail'));
const LoadDemands = lazy(() => import('./supplier/load-demands'));
const VehicleDocuments = lazy(() => import('./supplier/vehicles/documents'));
//#endregion


export {
    Home,
    Login,
    LoginBlocked,
    Register,
    Profile,
    ForgotPassword,
    PhoneVerification,
    RegisterToCompany,
    RegisterApproval,
    RegisterTemporaryCompany,
    RegisterCompany,
    JoinDemands,
    NotCompany,
    NotCompleted,
    NotFound,
    UnAuthorized,
    RefreshAuthorized,
    QuoteCreate,
    QuoteCreateVehicle,
    QuoteCreateRoute,
    QuoteCreateLoad,
    QuoteCreateSummary,
    QuoteCreateComplate,
    InvoicingChoices,
    Users,
    AddressBook,
    Notifications,
    SupplierCompany,
    CustomerCompany,
    Quotes,
    QuoteDetail,
    CustomerDashboard,
    CustomerShipment,
    CustomerShipmentDetail,
    CustomerShipmentPreview,
    Favorite,
    Invoice,
    BalancePayments,
    StoreCards,
    DiscountVouchers,
    Cart,
    Auctions,
    BidsExisting,
    BidsSms,
    SupplierShipment,
    SupplierShipmentDetail,
    SupplierBalancePayments,
    CustomerTrailerTracking,
    Vehicles,
    SupplierDashboard,
    LoadDemands,
    VehicleDetail,
    EscalationApproval,
    EscalationApprovalDetail,
    ContractApproval,
    ContractApprovalDetail,
    InvoiceApproval,
    InvoiceApprovalDetail,
    AdvantageWallet,
    AdvantageWalletIntro,
    AdvantageWalletDocument,
    AdvantageWalletAgreement,
    AdvantageWalletPayment,
    AdvantageWalletHistory,
    AdvantageWalletDashboard,
    AdvantageWalletError,
    VehicleDocuments,
    CustomerProcessGuide,
    BulkDemand,
    Agreements
};