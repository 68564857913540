import styled from 'styled-components';
import { mediaResponseMax } from 'styles/mixins';

const StyledRadioButton = (ComponentName) => styled(ComponentName)`
    &.ant-user-profile {
        ${mediaResponseMax.xs`
            .ant-radio-button-wrapper {
                width: 50%;
                text-align: center;
            }
        `};
    }
    &.ant-radio-group {
        .ant-radio-wrapper:hover {
            .ant-radio > .ant-radio-inner {
                border-color: #1890FF;
            }
        }
        .ant-radio-wrapper-checked > .ant-radio-checked > .ant-radio-inner {
            background-color: #fff;
            border-color: #1890FF;
            &::after {
                transform: scale(0.500);
                background-color: #1890FF;
            }
        }
        .ant-radio-wrapper-checked::before {
            background-color: #FFD4C0;
        }
        .ant-radio-orange {
            font-size: 14px;
            border-color: #FFD4C0;
            &:last-child::before {
                display: none;
            }
            .ant-radio-button + span {
                font-size: 14px;
                font-weight: 400;
                color: #F46424;
            }
            .ant-radio-button-checked + span {
                font-weight: 500;
            }
        }
        .ant-radio-button-wrapper:not(:first-child)::before {
            background-color: #FFD4C0;
        }
        .ant-radio-button-wrapper-checked.ant-radio-orange {
            background-color: #FFF3EE;
        }
    }
    &.ant-tab-share-srip {
        margin-bottom: 28px;
    }
    &.pay-tabs {
        display: flex;
        flex-direction: column;
        
        .ant-radio-checked {
            .ant-radio-inner {
                background-color: transparent;
                &::after {
                    opacity: 1;
                    transform: rotate(45deg) scale(1) translate(70%, -25%) !important;
                    transition: all .2s cubic-bezier(.12,.4,.29,1.46) .1s;
                    background-color: transparent !important
                }
            }
        }
        .ant-radio-inner {
            width: 19px;
            height: 19px;
            &::after {
                content: "";
                position: absolute;
                box-sizing: border-box;
                opacity: 0;
                display: table;
                width: 6px;
                height: 11px;
                border: 2px solid #1890FF;
                border-top: 0;
                border-radius: unset;
                border-inline-start: 0;
            }
        }
        &.ant-radio-wrapper-checked {
            border-color: #1890FF;
        }
        .ant-radio-wrapper {
            margin-inline-end: 0px;
        }
        .pay-card {
            display: flex;
            align-items: center;
            border: 1px solid #DBE0E4;
            border-radius: 8px;
            padding-left: 20px;
            padding-right: 15px;
            min-height: 52px;

            .ant-radio > .ant-radio-inner {
                width: 19px;
                height: 19px;
            }
            .ant-radio + span {
                width: 100%;
                display: grid;
                grid-template-columns: 75% 25%;
                justify-content: space-between;
                align-items: center;
                padding-inline-start: 14px;
                padding-inline-end: 0px;
                color: #4C6278;

                .pay-title {
                    font-size: 14px;
                    font-weight: 500;

                    ${mediaResponseMax.sm`
                        width: 160px;
                        font-size: 12px;
                    `};
                }
                .pay-price {
                    font-size: 16px;
                    font-weight: 600;
                }
                .input-wrapper > .ant-input {
                    width: 106px;
                    height: 36px;
                    border-radius: 8px;
                    text-align: right;
                    font-size: 16px;
                    font-weight: 600;
                    color: #4C6278;
                }
            }
            &:not(:last-child) {
                margin-bottom: 12px;
            }
        }
        .pay-card-input {
            padding-right: 8px;
        }
    }
    &.payer-information-tabs {
        z-index: 0;
    }
    &.ant-radio-payment-method-cards {
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        ${mediaResponseMax.sm`
            flex-direction: column;
            align-items: flex-start;
        `};
        
        .ant-radio-wrapper {
            width: 270px;
            padding-bottom: 185px;

            &:last-child {
                margin-inline-end: 0px;
            }
            &:not(:last-child) {
                margin-inline-end: 30px;
            }
            & > span:not(.ant-radio) {
                position: relative;

                .eta-kredit-card {
                    position: absolute;
                    left: -15px;
                    top: 35px;
                }
            }
        }
        .ant-radio-wrapper-checked {
            .eta-kredit-card {
                border-color: #F46424;
            }
          .selected-card-header {
            color: #1890FF;
            font-weight: 500;
            font-size: 14px;
          }
        }
    }
`;

export {
    StyledRadioButton
};