import styled from 'styled-components';

const StyledInput = (ComponentName) => styled(ComponentName)`
    &.header-input-search {
        input{
            border-color: #dbe0e4;
        }
    }
`;

const StyledInputNumber = (ComponentName) => styled(ComponentName)`
  width: 100%;
`;
const StyledInputGroup = (ComponentName) => styled(ComponentName)`
    display: flex !important;

    &.ant-input-group-verified {
        .ant-input-affix-wrapper{
            border-start-end-radius: 0;
            border-end-end-radius: 0;
        }

        button{
            border-start-start-radius: 0;
            border-end-start-radius: 0;
        }
    }
`;

export {
    StyledInput,
    StyledInputNumber,
    StyledInputGroup
};