import styled from 'styled-components';

const StyledPagination = (ComponentName) => styled(ComponentName)`
        .ant-pagination-item {
            border-radius: 2px;
            border-color: #D9D9D9;
            &:hover {
                border-color: #f26b51;
                background-color: #fff;
            }
            a {
                font-size: 14px;
                font-weight: 400;
                color: #000;
            }
        }
        .ant-pagination-item-active {
            border-color: #1890FF;
            a {
                color: #1890FF;
            }
        }
        .ant-pagination-prev,
        .ant-pagination-next {
            border-radius: 2px;
            .ant-pagination-item-link {
                border-radius: 2px;
                border-color: #D9D9D9;
            }
        }
        .ant-pagination-options > .ant-select > .ant-select-selector {
            border-radius: 2px;
        }
`;

export {
    StyledPagination
};