const AccountType = {							
    /** Yük Sahibi */
    Customer: 1, 
    /** Kamyoncu */
    Supplier: 2, 
    /** Banka */
    Bank: 3, 
    /** Etaşımacılık */
    Company: 4,
    /** İş Ortağı */
    Vendor: 12,  
};

export default AccountType;