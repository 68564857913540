import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import accountService from 'core/services/account.service';

export const get = createAsyncThunk(
    'getAccount',
    async (_, { rejectWithValue }) => {
        try {
            return await accountService.get();
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const deleteAccount = createAsyncThunk(
    'deleteAccount',
    async (_, { rejectWithValue }) => {
        try {
            return await accountService.deleteAccount();
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getPersons = createAsyncThunk(
    'getPersons',
    async (params, { rejectWithValue }) => {
        try {
            return await accountService.getPersons(params);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);
export const getBalance = createAsyncThunk(
    'getBalance',
    async (_, { rejectWithValue }) => {
        try {
            return await accountService.getBalance();
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getPersonsSearch = createAsyncThunk(
    'getPersonsSearch',
    async (params, { rejectWithValue }) => {
        try {
            return await accountService.getPersonsSearch(params);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getTaxNumbersCheck = createAsyncThunk(
    'getTaxNumbersCheck',
    async ({ taxNumber, accountType }, { rejectWithValue }) => {
        try {
            return await accountService.getTaxNumbersCheck(taxNumber, { accountType });
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getCompanyTypes = createAsyncThunk(
    'getCompanyTypes',
    async (_, { rejectWithValue }) => {
        try {
            return await accountService.getCompanyTypes();
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getLocations = createAsyncThunk(
    'getLocations',
    async (data, { rejectWithValue }) => {
        try {
            return await accountService.getLocations(data);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const postLocations = createAsyncThunk(
    'postLocations',
    async (data, { rejectWithValue }) => {
        try {
            return await accountService.postLocations(data);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const putLocations = createAsyncThunk(
    'putLocations',
    async ({ locationId, data }, { rejectWithValue }) => {
        try {
            return await accountService.putLocations(locationId, data);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getInvoicingChoicesLines = createAsyncThunk(
    'getInvoicingChoicesLines',
    async (_, { rejectWithValue }) => {
        try {
            return await accountService.getInvoicingChoicesLines();
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getJoinDemands = createAsyncThunk(
    'getJoinDemands',
    async (params, { rejectWithValue }) => {
        const newParams = {
            ...params,
            accountJoinDemandStatus: 3
        };
        try {
            return await accountService.getJoinDemands(newParams);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const deleteJoinDemands = createAsyncThunk(
    'deleteJoinDemands',
    async (accountJoinDemandId, { rejectWithValue }) => {
        try {
            return await accountService.deleteJoinDemands(accountJoinDemandId);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getInvoices = createAsyncThunk(
    'getInvoices',
    async (params, { rejectWithValue }) => {
        try {
            return await accountService.getInvoices(params);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const patchJoinDemands = createAsyncThunk(
    'patchJoinDemands',
    async (accountJoinDemandId, { rejectWithValue }) => {
        try {
            return await accountService.patchJoinDemands(accountJoinDemandId);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const deleteLocations = createAsyncThunk(
    'deleteLocations',
    async (locationId, { rejectWithValue }) => {
        try {
            return await accountService.deleteLocations(locationId);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const deletePerson = createAsyncThunk(
    'deletePerson',
    async (personId, { rejectWithValue }) => {
        try {
            return await accountService.deletePerson(personId);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getPersonsRoles = createAsyncThunk(
    'getPersonsRoles',
    async (personId, { rejectWithValue }) => {
        try {
            return await accountService.getPersonsRoles(personId);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const putPersonsRoles = createAsyncThunk(
    'putPersonsRoles',
    async ({ personId, data }, { rejectWithValue }) => {
        try {
            return await accountService.putPersonsRoles(personId, data);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const putPersonsType = createAsyncThunk(
    'putPersonsType',
    async ({ personId, data }, { rejectWithValue }) => {
        try {
            return await accountService.putPersonsType(personId, data);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getTaxNumbersMaskedName = createAsyncThunk(
    'getTaxNumbersMaskedName',
    async (taxNumber, { rejectWithValue }) => {
        try {
            return await accountService.getTaxNumbersMaskedName(taxNumber);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getVehicles = createAsyncThunk(
    'getVehicles',
    async (params, { rejectWithValue }) => {
        try {
            return await accountService.getVehicles(params);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);
export const getVehiclesById = createAsyncThunk(
    'getVehiclesById',
    async (vehicleId, { rejectWithValue }) => {
        try {
            return await accountService.getVehiclesById(vehicleId);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const putAsCustomer = createAsyncThunk(
    'putAsCustomer',
    async (params, { rejectWithValue }) => {
        try {
            return await accountService.putAsCustomer(params);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const putAsSupplier = createAsyncThunk(
    'putAsSupplier',
    async (params, { rejectWithValue }) => {
        try {
            return await accountService.putAsSupplier(params);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);


const initialState = {
    account: {},
    companyTypes: [],
    locations: [],
    persons: [],
    balance: {},
    personsSearch: [],
    personsPageProperty: {},
    locationsPageProperty: {},
    isFetching: false,
    invoicingChoices: [],
    joinDemands: [],
    joinDemandsPageProperty: {},
    invoices: [],
    invoicesPageProperty: {},
    filterDataForInvoices: {},
    personRoles: [],
    accountVehicles: [],
    vehiclesPageProperty: {},
    vehicleById: {}
};

export const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        setFilteredInvoice: (state, action) => {
            state.filterDataForInvoices = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(get.fulfilled, (state, action) => {
            state.account = action?.payload?.data;
        });
        builder.addCase(getCompanyTypes.fulfilled, (state, action) => {
            state.companyTypes = action?.payload?.data?.data || [];
        });
        builder.addCase(getPersons.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(getVehicles.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(getPersons.fulfilled, (state, action) => {
            state.persons = action?.payload?.data?.data || [];
            state.personsPageProperty = {
                totalCount: action?.payload?.data?.totalCount,
                limit: action?.payload?.data?.limit,
                next: action?.payload?.data?.next,
                prev: action?.payload?.data?.prev,
            } || {};
            state.isFetching = false;
        });
        builder.addCase(getBalance.fulfilled, (state, action) => {
            state.balance = action?.payload?.data?.data || [];
        });
        builder.addCase(getPersons.rejected, (state) => {
            state.persons = [];
            state.personsPageProperty = {};
            state.isFetching = false;
        });
        builder.addCase(getPersonsSearch.fulfilled, (state, action) => {
            state.personsSearch = action?.payload?.data?.data;
        });
        builder.addCase(getLocations.fulfilled, (state, action) => {
            state.locations = action?.payload?.data?.data || [];
            state.locationsPageProperty = {
                totalCount: action?.payload?.data?.totalCount,
                limit: action?.payload?.data?.limit,
                next: action?.payload?.data?.next,
                prev: action?.payload?.data?.prev,
            } || {};
            state.isFetching = false;
        });
        builder.addCase(getLocations.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(getLocations.rejected, (state) => {
            state.isFetching = false;
        });
        builder.addCase(getInvoicingChoicesLines.fulfilled, (state, action) => {
            const editedData = action?.payload?.data?.data?.map((item) => {
                return {
                    title: item?.name,
                    key: item?.invoicingLineDescriptionField,
                    order: item?.order,
                    isSelected: item?.isSelected,
                };
            }).sort((a, b) => a.order - b.order);
            state.invoicingChoices = editedData || [];
        });
        builder.addCase(getJoinDemands.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(getJoinDemands.fulfilled, (state, action) => {
            state.joinDemands = action?.payload?.data?.data || [];
            state.joinDemandsPageProperty = {
                totalCount: action?.payload?.data?.totalCount,
                limit: action?.payload?.data?.limit,
                next: action?.payload?.data?.next,
                prev: action?.payload?.data?.prev,
            } || {};
            state.isFetching = false;
        });
        builder.addCase(getJoinDemands.rejected, (state) => {
            state.isFetching = false;
        });
        builder.addCase(getInvoices.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(getInvoices.fulfilled, (state, action) => {
            state.invoices = action?.payload?.data?.data || [];
            state.invoicesPageProperty = {
                totalCount: action?.payload?.data?.totalCount,
                limit: action?.payload?.data?.limit,
                next: action?.payload?.data?.next,
                prev: action?.payload?.data?.prev,
            } || {};
            state.isFetching = false;
        });
        builder.addCase(getInvoices.rejected, (state) => {
            state.invoices = [];
            state.invoicesPageProperty = {};
            state.isFetching = false;
        });
        builder.addCase(getPersonsRoles.fulfilled, (state, action) => {
            state.personRoles = action?.payload?.data?.data || [];
        });
        builder.addCase(getVehicles.fulfilled, (state, action) => {
            state.accountVehicles = action?.payload?.data?.data || [];
            state.vehiclesPageProperty = {
                totalCount: action?.payload?.data?.totalCount,
                limit: action?.payload?.data?.limit,
                next: action?.payload?.data?.next,
                prev: action?.payload?.data?.prev,
            } || {};
            state.isFetching = false;
        });
        builder.addCase(getVehicles.rejected, (state) => {
            state.isFetching = false;
        });
        builder.addCase(postLocations.fulfilled, (state, action) => {
            state.isFetching = false;
        });
        builder.addCase(postLocations.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(postLocations.rejected, (state) => {
            state.isFetching = false;
        });
        builder.addCase(getVehiclesById.fulfilled, (state, action) => {
            state.vehicleById = action?.payload?.data?.data || {};
        });
    }
});
export const { setFilteredInvoice } = accountSlice.actions;
