import styled from 'styled-components';

const ImageWrapper = styled.figure`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${(props) => props.width ? props.width : 'none'};
    height: ${(props) => props.height ? props.height : 'none'};
    
    img{
        max-width: ${(props) => props.width ? props.width : 'none'};
        max-height: ${(props) => props.height ? props.height : 'none'};
        width: 100%;
        height: 100%;
        object-fit: ${(props) => props.objectFit};
    }

  &.cart-delete-btn {
    :hover {
      cursor: pointer;
    }
  }
`;

export { ImageWrapper };