import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import virtualPosService from 'core/services/virtualpos.service';
import { notifications } from 'core/utils/common/notifications';

export const getVirtualPosCardsByBinNumber = createAsyncThunk(
    'getVirtualPosCardsByBinNumber',
    async (binNumber, { rejectWithValue }) => {
        try {
            return await virtualPosService.getVirtualPosCardsByBinNumber(binNumber);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getInstallments = createAsyncThunk(
    'getInstallments',
    async (params, { rejectWithValue }) => {
        try {
            return await virtualPosService.getInstallments(params);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getInstallmentsByCardBrandCode = createAsyncThunk(
    'getInstallmentsByCardBrandCode',
    async ({cardBrandCode, params}, { rejectWithValue }) => {
        try {
            if(params?.amount > 0) return await virtualPosService.getInstallmentsByCardBrandCode(cardBrandCode, params);
            return notifications.error('Lütfen geçerli bir tutar giriniz.');
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getStoredCards = createAsyncThunk(
    'getStoredCards',
    async (_, { rejectWithValue }) => {
        try {
            return await virtualPosService.getStoredCards();
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const postPayments = createAsyncThunk(
    'postPayments',
    async ({ data }, { rejectWithValue }) => {
        try {
            return await virtualPosService.postPayments(data);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const deleteStoredCard = createAsyncThunk(
    'deleteStoredCard',
    async (storeCardId, { rejectWithValue }) => {
        try {
            return await virtualPosService.deleteStoredCard(storeCardId);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const putStoreCards = createAsyncThunk(
    'putStoreCards',
    async ({ storeCardId, data }, { rejectWithValue }) => {
        try {
            return await virtualPosService.putStoreCards(storeCardId, data);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getTransactionMessage = createAsyncThunk(
    'getTransactionMessage',
    async (transactionId, { rejectWithValue }) => {
        try {
            return await virtualPosService.getTransactionMessage(transactionId);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

const initialState = {
    binNumberData: {},
    installments: [],
    isFetching: false,
    installmentsOfCardBrandCode: [],
    cardErrorText: '',
    selectedInstallment: null,
    storedCards: [],
    selectedStoredCard: null,
    storedCardFetching: false,
    currentPaymentLocation: null,
    selectedPaymentBalance:{},
    formData:{},
    transactionMessage: null
};

export const virtualPosSlice = createSlice({
    name: 'virtualPos',
    initialState,
    reducers: {
        setBinNumberData: (state, action) => {
            state.binNumberData = action.payload;
        },
        setError: (state, action) => {
            state.cardErrorText = action.payload;
        },
        setSelectedInstallment: (state, action) => {
            state.selectedInstallment = action.payload;
        },
        setSelectedStoredCard: (state, action) => {
            state.selectedStoredCard = action.payload;
        },
        setCurrentPaymentLocation: (state, action) => {
            state.currentPaymentLocation = action.payload;
        },
        setSelectedPaymentBalance: (state, action) => {
            state.selectedPaymentBalance = action.payload;
        },
        setFormData: (state, action) => {
            state.formData = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getVirtualPosCardsByBinNumber.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(getVirtualPosCardsByBinNumber.fulfilled, (state, action) => {
            state.isFetching = false;
            state.binNumberData = action?.payload?.data?.data || {};
        });
        builder.addCase(getVirtualPosCardsByBinNumber.rejected, (state) => {
            state.isFetching = false;
            state.binNumberData = {};
        });
        builder.addCase(getInstallments.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(getInstallments.fulfilled, (state, action) => {
            state.isFetching = false;
            state.installments = action?.payload?.data?.data || [];
        });
        builder.addCase(getInstallments.rejected, (state) => {
            state.isFetching = false;
            state.installments = [];
        });
        builder.addCase(getInstallmentsByCardBrandCode.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(getInstallmentsByCardBrandCode.fulfilled, (state, action) => {
            state.isFetching = false;
            state.installmentsOfCardBrandCode = action?.payload?.data?.data || [];
        });
        builder.addCase(getInstallmentsByCardBrandCode.rejected, (state) => {
            state.isFetching = false;
            state.installmentsOfCardBrandCode = [];
        });
        builder.addCase(getStoredCards.pending, (state) => {
            state.storedCardFetching = true;
        });
        builder.addCase(getStoredCards.fulfilled, (state, action) => {
            const tempStoredCards = action?.payload?.data?.data || [];
            state.storedCardFetching = false;
            state.storedCards = tempStoredCards.map((item) => {
                const maskedCardNumber = '*'.repeat(12) + item?.cardNumber.slice(-4);
                return {
                    ...item,
                    cardNumber: maskedCardNumber?.replace(/(.{4})/g, '$1 ').trim() || ''
                };
            });
        });
        builder.addCase(getStoredCards.rejected, (state) => {
            state.storedCardFetching = false;
            state.storedCards = [];
        });
        builder.addCase(getTransactionMessage.fulfilled, (state, action) => {
            state.transactionMessage = action?.payload?.data?.data || null;
        });
    }
});

export const {
    setBinNumberData,
    setError,
    setSelectedInstallment,
    setSelectedStoredCard,
    setCurrentPaymentLocation,
    setSelectedPaymentBalance,
    setFormData
} = virtualPosSlice.actions;