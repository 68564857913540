import dataApi, { deleteQuery, getQuery, putQuery } from '../apis/data.api';

const get = async () => {
  return dataApi.get('person');
};

const post = async (data) => {
  return dataApi.post('persons', data);
};

const getTypes = async () => {
  return dataApi.get('persons/types');
};

const putEmail = async (data) => {
  return dataApi.put('person/email', data);
};

const putPhone = async (data) => {
  return dataApi.put('person/phone', data);
};

const putPassword = async (data) => {
  return dataApi.put('person/password', data);
};

const getIntro = async () => {
  return dataApi.get('person/intro');
};

const getJoinDemands = async (params) => {
  return getQuery('person/join-demands', params);
};

const deleteJoinDemand = async (accountJoinDemandId) => {
  return dataApi.delete(`person/join-demands/${accountJoinDemandId}`);
};

const getNotifications = async (params) => {
  return getQuery('person/notifications', params);
};

const getNotificationCount = async (params) => {
  return getQuery('person/notifications/count', params);
};

const getNotificationById = async (notificationId) => {
  return getQuery(`person/notifications/${notificationId}`);
};

const putNotificationRead = async (notificationId) => {
  return dataApi.put(`person/notifications/${notificationId}/read`);
};

const getNotificationsSettings = async () => {
  return dataApi.get('person/notifications-settings');
};

const putNotificationsSettings = async (params) => {
  return putQuery('person/notifications-settings', params);
};

const putJoinRoles = async () => {
  return dataApi.put('person/join-roles');
};

const putNotificationsRead = async (params) => {
  return putQuery('person/notifications-read', params);
};

const deleteNotifications = async (params) => {
  return deleteQuery('person/notifications', params);
};

const postCustomerGainDocument = async (documentId) => {
  return dataApi.post(`person/customer-gain/document/${documentId}`);
};

const getCustomerGainAccessibility = async () => {
  return dataApi.get('person/customer-gain-accessibility');
};

const postApproveCustomerGainAggrements = async () => {
  return dataApi.post('person/approve-customer-gain-aggrements');
};

const personService = {
  get,
  post,
  getTypes,
  putEmail,
  putPhone,
  putPassword,
  getIntro,
  getJoinDemands,
  deleteJoinDemand,
  getNotifications,
  getNotificationCount,
  getNotificationById,
  putNotificationRead,
  getNotificationsSettings,
  putNotificationsSettings,
  putJoinRoles,
  putNotificationsRead,
  deleteNotifications,
  postCustomerGainDocument,
  getCustomerGainAccessibility,
  postApproveCustomerGainAggrements
};

export default personService;
