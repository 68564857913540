import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import supplierService from '../../core/services/supplier.service';
import dayjs from 'dayjs';

export const get = createAsyncThunk(
    'getSupplier',
    async (_, { rejectWithValue }) => {
        try {
            return await supplierService.get();
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);
export const post = createAsyncThunk(
    'postSupplier',
    async (data, { rejectWithValue }) => {
        try {
            return await supplierService.post(data);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const put = createAsyncThunk(
    'putSupplier',
    async (data, { rejectWithValue }) => {
        try {
            return await supplierService.put(data);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const postAuctionsBid = createAsyncThunk(
    'postAuctionsBid',
    async ({ auctionId, data }, { rejectWithValue }) => {
        try {
            return await supplierService.postAuctionsBid(auctionId, data);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getBids = createAsyncThunk(
    'getBids',
    async (params, { rejectWithValue }) => {
        try {
            return await supplierService.getBids(params);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const deleteAuctionBid = createAsyncThunk(
    'deleteAuctionBid',
    async (auctionBidId, { rejectWithValue }) => {
        try {
            return await supplierService.deleteAuctionBid(auctionBidId);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getShipments = createAsyncThunk(
    'getShipments',
    async (params, { rejectWithValue }) => {
        try {
            return await supplierService.getShipments(params);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getShipmentById = createAsyncThunk(
    'getShipmentById',
    async (shipmentId, { rejectWithValue }) => {
        try {
            return await supplierService.getShipmentById(shipmentId);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);


export const getPayments = createAsyncThunk(
    'getPayments',
    async (params, { rejectWithValue }) => {
        try {
            return await supplierService.getPayments(params);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getShipmentCount = createAsyncThunk(
    'getShipmentCount',
    async (params, { rejectWithValue }) => {
        try {
            return await supplierService.getShipmentCount(params);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getProcuredShipmentsCount = createAsyncThunk(
    'getProcuredShipmentsCount',
    async (params, { rejectWithValue }) => {
        try {
            return await supplierService.getProcuredShipmentsCount(params);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);
export const getNotificationEmails = createAsyncThunk(
    'getNotificationEmails',
    async (_, { rejectWithValue }) => {
        try {
            return await supplierService.getNotificationEmails();
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const putNotificationEmails = createAsyncThunk(
    'putNotificationEmails',
    async (data, { rejectWithValue }) => {
        try {
            return await supplierService.putNotificationEmails(data);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const postLoadDemands = createAsyncThunk(
    'postLoadDemands',
    async (data, { rejectWithValue }) => {
        try {
            return await supplierService.postLoadDemands(data);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const postAuctionsAssignment = createAsyncThunk(
    'postAuctionsAssignment',
    async (auctionBidId, { rejectWithValue }) => {
        try {
            return await supplierService.postAuctionsAssignment(auctionBidId);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const deleteAuctionsAssignment = createAsyncThunk(
    'deleteAuctionsAssignment',
    async (auctionBidId, { rejectWithValue }) => {
        try {
            return await supplierService.deleteAuctionsAssignment(auctionBidId);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const postTaxNumbersJoinDemands = createAsyncThunk(
    'postTaxNumbersJoinDemands',
    async (taxNumber, { rejectWithValue }) => {
        try {
            return await supplierService.postTaxNumbersJoinDemands(taxNumber);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getTrailers = createAsyncThunk(
    'getTrailers',
    async (params, { rejectWithValue }) => {
        try {
            return await supplierService.getTrailers(params);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getTrailerById = createAsyncThunk(
    'getTrailerById',
    async (trailerId, { rejectWithValue }) => {
        try {
            return await supplierService.getTrailerById(trailerId);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getQuoteBidsTransactions = createAsyncThunk(
    'getQuoteBidsTransactions',
    async (params, { rejectWithValue }) => {
        try {
            return await supplierService.getQuoteBidsTransactions(params);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const defaultFilteredDate = {
    startDateTime: dayjs().subtract(1, 'week').format('YYYY-MM-DD'),
    finishDateTime: dayjs().add(1, 'week').format('YYYY-MM-DD'),
};

export const defaultFilteredPayment = {
    startDate: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
    finishDate: dayjs().format('YYYY-MM-DD'),
};

export const defaultFilteredBids = {
    startDate: dayjs().subtract(1, 'week').format('YYYY-MM-DD'),
    finishDate: dayjs().add(1, 'week').format('YYYY-MM-DD'),
};



const initialState = {
    supplier: {},
    isFetching: false,
    bids: [],
    filterObjectBids: defaultFilteredBids,
    bidsPageProperty: {},
    shipment: [],
    shipmentPageProperty: {},
    isDetail: false,
    filteredObject: defaultFilteredDate,
    shipmentDetail: {},
    payments: [],
    paymentsPageProperty: {},
    filteredPayment: defaultFilteredPayment,
    shipmentCount: [],
    procuredShipmentsCount: [],
    supplierShipmentHookFilteredObject: defaultFilteredDate,
    notificationEmails: [],
    trailerList: [],
    isFetchingTrailerList: false,
    trailerDetail: {},
    smsBidsList: [],
    smsBidsPageProperty: {},
    smsBidsFilterObject: {},
    isFetchingSmsBids: false,
};

export const supplierSlice = createSlice({
    name: 'supplier',
    initialState,
    reducers: {
        setFilterObjectBids: (state, action) => {
            state.filterObjectBids = action.payload;
        },
        setIsDetail: (state, action) => {
            state.isDetail = action.payload;
        },
        setFilteredObject: (state, action) => {
            state.filteredObject = action.payload;
        },
        setFilteredPayment: (state, action) => {
            state.filteredPayment = action.payload;
        },
        setSupplierShipmentHookFilteredObject: (state, action) => {
            state.supplierShipmentHookFilteredObject = action.payload;
        },
        setDetailClear: (state, action) => {
            state.shipmentDetail = {};
        },
        setSmsBidsFilteredObject: (state, action) => {
            state.smsBidsFilterObject = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getBids.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(get.fulfilled, (state, action) => {
            state.supplier = action?.payload?.data?.data;
        });
        builder.addCase(getBids.fulfilled, (state, action) => {
            state.bids = action?.payload?.data?.data;
            state.bidsPageProperty = {
                totalCount: action?.payload?.data?.totalCount,
                limit: action?.payload?.data?.limit,
                next: action?.payload?.data?.next,
                prev: action?.payload?.data?.prev,
            } || {};
            state.isFetching = false;
        });
        builder.addCase(getBids.rejected, (state) => {
            state.bids = [];
            state.isFetching = false;
        });
        builder.addCase(getShipments.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(getShipments.fulfilled, (state, action) => {
            state.shipment = action?.payload?.data?.data;
            state.shipmentPageProperty = {
                totalCount: action?.payload?.data?.totalCount,
                limit: action?.payload?.data?.limit,
                next: action?.payload?.data?.next,
                prev: action?.payload?.data?.prev,
            } || {};
            state.isFetching = false;
        });
        builder.addCase(getShipments.rejected, (state, action) => {
            state.shipment = [];
            state.isFetching = false;
        });
        builder.addCase(getShipmentById.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(getShipmentById.fulfilled, (state, action) => {
            state.shipmentDetail = action?.payload?.data?.data;
            state.isFetching = false;
        });
        builder.addCase(getShipmentById.rejected, (state) => {
            state.shipmentDetail = {};
            state.isFetching = false;
        });
        builder.addCase(getPayments.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(getPayments.fulfilled, (state, action) => {
            state.payments = action?.payload?.data?.data;
            state.paymentsPageProperty = {
                totalCount: action?.payload?.data?.totalCount,
                limit: action?.payload?.data?.limit,
                next: action?.payload?.data?.next,
                prev: action?.payload?.data?.prev,
            };
            state.isFetching = false;
        });
        builder.addCase(getPayments.rejected, (state, action) => {
            state.payments = [];
            state.paymentsPageProperty = {},
                state.isFetching = false;
        });
        builder.addCase(getShipmentCount.fulfilled, (state, action) => {
            state.shipmentCount = action?.payload?.data?.data;
        });
        builder.addCase(getProcuredShipmentsCount.fulfilled, (state, action) => {
            state.procuredShipmentsCount = action?.payload?.data?.data;
        });
        builder.addCase(getNotificationEmails.fulfilled, (state, action) => {
            state.notificationEmails = action?.payload?.data?.data;
        });
        builder.addCase(getTrailers.pending, (state, action) => {
            state.isFetchingTrailerList = true;
        });
        builder.addCase(getTrailers.rejected, (state, action) => {
            state.trailerList = action?.payload?.data?.data || [];
            state.isFetchingTrailerList = false;
        });
        builder.addCase(getTrailers.fulfilled, (state, action) => {
            state.trailerList = action?.payload?.data?.data || [];
            state.isFetchingTrailerList = false;
        });
        builder.addCase(getTrailerById.fulfilled, (state, action) => {
            state.trailerDetail = action?.payload?.data?.data;
        });
        builder.addCase(getQuoteBidsTransactions.pending, (state) => {
            state.isFetchingSmsBids = true;
        });
        builder.addCase(getQuoteBidsTransactions.fulfilled, (state, action) => {
            state.smsBidsList = action?.payload?.data?.data;
            state.smsBidsPageProperty = {
                totalCount: action?.payload?.data?.totalCount,
                limit: action?.payload?.data?.limit,
                next: action?.payload?.data?.next,
                prev: action?.payload?.data?.prev,
            };
            state.isFetchingSmsBids = false;
        });
        builder.addCase(getQuoteBidsTransactions.rejected, (state, action) => {
            state.payments = [];
            state.smsBidsPageProperty = {};
            state.isFetchingSmsBids = false;
        });
    }
});

export const {
    setIsDetail,
    setFilteredObject,
    setFilterObjectBids,
    setFilteredPayment,
    setSupplierShipmentHookFilteredObject,
    setDetailClear,
    setSmsBidsFilteredObject,
} = supplierSlice.actions;
