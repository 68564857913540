import axios from 'axios';

export const auth = axios.create({
  baseURL: process.env.REACT_APP_AUTH_API_URL
});

auth.interceptors.request.use(
  (config) => {
    config.params = { ...config.params, v: Date.now() };
    return config;
  },
  (error) => Promise.reject(error)
);

auth.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        console.log('401');
      } else {
        return Promise.reject(error.response.data);
      }
    }
    return Promise.reject(error);
  }
);

export default auth;