import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Cookies } from 'react-cookie';
import { decodeToken, setToken } from 'core/utils/helpers/converter';
import { logout } from 'store/slice/auth.slice';
import walletService from 'core/services/wallet.service';
import { RoutePaths } from 'router/paths';

let cookie = new Cookies();

export const walletLogin = createAsyncThunk(
    'walletLogin',
    async (_, {rejectWithValue, dispatch}) => {
        try {
            const { name } = decodeToken('accessToken');
            if(!name) return dispatch(logout(() => location.pathname = RoutePaths.AUTH.LOGIN));
            const walletId = localStorage.getItem('walletId');

            let formData = new URLSearchParams();
            formData.append('grant_type', 'password');
            formData.append('client_id', 'Eta.Wallet.API');
            formData.append('client_secret', '75A67D20-33ED-4D18-8BFA-A6618D444E94');
            formData.append('identifier_type', 1); // Identifier Type 4 => Telefon ile giriş
            formData.append('username', name);
            formData.append('password', walletId);
            return await walletService.walletLogin(formData);
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const walletRefresh = createAsyncThunk(
    'walletRefresh',
    async (_, { rejectWithValue }) => {
      try {
        let formData = new URLSearchParams();
        formData.append('grant_type', 'refresh_token');
        formData.append('client_id', 'Eta.Wallet.API');
        formData.append('client_secret', '75A67D20-33ED-4D18-8BFA-A6618D444E94');
        formData.append('refresh_token', cookie.get('refreshTokenWallet', { path: '/' }));

        return await walletService.walletLogin(formData);
      } catch (error) {
        return rejectWithValue(error);
      }
    },
);

export const getCustomerPersonWallet = createAsyncThunk(
  'getCustomerPersonWallet',
  async (_, { rejectWithValue }) => {
    try {
      return await walletService.getCustomerPersonWallet();
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const postCustomerPersonWallet = createAsyncThunk(
  'postCustomerPersonWallet',
  async (body, { rejectWithValue }) => {
    try {
      return await walletService.postCustomerPersonWallet(body);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getQrCode = createAsyncThunk(
  'getQrCode',
  async (_, { rejectWithValue }) => {
    try {
      return await walletService.getQrCode();
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getMerchants = createAsyncThunk(
  'getMerchants',
  async (params, { rejectWithValue }) => {
    try {
      return await walletService.getMerchants(params);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getMerchantFilters = createAsyncThunk(
  'getMerchantFilters',
  async (_, { rejectWithValue }) => {
    try {
      return await walletService.getMerchantFilters();
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getCampaigns = createAsyncThunk(
  'getCampaigns',
  async (_, { rejectWithValue }) => {
    try {
      return await walletService.getCampaigns();
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getTransactions = createAsyncThunk(
  'getTransactions',
  async (params, { rejectWithValue }) => {
    try {
      return await walletService.getTransactions(params);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getTransactionStatusTypes = createAsyncThunk(
  'getTransactionStatusTypes',
  async (_, { rejectWithValue }) => {
    try {
      return await walletService.getTransactionStatusTypes();
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const postCoupon = createAsyncThunk(
  'postCoupon',
  async (body, { rejectWithValue }) => {
    try {
      return await walletService.postCoupon(body);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getCoupons = createAsyncThunk(
  'getCoupons',
  async (params, { rejectWithValue }) => {
    try {
      return await walletService.getCoupons(params);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getCouponFilters = createAsyncThunk(
  'getCouponFilters',
  async (_, { rejectWithValue }) => {
    try {
      return await walletService.getCouponFilters();
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getHowToUseCoupon = createAsyncThunk(
  'getHowToUseCoupon',
  async (merchantId, { rejectWithValue }) => {
    try {
      return await walletService.getHowToUseCoupon(merchantId);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const logoutWallet = createAsyncThunk(
    'logoutWallet',
    async (callback = () => {
    }, {rejectWithValue}) => {
        try {
            return callback();
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const getLoginPoints = createAsyncThunk(
    'getLoginPoints',
    async (personId, {rejectWithValue}) => {
        try {
            return await walletService.getLoginPoints(personId);
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getLoginPointsInfo = createAsyncThunk(
    'getLoginPointsInfo',
    async (_, {rejectWithValue}) => {
        try {
            return await walletService.getLoginPointsInfo();
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

const initialState = {
    isLoading: false,
    isLoadingDashboard: false,
    campaigns: [],
    transactions: [],
    transactionPageProperty: {},
    statusTypes: [],
    qrCode:{},
    walletInfo: {},
    merchants: [],
    loginPoints: {},
    loginPointsInfo: {},
    etaPointInfo: {},
};

export const walletSlice = createSlice({
    name: 'wallet',
    initialState,
    reducers: {
        setEtaPointInfo: (state, action) => {
            state.etaPointInfo = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(walletLogin.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(walletLogin.fulfilled, (state, action) => {
            state.isLoading = false;
            setToken({
                expiresIn: action?.payload?.data?.expires_in,
                accessToken: action?.payload?.data?.access_token,
                refreshToken: action?.payload?.data?.refresh_token
            }, 'Wallet');
        });
        builder.addCase(walletLogin.rejected, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(walletRefresh.fulfilled, (state, action) => {
            setToken({
                expiresIn: action?.payload?.data?.expires_in,
                accessToken: action?.payload?.data?.access_token
            }, 'Wallet');
        });
        builder.addCase(getCampaigns.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getCampaigns.fulfilled, (state, action) => {
            state.campaigns = action?.payload?.data?.data || [];
            state.isLoading = false;
        });
        builder.addCase(getCampaigns.rejected, (state, action) => {
            state.isLoading = false;
            state.campaigns = [];
        });
        builder.addCase(getTransactions.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getTransactions.fulfilled, (state, action) => {
            state.transactions = action?.payload?.data?.data || [];
            state.isLoading = false;
            state.transactionPageProperty = {
                totalCount: action?.payload?.data?.totalCount,
                limit: action?.payload?.data?.limit,
                next: action?.payload?.data?.next,
                prev: action?.payload?.data?.prev,
            };
        });
        builder.addCase(getTransactions.rejected, (state, action) => {
            state.isLoading = false;
            state.transactions = [];
            state.transactionPageProperty = {};
        });
        builder.addCase(getQrCode.fulfilled, (state, action) => {
            state.qrCode = action?.payload?.data?.data || {};
        });
        builder.addCase(getTransactionStatusTypes.fulfilled, (state, action) => {
            state.statusTypes = action?.payload?.data?.data || [];
        });
        builder.addCase(logoutWallet.fulfilled, (state) => {
            cookie.remove('accessTokenWallet', { path: '/' });
            cookie.remove('refreshTokenWallet', { path: '/' });
        });
        builder.addCase(getCustomerPersonWallet.pending, (state, action) => {
            state.isLoadingDashboard = true;
        });
        builder.addCase(getCustomerPersonWallet.fulfilled, (state, action) => {
            state.walletInfo = action?.payload?.data?.data || {};
            state.isLoadingDashboard = false;
        });
        builder.addCase(getCustomerPersonWallet.rejected, (state, action) => {
            state.isLoadingDashboard = false;
        });
        builder.addCase(getMerchants.fulfilled, (state, action) => {
            state.merchants = action?.payload?.data?.data || [];
        });
        builder.addCase(getLoginPoints.fulfilled, (state, action) => {
            state.loginPoints = action?.payload?.data?.data || {};
        });
        builder.addCase(getLoginPointsInfo.fulfilled, (state, action) => {
            state.loginPointsInfo = action?.payload?.data?.data || {};
        });
    }
});

export const { setEtaPointInfo } = walletSlice.actions;
  