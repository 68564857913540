import { Badge } from 'antd';

const anchors = [
    {
        key: 'company-profile',
        href: '#company-profile',
        title: 'Firma Profilim',
    },
    {
        key: 'billing-address',
        href: '#billing-address',
        title: 'Fatura Adresi',
    },
    {
        key: 'invoice-authorized-information',
        href: '#invoice-authorized-information',
        title: 'Finansal Yetkili Bilgilerim',
    }
];

const tabItems = (tabsCount) => [
    {
        key: 1,
        label: <Badge className={tabsCount?.tab1 > 0 && 'ant-badge-has-count'} count={tabsCount?.tab1}>Tedarik Sürecinde</Badge>,
        value: [2]
    },
    {
        key: 2,
        label: <Badge className={tabsCount?.tab2 > 0 && 'ant-badge-has-count'} count={tabsCount?.tab2}>Ödeme Bekleyen</Badge>,
        value: [1]
    },
    {
        key: 3,
        label: <Badge className={tabsCount?.tab3 > 0 && 'ant-badge-has-count'} count={tabsCount?.tab3}>Tedarik Edildi</Badge>,
        value: [3, 4, 5, 6, 7, 8, 9, 10, 11, 13, 14]
    },
    {
        key: 4,
        label: <span>İptal</span>,
        value: [95, 96, 97, 98, 99]
    }
];

const documentType = ['DeliveryChallan', 'GoodsReceiptDocument'];

const shownLastLocation = [
    '291cec8c-0511-4b8b-7065-08dbd15ad7f5',
    '9c7067c1-c91a-11e9-8ef1-005056a02e27',
    'd999f577-3bef-4d27-a78c-cb7b93b48590'
];

export {
    anchors,
    tabItems,
    documentType,
    shownLastLocation
};