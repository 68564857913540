import styled from 'styled-components';
import { mediaResponseMax } from 'styles/mixins';

const StyledForm = (ComponentName) => styled(ComponentName)`
    width: 100%;
    .ant-form-item-submit {
        display: flex;
        justify-content: flex-end;
        margin-bottom: unset !important;

        ${mediaResponseMax.xs`
            display: block;
        `};
    }
    .checkbox-item {
        margin-bottom: 10px !important;
    }
    .button-item {
        margin-bottom: unset !important;
        margin-top: 18px;
    }
    .ant-form-item-label{
        padding-bottom: 4px;

        label{
            color: #4c6278;
            font-weight: 600;

            &.ant-form-item-required{
                flex-direction: row-reverse;

                :before{
                    margin: -4px 4px 0;
                }
            }
        }
    }

  .ant-picker-cell-disabled {
    pointer-events: unset !important;
  }
`;

export {
    StyledForm
};