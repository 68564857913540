import styled from 'styled-components';
import { mediaResponseMax } from 'styles/mixins';


const StyledTree = (ComponentName) => styled(ComponentName)`
  background: transparent;
  
  .ant-tree-treenode{
    padding-left: 4px;
  }

  .ant-tree-checkbox {
    margin-inline-end: 4px !important;
    .ant-tree-checkbox-inner {
      &:hover {
        border-color: #1890FF !important;
      }
    }
  }
  
  .ant-tree-checkbox-checked {
    .ant-tree-checkbox-inner {
      background-color: #1890FF !important;
      border-color: #1890FF !important;
    }
  }

  .ant-tree-indent,
  .ant-tree-switcher,
  .ant-tree-draggable-icon {
      display: none;
  }

  .tree-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .tree-order-box {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #F1F5F9;
      width: 16px !important;
      height: 16px !important;
      border-radius: 5px;
      border: 1px solid #D9D9D9;
      color: #64748B;
      font-weight: 600;
      font-size: 11px;
      margin-right: 8px;
      margin-top: 3px;
    }
    .tree-title {
      color: #4C6278;
      font-size: 14px;
      font-weight: 400;
      white-space: nowrap;
    }
  }

  &.ant-tree-invoicing-choices{
    .ant-tree-list-holder-inner {
      display: block !important;
      column-count: 2;
      ${mediaResponseMax.sm`
        column-count: 1;
        `};
    }
  }
`;

export {
  StyledTree
};