import { useEffect, useState } from 'react';
import { Menu } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toggleCollapsed } from 'store/slice/app.slice';
import { SiderWrapper } from './menu.styles';
import { menuList } from './menu.list';
import { useAuth } from 'hooks/auth/use-auth';
import GA, { eventTypes } from 'tools/analytics';

const SiderMenu = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const { onLogout } = useAuth();

    const [openKeys, setOpenKeys] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState(null);

    const { view, collapsed, company } = useSelector(state => state.app);
    const { isAuth } = useSelector(state => state.auth);
    const { intro } = useSelector(state => state.person);

    useEffect(() => {
        isAuth && getDefaultPath();
    }, [isAuth]);

    const getDefaultPath = () => {
        const items = menuList(company)?.flatMap(element => [
            element,
            ...element.children.flatMap(element => [
                element,
                ...element.children.map(m => ({
                    key: m.key,
                    label: m.label,
                    path: m.path,
                    openKeys: [element.key]
                }))
            ]),
        ]);

        const currentItem = items.filter(f => f.path === location.pathname);
        if (currentItem.length > 0) {
            setOpenKeys(currentItem[0].openKeys || []);
            setSelectedKeys(currentItem[0].key);
        }
    };

    const handleClick = (e) => {
        if (e.key === 'logout') return onLogout();
        setSelectedKeys(e.key);
        if (view !== 'DesktopView') {
            setTimeout(() => {
                dispatch(toggleCollapsed());
            }, 100);
        }
    };

    const onClickMenuItem = (item) => {
        GA.event(eventTypes.CLICK, 'Ana Menü', 'Sayfaya Git', item.label, item.path);
        return navigate(item.path);
    };

    const onOpenChange = (keys) => {
        const rootSubmenuKeys = ['account'];
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };

    const getItem = (label, key, icon, children, type, onClick, disabled) => {
        return {
            key,
            icon,
            children,
            label,
            type,
            disabled: disabled,
            onClick
        };
    };

    const items = company?.value ? [
        ...menuList(company, intro).map(group => (
            getItem(
                group.label,
                group.key,
                null,
                [...group.children.map(menu => (
                    menu?.children?.length > 0
                        ? getItem(menu.label, menu.key, menu.icon, [
                            ...menu.children.map(sub => getItem(sub.label, sub.key, null, null, null, () => onClickMenuItem(sub), sub.disabled))
                            ], null, null, menu.disabled) 
                        : getItem(menu.label, menu.key, menu.icon, null, null, () => onClickMenuItem(menu), menu.disabled)
                ))],
                'group'
            )
        ))
    ] : [];

    return (
        <SiderWrapper
            collapsible
            collapsed={collapsed}
            onCollapse={() => dispatch(toggleCollapsed())}
            width={view === 'MobileView' ? '100%' : 295}
        >
            <Menu
                mode="inline"
                theme="dark"
                items={items}
                selectedKeys={[selectedKeys]}
                openKeys={openKeys}
                onClick={handleClick}
                onOpenChange={onOpenChange}
            />
        </SiderWrapper>
    );
};

export default SiderMenu;