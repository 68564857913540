import styled from 'styled-components';

const MessageWrapper = styled.div`
    position: fixed;
    display: flex;
    align-items: center;
    right: 28px;
    bottom: 30px;
    z-index: 3;
`;

const MessageButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-color: #7CD070;
    box-shadow: 0px 0px 10px 0px #00000040;
    margin-right: 14px;

    .anticon {
        color: #fff;
        font-size: 30px
    }
`;

export {
    MessageWrapper,
    MessageButton
};