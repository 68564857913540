import dataApi from '../apis/data.api';

const getPaymentTypes = async (isCustomerPayment, isSupplierPayment) => {
  return dataApi.get(`payments/types/${isCustomerPayment}/customer-types/${isSupplierPayment}/supplier-types`);
};

const paymentService = {
  getPaymentTypes,
};

export default paymentService;
