import { configureStore } from '@reduxjs/toolkit';
import { loadState, saveState } from './persisted-state';
import rootReducer from './rootReducer';

const preloadedState = loadState();

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  preloadedState: preloadedState
});

store.subscribe(() => saveState({
  app: store.getState().app,
  auth: store.getState().auth,
  person: store.getState().person
}));

export default store;