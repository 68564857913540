import dataApi from '../apis/data.api';

const postVehicles = async (data) => {
  return dataApi.post('vehicles', data);
};

const putVehicles = async (vehicleId, data) => {
  return dataApi.put(`vehicles/${vehicleId}`, data);
};

const getWithVehicleId = async (vehicleId) => {
  return dataApi.get(`vehicles/me/${vehicleId}`);
};

const getStatuses = async () => {
  return dataApi.get('vehicles/statuses');
};

const getTypes = async () => {
  return dataApi.get('vehicles/types');
};

const getTypesWithKey = async (key) => {
  return dataApi.get(`vehicles/types/${key}`);
};

const getDetailedTypes = async (params) => {
  return dataApi.get('vehicles/detailed-types', { params });
};

const getVehicleBrands = async () => {
  return dataApi.get('vehicles/brands');
};

const getVehicleLastLocation = async (plateNumber) => {
  return dataApi.get(`vehicles/last-location/${plateNumber}`);
};

const vehicleService = {
  postVehicles,
  putVehicles,
  getWithVehicleId,
  getStatuses,
  getTypes,
  getTypesWithKey,
  getDetailedTypes,
  getVehicleBrands,
  getVehicleLastLocation
};

export default vehicleService;