const PersonType = {							
    /** Tedarikçi */
    Supplier: 1, 
    /** Personel */
    Internal: 5, 
    /** Müşteri */
    Customer: 8, 
    /** Müşteri ve Tedarikçi */
    CustomerAndSupplier: 9, 
};

export default PersonType;