const eventTypes = {
    CLICK: 'custom_click',
    PAGE_VIEW: 'custom_page_view',
    PAGE_REQUEST: 'custom_page_request',
    FORM_SUBMIT: 'custom_form_submit',
};

const event = (type, category, action, label = null, value = null) => {
    gtag('event', type, {
        app: 'Kokpit Web',
        category: category,
        action: action,
        label: label,
        value: value
    });
};

const pageview = (pathname) => {
    gtag('event', eventTypes.PAGE_VIEW, {
        app: 'Kokpit Web',
        location: pathname
    });
};

const GA = {
    event,
    pageview
};

export { eventTypes };

export default GA;