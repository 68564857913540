import styled from 'styled-components';

const StyledTag = (ComponentName) => styled(ComponentName)`
    font-size: 11px;
    line-height: 22px;
    padding: 0 8px;

    &.ant-tag-xl{
        font-size: 12px;
        font-weight: 500;
        line-height: 30px;
        padding: 0 16px;
    }

    &.quote-status{

        &.status-1,
        &.status-2,
        &.status-8,
        &.status-11{
            color: #d46b08;
            background: #fff7e6;
            border-color: #ffd591;
        }

      &.status-13 {
        color: #d46b08;
        background: #fff7e6;
        border-color: #ffd591;
      }

      &.status-10,
        &.status-5,
        &.status-4{
            color: #cf1322;
            background: #fff1f0;
            border-color: #ffa39e;
        }

        &.status-3{
            color: #0958d9;
            background: #e6f4ff;
            border-color: #91caff;
        }

        &.status-9{
            color: #389e0d;
            background: #f6ffed;
            border-color: #b7eb8f;
        }
    }
    &.auction-bid-status{

        &.status-3,
        &.status-4{
            color: #d46b08;
            background: #fff7e6;
            border-color: #ffd591;
        }

        &.status-1,
        &.status-6,
        &.status-7,
        &.status-97,
        &.status-98{
            color: #cf1322;
            background: #fff1f0;
            border-color: #ffa39e;
        }

        &.status-2{
            color: #0958d9;
            background: #e6f4ff;
            border-color: #91caff;
        }

        &.status-5,
        &.status-8{
            color: #389e0d;
            background: #f6ffed;
            border-color: #b7eb8f;
        }
        &.status-FD4056{
            color: #389e0d;
            background: #f6ffed;
            border-color: #b7eb8f;
        }
        &.status-FFC529{
            color: #0958d9;
            background: #e6f4ff;
            border-color: #91caff;
        }
        &.status-45E566{
            color: #cf1322;
            background: #fff1f0;
            border-color: #ffa39e;
        }
    }

    &.shipment-status{

        &.status-1,
        &.status-2{
            color: #d46b08;
            background: #fff7e6;
            border-color: #ffd591;
        }

        &.status-3,
        &.status-4,
        &.status-5,
        &.status-6,
        &.status-7,
        &.status-8,
        &.status-9,
        &.status-13,
        &.status-14{
            color: #0958d9;
            background: #e6f4ff;
            border-color: #91caff;
        }

        &.status-10,
        &.status-11{
            color: #389e0d;
            background: #f6ffed;
            border-color: #b7eb8f;

        }

        &.status-95,
        &.status-96,
        &.status-97,
        &.status-98,
        &.status-99{
            color: #cf1322;
            background: #fff1f0;
            border-color: #ffa39e;
        }
    }
`;

export {
    StyledTag
};