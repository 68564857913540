import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Cookies } from 'react-cookie';
import authService from 'core/services/auth.service';
import { setToken } from 'core/utils/helpers/converter';
import GA, { eventTypes } from 'tools/analytics';

let cookie = new Cookies();

export const login = createAsyncThunk(
  'login',
  async (data, { rejectWithValue }) => {
    try {
      localStorage.setItem('walletId', data?.password);
      return await authService.login(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const refresh = createAsyncThunk(
  'refresh',
  async (_, { rejectWithValue }) => {
    try {
      let token = cookie.get('refreshToken', { path: '/' });
      return await authService.refreshToken(token);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const postResetPassword = createAsyncThunk(
  'postResetPassword',
  async (phoneNumber, { rejectWithValue }) => {
    try {
      return await authService.postResetPassword(phoneNumber);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const putResetPassword = createAsyncThunk(
  'putResetPassword',
  async (data, { rejectWithValue }) => {
    try {
      return await authService.putResetPassword(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const logout = createAsyncThunk(    
  'logout',
  async (callback = () => {}, { rejectWithValue }) => {
    try {
      return callback();
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const initialState = {
  isLoading: false,
  isAuth: false
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
   
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAuth = true;
      setToken({
        expiresIn: action?.payload?.data?.expiresIn,
        accessToken: action?.payload?.data?.accessToken,
        refreshToken: action?.payload?.data?.refreshToken
      });
      GA.event(eventTypes.PAGE_REQUEST, 'Giriş Yap', 'Başarılı');
    });
    builder.addCase(login.rejected, (state, action) => {
      state.isLoading = false;
      GA.event(eventTypes.PAGE_REQUEST, 'Giriş Yap', 'Başarısız');
    });
    builder.addCase(refresh.fulfilled, (state, action) => {
      state.isAuth = true;
      setToken({
        expiresIn: action?.payload?.data?.expiresIn,
        accessToken: action?.payload?.data?.accessToken,
      });
    });
    builder.addCase(postResetPassword.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(postResetPassword.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(postResetPassword.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(putResetPassword.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(putResetPassword.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(putResetPassword.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.isAuth = false;
      cookie.remove('accessToken', { path: '/' });
      cookie.remove('refreshToken', { path: '/' });
      cookie.remove('accessTokenWallet', { path: '/' });
      cookie.remove('refreshTokenWallet', { path: '/' });
      localStorage.removeItem('walletId');
    });
  }
});

