import styled from 'styled-components';
import { mediaResponseMax } from 'styles/mixins';

const StyledCollapse = (ComponentName) => styled(ComponentName)`
    &.ant-collapse-address {
        border: unset;
        background-color: unset;
        
        .ant-collapse-item {
            border: unset;
            &:not(:last-child) {
                margin-bottom: 8px;
            }
        }
        .ant-collapse-expand-icon {
            padding-inline: unset !important
        }
        .ant-collapse-item-active {
            & > .ant-collapse-header {
                background-color: #1890FF;
                border-end-start-radius: unset !important;
                border-end-end-radius: unset !important;
            }
            .ant-collapse-expand-icon {
                transform: rotate(180deg);
            }
        }
        .ant-collapse-header {
            padding: 17px 16px;
            background-color: #4C6278;
            border-radius: 8px !important;

            ${mediaResponseMax.xs`
                display: flex;
                align-items: center;                
            `};
        }
        .ant-collapse-header-text {
            font-size: 14px;
            font-weight: 600;
            color: #fff;

            ${mediaResponseMax.xs`
                font-size: 14px;
                white-space: pre-line;
            `};
        }
        .ant-collapse-content {
            border: 1px solid #F3F3F3;
            border-top: unset;
            border-end-start-radius: 8px;
            border-end-end-radius: 8px;
            .ant-collapse-content-box {
                padding: 28px 20px 28px;
            }
        }
    }
    &.ant-collapse-vehicle{
        overflow: hidden;
        background-color: unset;
        border-color: #F3F3F3;

        .ant-collapse-item-disabled > .ant-collapse-header {
            cursor: default;
        }
        .ant-collapse-vehicle-form {
            .ant-collapse-header {
                display: none;
            }
            .ant-collapse-content {
                overflow: hidden;
                border-top: unset;
                .ant-collapse-content-box {
                    background-color: #F8F9FA;
                }
            }
        }
        .ant-collapse-item{
            background-color: #fff;
            border-color: #F3F3F3;

            &:not(:last-child){
                border-bottom-width: 2px;
            }
        }
        .ant-collapse-content {
            background-color: #fff;
            border-color: #fff;

            .ant-collapse-content-box{
                padding-top: 0;
            }
        }
        .ant-collapse-header {
            display: flex;
            align-items: center;

            .ant-collapse-header-text {
                font-size: 14px;
                font-weight: 500;
                color: #4C6278;
                
                .selected {
                    display: none;
                    color: #4C6278;
                    margin-left: 5px;
                }
            }
        }
        .ant-collapse-item-active {
            .ant-collapse-header-text {
                color: #F46424;
                .selected {
                    display: inline;
                }
            }
        }
        
    }
    &.ant-collapse-order-complate {
        overflow: inherit;
        margin-bottom: 40px;

        .ant-collapse-item {
            border: 1px solid #DBE0E4;
            border-radius: 8px;
        }
        .ant-collapse-item-active {
            & > .ant-collapse-header {
                background-color: #fff;
                border-end-start-radius: unset !important;
                border-end-end-radius: unset !important;
                padding: 8px 20px 4px 28px;
            }
            .ant-collapse-expand-icon {
                transform: rotate(180deg);
            }
        }
        .ant-collapse-header {
            display: flex;
            align-items: center;
            background-color: #fff;
            overflow: hidden;
            padding: 8px 20px 8px 28px;
        }
        .ant-collapse-header-text {
            display: flex;
            flex-direction: column;
            color: #4C6278;

            .ant-collapse-header-title {
                font-size: 12px;
                font-weight: 700;
                color: #4C6278;
            }
            .ant-collapse-header-subtitle {
                font-size: 16px;
                font-weight: 600;
                color: #1890FF;
            }
        }
        .ant-collapse-expand-icon > .anticon {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #F3F3F3;
            border-radius: 50%;
            width: 28px;
            height: 28px;
        }
        .ant-collapse-content {
            border: unset;
            .ant-collapse-content-box {
                padding: 0 28px 10px;
            }
        }
    }
    &.ant-collapse-your-curiosities {
        overflow: inherit;

        .ant-collapse-item {
            border: 1px solid #DBE0E4;
            border-radius: 8px;
        }
        .ant-collapse-item-active {
            & > .ant-collapse-header {
                background-color: #fff;
                border-end-start-radius: unset !important;
                border-end-end-radius: unset !important;
                padding: 16px 20px 16px 28px;
            }
            .ant-collapse-expand-icon {
                transform: rotate(180deg);
            }
        }
        .ant-collapse-header-text {
            font-size: 14px;
            font-weight: 500;
            color: #000;
        }
        .ant-collapse-header {
            display: flex;
            align-items: center;
            background-color: #fff;
            overflow: hidden;
            padding: 16px 20px 16px 28px;
        }
        .ant-collapse-expand-icon > .anticon {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #F3F3F3;
            border-radius: 50%;
            width: 28px;
            height: 28px;
        }
        .ant-collapse-content {
            border: unset;
            .ant-collapse-content-box {
                padding: 0 20px 20px;
            }
        }
    }
    &.ant-collapse-draft {
        overflow: inherit;
        background-color: unset; 

        .ant-collapse-item {
            overflow: hidden;
            border: 1px solid transparent;

            .ant-collapse-header {
                background-color: #F8F9FA;
                padding: 10px 16px;

                .ant-collapse-header-text {
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 20px;
                    color: #232328;

                    .anticon {
                        margin-left: 8px;
                        color: #7CD070;
                        font-size: 16px;
                    }
                }
                .ant-collapse-extra {
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 20px;
                    color: #94A3B8;
                    margin-right: 15px
                }
                .ant-collapse-expand-icon > .anticon {
                    color: #4C6278;
                    font-size: 12px
                }
            }
        }
        .ant-collapse-item-active {
            border-radius: 4px;
            border: 1px solid #1890FF;

            .ant-collapse-header {
                border-radius: unset !important;
            }
        }
        .ant-collapse-content {
            border: unset;
            border-radius: 0px;
            .ant-collapse-content-box {
                padding: 20px 16px;

                ${mediaResponseMax.sm`
                    padding: 20px 10px;
                `};
            }
        }
    }
`;

export {
    StyledCollapse,
};