import styled from 'styled-components';

const StyledCheckbox = (ComponentName) => styled(ComponentName)`
    &.ant-checkbox-wrapper {
        /* display: flex;
        margin: unset;
        align-items: baseline; */
        
        & > .ant-checkbox + span {
            font-size: 12px;
            font-weight: 500;
            color: #4C6278;

            & > span {
                font-size: 12px;
                font-weight: 400;
                color: #F46424;
                text-decoration: underline;
            }
        }
        &:hover > .ant-checkbox > .ant-checkbox-inner {
            border-radius: 4px;
        }
        .ant-checkbox {
            width: 16px;
            height: 16px;
            &::after {
                border-radius: 4px;
            }
        }
    }
    &.ant-checkbox-inner {
        border: 2px solid #E1E1E1;
        border-radius: 4px;
        &::after {
            top: 45%;
            left: 20%;
            height: 11px;
        }
    }
    &.ant-checkbox-checked .ant-checkbox-inner {
        border: unset;
    }
    &.ant-checkbox-group {

        .ant-checkbox + span {
            display: flex;
            align-items: center;
            font-size: 12px;
            font-weight: 500;
            color: #4C6278;
            padding-inline-start: 4px
        }
    }
    &.ant-checkbox-primary {
        .ant-checkbox-wrapper-checked {
            &:hover {
                .ant-checkbox-inner {
                    background-color: #1890FF !important;
                }
                .ant-checkbox-checked::after {
                    content: unset;
                }
            }
            .ant-checkbox-inner {
                border-color: #1890FF;
                background-color: #1890FF;
            }
        }
        .ant-checkbox-wrapper:hover {
            .ant-checkbox-inner {
                border-color: #1890FF;
            }
        }
    }
    &.ant-vertical-group {
        display: flex;
        flex-direction: column;

        .ant-checkbox-wrapper {

            &:not(:last-child) {
                margin-bottom: 11px;
            }
        }
    }
`;

export {
    StyledCheckbox
};
