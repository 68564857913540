import React, { createContext, useContext, useState } from 'react';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { Cookies } from 'react-cookie';

const HubsContext = createContext();

const HubsProvider = ({ children }) => {
    const cookie = new Cookies();

    const [hubConnections, setHubConnections] = useState({});

    const hubStart = (hubName) => {
        if (!hubConnections[hubName]) {
            const builder = new HubConnectionBuilder()
                .withUrl(`${process.env.REACT_APP_HUBS_URL}/${hubName}`, {
                    accessTokenFactory: () => cookie.get('accessToken')
                })
                .withAutomaticReconnect()
                .build();
    
            builder.start()
                .then(() => {
                    console.log(`Connected to ${hubName} hub`);
                })
                .catch(err => {
                    console.error(`Error connecting to ${hubName} hub: ${err}`);
                });
    
            setHubConnections(prevState => ({
                ...prevState,
                [hubName]: builder,
            }));
        }
    };
  
    const hubConnection = (hubName) => {
        return hubConnections[hubName];
    };
  
    const hubDispose = (hubName) => {
        const connection = hubConnections[hubName];
        if (connection) {
            connection.stop();

            setHubConnections(prevState => {
                const updatedConnections = { ...prevState };
                delete updatedConnections[hubName];
                return updatedConnections;
            });
        }
    };
  
    return (
      <HubsContext.Provider value={{ hubStart, hubConnection, hubDispose }}>
          {children}
      </HubsContext.Provider>
    );
};

const useHubs = () => {
    return useContext(HubsContext);
};

export { useHubs };

export default HubsProvider;
