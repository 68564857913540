import { ShoppingCartOutlined, UserOutlined, DownOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Avatar, Popover, Badge } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCart } from 'store/slice/cart.slice';
import { getNotificationCount } from 'store/slice/person.slice';
import React, { useEffect, memo, useState } from 'react';
import { EtaButton, EtaDrawer, EtaImage } from 'components/ui';
import {
    HeaderActionsWrapper,
    PopoverHeaderWrapper,
} from './header.styles';
import { useAuth } from 'hooks/auth/use-auth';
import HeaderNotification from './header-notification';
import RegisterAsCompany from 'components/partials/auth/as-company';
import { modals } from 'core/utils/common/modals';
import { hasAnyRole } from 'core/utils/helpers/converter';
import { RoutePaths } from 'router/paths';
import GA, { eventTypes } from 'tools/analytics';
import PersonType from 'core/enums/person-type';
import AccountType from 'core/enums/account-type';

const HeaderActions = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { onSelectCompany, onJoinDemands, onLogout } = useAuth();

    const [openAsCompany, setOpenAsCompany] = useState(false);

    const { cart, cartCount } = useSelector(state => state.cart);
    const { intro } = useSelector(state => state.person);
    const { view, company } = useSelector(state => state.app);

    useEffect(() => {
        if (!Object.keys(cart).length) dispatch(getCart());
    }, []);

    const setSelectCompany = (personType) => {
        onSelectCompany(personType, true);
        dispatch(getNotificationCount({ personTypes: [personType], isRead: false }));
        console.log(personType == PersonType.Supplier ? 'Supplier' : 'Customer');
        GA.event(eventTypes.CLICK, 'Üst Menü', `${personType == PersonType.Supplier ? 'Tedarikçi' : 'Müşteri'} Hesabına Geçiş Yap`);
    };

    const setCreateCompany = (personType) => {
        setOpenAsCompany(true);
        console.log(personType == PersonType.Supplier ? 'Supplier' : 'Customer');
        GA.event(eventTypes.CLICK, 'Üst Menü', `${personType == PersonType.Supplier ? 'Tedarikçi' : 'Müşteri'} Hesabı Oluştur`);
    };

    const setJoinDemands = (taxNumber, accountType) => {
        const toCompany = accountType === AccountType.Customer ? 'Müşteri' : 'Tedarikçi';
        modals.confirm(
            'Firmaya Katılım Talebi',
            `${taxNumber} numaralı VKN ile bir firma kaydı var. ${toCompany} olarak kayıt olmak ister misiniz?`,
            {
                icon: <InfoCircleOutlined />,
                handleOk: async () => {
                    await onJoinDemands(taxNumber, accountType);
                },
            }
        );
        GA.event(eventTypes.CLICK, 'Üst Menü', `${toCompany} Hesabına Katıl`);
    };

    const content = (
        <div className="profile-content">
            <div className="profile-body">
                {
                    intro?.accountIntro
                        ? <>
                            <h5 className="popover-title">{company.key === PersonType.Customer ? 'Yükün var, araç mı arıyorsun?' : 'Aracın var, yük mü arıyorsun?'}</h5>
                            {
                                intro?.accountIntro?.isCustomer && [PersonType.Customer, PersonType.CustomerAndSupplier].includes(intro?.personType)
                                    ? company.key !== PersonType.Customer && <EtaButton type="warning-outline" size="xsmall" onClick={() => setSelectCompany(PersonType.Customer)}>Müşteri Hesabına Geçiş Yap</EtaButton>
                                    : intro?.accountIntro?.isSupplier && hasAnyRole(['SupplierAdmin'])
                                        ? <EtaButton type="warning-outline" size="xsmall" onClick={() => setCreateCompany(8)}>Müşteri Hesabı Oluştur</EtaButton>
                                        : <EtaButton type="warning-outline" size="xsmall" onClick={() => setJoinDemands(intro?.accountIntro?.taxNumber, 1)}>Müşteri Hesabına Katıl</EtaButton>
                            }
                            {
                                intro?.accountIntro?.isSupplier && [PersonType.Supplier, PersonType.CustomerAndSupplier].includes(intro?.personType)
                                    ? company.key !== PersonType.Supplier && <EtaButton type="warning-outline" size="xsmall" onClick={() => setSelectCompany(PersonType.Supplier)}>Tedarikçi Hesabına Geçiş Yap</EtaButton>
                                    : intro?.accountIntro?.isCustomer && hasAnyRole(['CustomerAdmin'])
                                        ? <EtaButton type="warning-outline" size="xsmall" onClick={() => setCreateCompany(1)}>Tedarikçi Hesabı Oluştur</EtaButton>
                                        : <EtaButton type="warning-outline" size="xsmall" onClick={() => setJoinDemands(intro?.accountIntro?.taxNumber, 2)}>Tedarikçi Hesabına Katıl</EtaButton>
                            }
                        </>
                        : <>
                            <h5 className="popover-title">Henüz firma hesabınız yok mu?</h5>
                            <EtaButton type="warning-outline" size="xsmall" onClick={() => { 
                                GA.event(eventTypes.CLICK, 'Üst Menü', 'Firmaya Kayıt Ol');
                                return navigate(RoutePaths.ACCOUNT.REGISTER_TO_COMPANY);
                            }}>Firmaya Kayıt Ol</EtaButton>
                        </>
                }
            </div>
            <div className="profile-footer">
                <a onClick={() => onLogout()}>
                    <EtaImage source="sign-out.svg" width='32px' height='20px' /> Çıkış
                </a>
            </div>
        </div>
    );

    return (
        <HeaderActionsWrapper>
            {company.key === PersonType.Customer &&
                <Badge className='header-action-cart' count={cartCount} color="#ed502d">
                    <ShoppingCartOutlined onClick={() => {
                        GA.event(eventTypes.CLICK, 'Üst Menü', 'Sepete Git');
                        return navigate(RoutePaths.CUSTOMER.CART);
                    }} />
                </Badge>
            }
            <HeaderNotification />
            <Popover
                className='header-action-user'
                overlayClassName="profile-popover"
                content={content}
                showArrow={true}
                placement="bottomRight"
                destroyTooltipOnHide
            >
                <PopoverHeaderWrapper>
                    <>

                        {
                            view === 'MobileView' ?
                                <UserOutlined />
                                :
                                <>
                                    <Avatar
                                        size="large"
                                        icon={<UserOutlined style={{ fontSize: 20 }} />}
                                    />
                                    <span className='user-text'>
                                        <label>{`${intro?.name} ${intro?.surname}`}</label>
                                        <small>
                                            <EtaImage source={company.key === PersonType.Customer ? 'package.svg' : 'truck.svg'} width='20px' height='15px' />
                                            {`${company.key === PersonType.Customer ? 'YÜK SAHİBİ' : 'ARAÇ SAHİBİ'}`}
                                        </small>
                                    </span>
                                    <DownOutlined />
                                </>

                        }
                    </>
                </PopoverHeaderWrapper>
            </Popover>
            <EtaDrawer
                title={`${intro?.accountIntro?.isCustomer ? 'Tedarikçi' : 'Müşteri'} olarak kayıt olmak ister misiniz?`}
                width={view === 'MobileView' ? '100%' : 450}
                open={openAsCompany}
                maskClosable={false}
                onClose={() => setOpenAsCompany(false)}
                zIndex={1040}
                destroyOnClose
            >
                <RegisterAsCompany accountType={intro?.accountIntro?.isCustomer ? PersonType.Customer : PersonType.Supplier} onCancel={() => setOpenAsCompany(false)} />
            </EtaDrawer>
        </HeaderActionsWrapper>
    );
};

export default memo(HeaderActions);