import React from 'react';
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    CloseOutlined,
    InfoCircleOutlined
} from '@ant-design/icons';
import {
    NotificationWrapper,
    NotificationCloseIcon,
    NotificationIcon,
    NotificationContent,
    NotificationTitle,
    NotificationDescription
} from './notification.styles';

const icons = [
    {
        type: 'error',
        icon: <CloseCircleOutlined style={{ color: '#FF4D4F', fontSize: '20px' }} />,
        color: '#fff2f0'
    },
    {
        type: 'warning',
        icon: <InfoCircleOutlined style={{ color: '#faad14', fontSize: '20px', transform: 'rotate(180deg)' }} />,
        color: '#fffbe6'
    },
    {
        type: 'info',
        icon: <InfoCircleOutlined style={{ color: '#1677ff', fontSize: '20px' }} />,
        color: '#e6f4ff'
    },
    {
        type: 'success',
        icon: <CheckCircleOutlined style={{ color: '#52c41a', fontSize: '20px' }} />,
        color: '#f6ffed'
    }
];
const Notification = (props) => {
    const { type, title, description, closeIcon, onClose } = props;
    const { icon, color } = icons.find(x => x.type === type);

    return (
        <NotificationWrapper>
            {closeIcon &&
                <NotificationCloseIcon onClick={onClose}>
                    <CloseOutlined />
                </NotificationCloseIcon>
            }
            <NotificationIcon color={color}>{icon}</NotificationIcon>
            <NotificationContent>
                <NotificationTitle>{title}</NotificationTitle>
                <NotificationDescription>{description}</NotificationDescription>
            </NotificationContent>
        </NotificationWrapper>
    );
};

export default Notification;