import styled from 'styled-components';
import { boxShadow } from 'core/utils/helpers/style_untils';
import { mediaResponseMax } from 'styles/mixins';

const NotificationWrapper = styled.div`
    min-width: 336px;
    height: auto;
    display: flex;
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    margin-bottom: 24px;
    ${boxShadow('0px 0px 4px 0px #00000014')};
    ${mediaResponseMax.sm`
        width: 100%;
    `};
`;
const NotificationCloseIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    top: 12px;
    right: 12px;
    .anticon {
        color: #4C6278;
    }
`;
const NotificationIcon = styled.div`
    display: flex;
    padding: 0px 6px;
    background-color: ${(props) => props.color};
`;
const NotificationContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: 12px 30px 15px 12px;
    height: 100%;
`;
const NotificationTitle = styled.strong`
    display: block;
    font-size: 14px;
    font-weight: 700;
    color: #000;
    margin: unset;
    margin-bottom: 4px;
`;
const NotificationDescription = styled.span`
    font-size: 13px;
    font-weight: 500;
    color: #4C6278;
`;

export {
    NotificationWrapper,
    NotificationCloseIcon,
    NotificationIcon,
    NotificationContent,
    NotificationTitle,
    NotificationDescription
};