import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, Col, Popover, Row } from 'antd';
import { NotificationContent, PopoverHeaderWrapper } from './header.styles';
import { BellOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { EtaButton } from 'components/ui';
import dayjs from 'dayjs';
import { getHeaderNotifications, getNotificationCount, putNotificationRead } from 'store/slice/person.slice';
import EtaList from 'components/ui/list';
import { RoutePaths } from 'router/paths';
import GA, { eventTypes } from 'tools/analytics';

const HeaderNotification = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { headerNotifications, notificationCount, isLoading } = useSelector(state => state.person);
    const { company } = useSelector(state => state.app);
    const [clickedItemId, setClickedItemId] = useState(null);
    const [readItems, setReadItems] = useState([]);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        dispatch(getNotificationCount({ personTypes: [company?.key], isRead: false }));
    }, []);

    const readNotification = async (notificationId) => {
        dispatch(putNotificationRead(notificationId));
    };

    const notificationItemClick = (notificationId) => {
        notificationId === clickedItemId ? setClickedItemId(null) : setClickedItemId(notificationId);
    };

    const onOpenChangeNotification = (e) => {
        if (e && !open) {
            dispatch(getHeaderNotifications({ personTypes: [company?.key], limit: 5 }));
        } else {
            setReadItems([]);
            setClickedItemId([]);
            dispatch(getNotificationCount({ personTypes: [company?.key], isRead: false }));
        }
        
        GA.event(eventTypes.CLICK, 'Üst Menü', e ? 'Bildirimleri Aç' : 'Bildirimleri Kapat');
        return setOpen(e);
    };

    const notificationContent = (
        <NotificationContent >
            <div className='notification-header'>Bildirimler</div>
            <div className='notification-body'>
                <EtaList
                    itemLayout="vertical"
                    dataSource={headerNotifications}
                    loading={isLoading}
                    renderItem={(item) => (
                        <EtaList.Item
                            key={item.title}
                            className={item?.isRead ? 'is-read' : readItems.includes(item.id) ? 'is-read' : null}
                            onClick={() => {
                                notificationItemClick(item.id);
                                setReadItems(prev => [...prev, item.id]);
                                !item.isRead && readNotification(item.id);
                            }}
                        >
                            <Row justify="space-between" align="top">
                                <Col span={23}>
                                    <div className='item-title'>{item?.content.title}</div>
                                    {clickedItemId === item.id &&
                                        <div className='item-detail' dangerouslySetInnerHTML={{ __html: item?.content?.body }} />
                                    }
                                    <div className='item-date'>{dayjs(item.time).format('DD.MM.YYYY HH:mm')}</div>
                                </Col>
                                <Col span={1}>
                                    <Badge status={item?.isRead ? 'default' : readItems.includes(item.id) ? 'default' : 'success'} key={item?.id} />
                                </Col>
                            </Row>
                        </EtaList.Item>
                    )}
                />
            </div>
            <div className='notification-footer'>
                <EtaButton
                    block
                    size="large"
                    type="primary"
                    onClick={() => {
                        setOpen(false);
                        GA.event(eventTypes.CLICK, 'Üst Menü', 'Tüm Bildirimler');
                        return navigate(RoutePaths.NOTIFICATIONS, { state: { tab: '1' } });
                    }}
                >Tümünü Gör</EtaButton>
            </div>
        </NotificationContent>
    );

    return (
        <Popover
            showArrow={true}
            placement="bottom"
            overlayClassName="notification-popover"
            content={notificationContent}
            open={open}
            trigger="click"
            onOpenChange={onOpenChangeNotification}
            destroyTooltipOnHide
        >
            <PopoverHeaderWrapper>
                <Badge count={notificationCount} color="#ed502d">
                    <BellOutlined className='header-action-notification' />
                </Badge>
            </PopoverHeaderWrapper>
        </Popover >
    );
};

export default HeaderNotification;