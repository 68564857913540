import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import invoicesService from 'core/services/invoice.service';

export const getInvoicesTypes = createAsyncThunk(
    'getInvoicesTypes',
    async (_, {rejectWithValue}) => {
        try {
            return await invoicesService.getInvoicesTypes();
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

const initialState = {
    invoicesTypes: [],
    isFetching: false,
};

export const invoicesSlice = createSlice({
    name: 'invoices',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getInvoicesTypes.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(getInvoicesTypes.fulfilled, (state, action) => {
            state.invoicesTypes = action?.payload?.data?.data || [];
            state.isFetching = false;
        });
        builder.addCase(getInvoicesTypes.rejected, (state) => {
            state.invoicesTypes = [];
            state.isFetching = false;
        });
    }
});


