import styled from 'styled-components';
import { Drawer } from 'antd';
import { mediaResponseMax } from 'styles/mixins';

const DrawerWrapper = styled(Drawer)`
    .ant-drawer-header {
        position: relative;
        border: unset;
        padding: 40px 60px 0px;
        ${mediaResponseMax.xs`
            padding: 30px 20px 0px;
        `};

        .ant-drawer-header-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row-reverse;

            .ant-drawer-title {
                font-size: 20px;
                font-weight: 600;
                color: #000;
            }
            .ant-drawer-close {
                margin: unset;
                color: #000;
                position: absolute;
                right: 25px;
                top: 25px;
            }
        }
    }
    .ant-drawer-body {
        padding: 40px 60px;
        ${mediaResponseMax.xs`
            padding: 30px 20px;
        `};
        .ant-upload{
          width: 100% !important;
        }
    }
    &.ant-bulk-demand-drawer {
        .ant-drawer-header {
            padding: 40px 28px 0px;
        }
        .ant-drawer-body {
            padding: 12px 28px;
        }
        .ant-drawer-footer {
            padding: 20px;
            border: unset;
        }
    }
    &.ant-payer-company-information-drawer {
        .ant-drawer-header {
            padding: 40px 60px 0px;
        }
        .ant-drawer-body {
            padding: 30px 60px;
        }
        .ant-drawer-footer {
            padding: 0 60px 40px 60px;
            border: unset;
        }

        ${mediaResponseMax.xs`
            .ant-drawer-header {
                padding: 40px 20px 0px;
            }
            .ant-drawer-body {
                padding: 30px 20px;
            }
            .ant-drawer-footer {
                padding: 0 20px 20px 20px;
                border: unset;
            }
        `};
    }
  
`;

export {
    DrawerWrapper
};