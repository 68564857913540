import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import customerService from '../../core/services/customer.service';
import dayjs from 'dayjs';

export const get = createAsyncThunk(
    'getCustomer',
    async (_, { rejectWithValue }) => {
        try {
            return await customerService.get();
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const put = createAsyncThunk(
    'putCustomer',
    async (data, { rejectWithValue }) => {
        try {
            return await customerService.put(data);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const post = createAsyncThunk(
    'postCustomer',
    async (data, { rejectWithValue }) => {
        try {
            return await customerService.post(data);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getBusinessSectors = createAsyncThunk(
    'getBusinessSectors',
    async (params, { rejectWithValue }) => {
        try {
            return await customerService.getBusinessSectors(params);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getInvoicingChoices = createAsyncThunk(
    'getInvoicingChoices',
    async (_, { rejectWithValue }) => {
        try {
            return await customerService.getInvoicingChoices();
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const putInvoicingChoices = createAsyncThunk(
    'putInvoicingChoices',
    async (data, { rejectWithValue }) => {
        try {
            return await customerService.putInvoicingChoices(data);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getPayers = createAsyncThunk(
    'getPayers',
    async (_, { rejectWithValue }) => {
        try {
            return await customerService.getPayers();
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getPayersWithPayerAccountId = createAsyncThunk(
    'getPayersWithPayerAccountId',
    async (payerAccountId, { rejectWithValue }) => {
        try {
            return await customerService.getPayersWithPayerAccountId(payerAccountId);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const postPayers = createAsyncThunk(
    'postPayers',
    async (taxNumber, { rejectWithValue }) => {
        try {
            return await customerService.postPayers(taxNumber);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getShipments = createAsyncThunk(
    'getShipments',
    async (params, { rejectWithValue }) => {
        try {
            return await customerService.getShipments(params);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getShipmentById = createAsyncThunk(
    'getShipmentById',
    async (shipmentId, { rejectWithValue }) => {
        try {
            return await customerService.getShipmentById(shipmentId);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getShipmentPreviewById = createAsyncThunk(
    'getShipmentPreviewById',
    async (shipmentId, { rejectWithValue }) => {
        try {
            return await customerService.getShipmentPreviewById(shipmentId);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getPayments = createAsyncThunk(
    'getPayments',
    async (params, { rejectWithValue }) => {
        try {
            return await customerService.getPayments(params);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getShipmentCount = createAsyncThunk(
    'getCustomerShipmentCount',
    async (params, { rejectWithValue }) => {
        try {
            return await customerService.getShipmentCount(params);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getProcuredShipmentsCount = createAsyncThunk(
    'getProcuredShipmentsCount',
    async (params, { rejectWithValue }) => {
        try {
            return await customerService.getProcuredShipmentsCount(params);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getNotificationEmails = createAsyncThunk(
    'getNotificationEmails',
    async (_, { rejectWithValue }) => {
        try {
            return await customerService.getNotificationEmails();
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const putNotificationEmails = createAsyncThunk(
    'putNotificationEmails',
    async (data, { rejectWithValue }) => {
        try {
            return await customerService.putNotificationEmails(data);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getSurveyQuestions = createAsyncThunk(
    'getSurveyQuestions',
    async (shipmentId, { rejectWithValue }) => {
        try {
            return await customerService.getSurveyQuestions(shipmentId);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const postSurveys = createAsyncThunk(
    'postSurveys',
    async ({ shipmentId, data }, { rejectWithValue }) => {
        try {
            return await customerService.postSurveys(shipmentId, data);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);
export const postShareEmail = createAsyncThunk(
    'postShareEmail',
    async ({ shipmentId, data }, { rejectWithValue }) => {
        try {
            return await customerService.postShareEmail(shipmentId, data);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const postTaxNumbersJoinDemands = createAsyncThunk(
    'postTaxNumbersJoinDemands',
    async (taxNumber, { rejectWithValue }) => {
        try {
            return await customerService.postTaxNumbersJoinDemands(taxNumber);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const defaultFilteredDate = {
    startDate: dayjs().subtract(1, 'week').format('YYYY-MM-DD'),
    finishDate: dayjs().add(1, 'week').format('YYYY-MM-DD'),
};

export const defaultFilteredPayment = {
    startDate: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
    finishDate: dayjs().format('YYYY-MM-DD'),
};

const initialState = {
    businessSectors: [],
    invoicePreferences: {},
    customer: {},
    payers: [],
    payer: {},
    shipment: [],
    shipmentPageProperty: {},
    shipmentDetail: {},
    isFetching: false,
    filteredObject: defaultFilteredDate,
    isDetail: false,
    payments: [],
    paymentsPageProperty: {},
    filteredPayment: defaultFilteredPayment,
    shipmentCount: [],
    procuredShipmentsCount: [],
    shipmentHookFilteredObject: defaultFilteredDate,
    notificationEmails: [],
    surveyQuestions: [],
};

export const customerSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        setPayer(state, action) {
            state.payer = action.payload;
        },
        setFilteredObject: (state, action) => {
            state.filteredObject = action.payload;
        },
        setIsDetail: (state, action) => {
            state.isDetail = action.payload;
        },
        setFilteredPayment: (state, action) => {
            state.filteredPayment = action.payload;
        },
        setShipmentHookFilteredObject: (state, action) => {
            state.shipmentHookFilteredObject = action.payload;
        },
        setDetailClear: (state, action) => {
            state.shipmentDetail = {};
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getBusinessSectors.fulfilled, (state, action) => {
            state.businessSectors = action?.payload?.data?.data;
        });
        builder.addCase(get.fulfilled, (state, action) => {
            state.customer = action?.payload?.data?.data;
        });
        builder.addCase(getInvoicingChoices.fulfilled, (state, action) => {
            state.invoicePreferences = action?.payload?.data?.data || {};
        });
        builder.addCase(getPayers.fulfilled, (state, action) => {
            state.payers = action?.payload?.data?.data;
        });
        builder.addCase(getPayersWithPayerAccountId.fulfilled, (state, action) => {
            state.payer = action?.payload?.data?.data;
        });
        builder.addCase(getShipments.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(getShipments.fulfilled, (state, action) => {
            state.shipment = action?.payload?.data?.data;
            state.shipmentPageProperty = {
                totalCount: action?.payload?.data?.totalCount,
                limit: action?.payload?.data?.limit,
                next: action?.payload?.data?.next,
                prev: action?.payload?.data?.prev,
            } || {};
            state.isFetching = false;
        });
        builder.addCase(getShipments.rejected, (state, action) => {
            state.shipment = [];
            state.isFetching = false;
        });
        builder.addCase(getShipmentById.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(getShipmentById.fulfilled, (state, action) => {
            state.shipmentDetail = action?.payload?.data?.data;
            state.isFetching = false;
        });
        builder.addCase(getShipmentById.rejected, (state) => {
            state.shipmentDetail = {};
            state.isFetching = false;
        });
        builder.addCase(getShipmentPreviewById.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(getShipmentPreviewById.fulfilled, (state, action) => {
            state.shipmentDetail = action?.payload?.data?.data;
            state.isFetching = false;
        });
        builder.addCase(getShipmentPreviewById.rejected, (state, action) => {
            state.shipmentDetail = { errors: action?.payload };
            state.isFetching = false;
        });
        builder.addCase(getPayments.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(getPayments.fulfilled, (state, action) => {
            state.payments = action?.payload?.data?.data;
            state.paymentsPageProperty = {
                totalCount: action?.payload?.data?.totalCount,
                limit: action?.payload?.data?.limit,
                next: action?.payload?.data?.next,
                prev: action?.payload?.data?.prev,
            } || {};
            state.isFetching = false;
        });
        builder.addCase(getPayments.rejected, (state, action) => {
            state.payments = [];
            state.isFetching = false;
        });
        builder.addCase(getShipmentCount.fulfilled, (state, action) => {
            state.shipmentCount = action?.payload?.data?.data;
        });
        builder.addCase(getProcuredShipmentsCount.fulfilled, (state, action) => {
            state.procuredShipmentsCount = action?.payload?.data?.data;
        });
        builder.addCase(getNotificationEmails.fulfilled, (state, action) => {
            state.notificationEmails = action?.payload?.data?.data;
        });
        builder.addCase(getSurveyQuestions.fulfilled, (state, action) => {
            state.surveyQuestions = action?.payload?.data?.data;
        });
        builder.addCase(postSurveys.pending, (state, action) => {
            state.isFetching = true;
        });
        builder.addCase(postSurveys.fulfilled, (state, action) => {
            state.isFetching = false;
        });
        builder.addCase(postSurveys.rejected, (state, action) => {
            state.isFetching = false;
        });
    }
});

export const { setPayer, setFilteredObject, setIsDetail, setFilteredPayment, setShipmentHookFilteredObject, setDetailClear } = customerSlice.actions;
