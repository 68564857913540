import dataApi from '../apis/data.api';

const getVirtualPosCardsByBinNumber = async (binNumber) => {
  return dataApi.get(`virtual-pos/cards/${binNumber}`);
};

const getInstallments = async (params) => {
    return dataApi.get('virtual-pos/cards/installments', { params });
};

const getInstallmentsByCardBrandCode = async (cardBrandCode, params) => {
    return dataApi.get(`virtual-pos/cards/${cardBrandCode}/installments`, { params });
};

const getStoredCards = async () => {
    return dataApi.get('virtual-pos/store-cards');
};

const postPayments = async (data) => {
    return dataApi.post('virtual-pos/payments', data);
};

const deleteStoredCard = async (storeCardId) => {
    return dataApi.delete(`virtual-pos/store-cards/${storeCardId}`);
};

const putStoreCards = async (storeCardId, data) => {
    return dataApi.put(`virtual-pos/store-cards/${storeCardId}`, data);
};
const getTransactionMessage = async (transactionId) => {
    return dataApi.get(`virtual-pos/transactions/${transactionId}/message`);
};
const virtualPosService = {
    getVirtualPosCardsByBinNumber,
    getInstallments,
    getInstallmentsByCardBrandCode,
    getStoredCards,
    postPayments,
    deleteStoredCard,
    putStoreCards,
    getTransactionMessage
};

export default virtualPosService;