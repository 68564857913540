import dataApi, { getQuery } from '../apis/data.api';

const get = async () => {
  return dataApi.get('customer');
};

const put = async (data) => {
  return dataApi.put('customers', data);
};

const post = async (data) => {
  return dataApi.post('customers', data);
};

const getPayers = async () => {
  return dataApi.get('customer/payers');
};

const getPayersWithPayerAccountId = async (payerAccountId) => {
  return dataApi.get(`customer/payers/${payerAccountId}`);
};

const postPayers = async (taxNumber) => {
  return dataApi.post(`customer/payers/${taxNumber}`);
};

const getShippingOffice = async () => {
  return dataApi.get('customer/shipping-office');
};

const getBusinessSectors = async (params) => {
  return dataApi.get('customers/business-sectors', { params });
};

const getInvoicingChoices = async () => {
  return dataApi.get('customer/invoicing-choices');
};

const putInvoicingChoices = async (data) => {
  return dataApi.put('customer/invoicing-choices', data);
};

const getShipments = async (params) => {
  return getQuery('customer/shipments', params);
};

const getShipmentById = (shipmentId) => {
  return getQuery(`customer/shipments/${shipmentId}`);
};

const getShipmentPreviewById = (shipmentId) => {
  return getQuery(`customers/shipments/${shipmentId}`);
};

const getPayments = async (params) => {
  return getQuery('customer/payments', params);
};

const getShipmentCount = async (params) => {
  return getQuery('customer/shipments/count', params);
};

const getProcuredShipmentsCount = async (params) => {
  return getQuery('customer/procured-shipments/count', params);
};

const getNotificationEmails = async () => {
  return dataApi.get('customer/notification-emails');
};

const putNotificationEmails = async (data) => {
  return dataApi.put('customer/notification-emails', data);
};

const getSurveyQuestions = async (shipmentId) => {
  return dataApi.get(`customers/shipments/${shipmentId}/surveys/questions`);
};

const postSurveys = async (shipmentId, data) => {
  return dataApi.post(`customer/shipments/${shipmentId}/surveys/answers`, data);
};

const postShareEmail = async (shipmentId, data) => {
  return dataApi.post(`customer/shipments/${shipmentId}/share-email`, data);
};

const postTaxNumbersJoinDemands = async (taxNumber) => {
  return dataApi.post(`customer/tax-numbers/${taxNumber}/join-demands`);
};

const customerService = {
  get,
  put,
  post,
  getPayers,
  getPayersWithPayerAccountId,
  postPayers,
  getShippingOffice,
  getBusinessSectors,
  getInvoicingChoices,
  putInvoicingChoices,
  getShipments,
  getShipmentById,
  getShipmentPreviewById,
  getPayments,
  getShipmentCount,
  getProcuredShipmentsCount,
  getNotificationEmails,
  putNotificationEmails,
  getSurveyQuestions,
  postSurveys,
  postShareEmail,
  postTaxNumbersJoinDemands
};

export default customerService;
