import { notification } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

export const notifications = {
    success: (message, title = 'Başarılı', options = {}) => notifications.default('success', message, title, options = {}),
    error: (message, title = 'Hata', options = {}) => notifications.default('error', message, title, options = {}),
    warning: (message, title = 'Uyarı', options = {}) => notifications.default('warning', message, title, options = {}),
    info: (message, title = 'Bilgi', options = {}) => notifications.default('info', message, title, options = {}),
    default: (type, message, title, options = {}) => {

        return notification[type]({
            description: message,
            message: title,
            placement: options.placement || 'bottomLeft',
            bottom: options.bottom || 24,
            icon: options.icon || null,
            closeIcon: <CloseOutlined />
        });
    }
};