import styled from 'styled-components';
import { Modal } from 'antd';
import { mediaResponseMax } from 'styles/mixins';

const ModalWrapper = styled(Modal)`
    top: ${props => props.top ? `${props?.top}px !important` : '180px !important'};
    ${mediaResponseMax.xs`
        top: 80px !important;
    `};

    &.ant-modal-not-header {
        .ant-modal-content {
            padding: 60px 40px 40px 40px;
            border-radius: 4px;
            ${mediaResponseMax.xs`
                padding: 40px 20px 20px 20px;
            `};
        }
        .ant-modal-close {
            top: 15px;
            .ant-modal-close-x {
                color: #4C6278;
            }
        }
    }

    .ant-modal-content {
        padding: ${props => props?.isShipmentModal ? '60px 20px 40px 20px' : '100px 40px 40px 40px'};
        border-radius: 4px;
        ${mediaResponseMax.xs`
            padding: 80px 20px 20px 20px;
        `};
    }
    .ant-modal-close {
        top: 20px;
        .ant-modal-close-x {
            color: #fff;
        }
    }
    .ant-modal-header {
        background-color: #4C6278;
        border-radius: 4px 4px 0 0;
        position: absolute;
        height: 60px;
        top: 0px;
        left: 0px;
        right: 0px;
        display: flex;
        align-items: center;
        padding-left: 40px;

        .ant-modal-title {
            font-size: 16px;
            font-weight: 600;
            color: #fff;
        }
    }

    .ant-modal-footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 20px;

        ${mediaResponseMax.xs`
            justify-content: space-between;
        `};
      button:disabled {
        background-color: #94a1ae !important;
        cursor: not-allowed !important;
      }

        .button-wrapper {
            .ant-btn-warning {
                min-width: 120px;
                height: 42px;
                
                ${mediaResponseMax.xs`
                    width: 114px;
                `};
            }
            &:not(:last-child) {
                margin-right: 12px;
            }
        }
    }
    
    &.modal-auction-bid{

    }
`;

export {
    ModalWrapper
};