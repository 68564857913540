import styled from 'styled-components';
import { mediaResponseMax, mediaResponseMin } from 'styles/mixins';

const StyledTimeline = (ComponentName) => styled(ComponentName)`
    padding-top: 4px;

    .status-history{
        display: flex;
        align-items: center;
        justify-content: space-between;

        .name {
            font-size: 14px;
            font-weight: 500;
            color: #000;
            flex: 1 1 auto;
        }
        
        .date{
            font-size: 12px;
            font-weight: 600;
            color: #94a1ae;
        }
    }

    &.custom-timeline {
        margin: 12px 0;

        .ant-timeline-item {
            .ant-timeline-item-head {
                width: 32px;
                height: 32px;
                border-width: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                top: 7px;
                ${mediaResponseMax.xs`
                    top: 0px;
                `};
                
                &::before {
                    position: absolute;
                    content: "";
                    width: 8px;
                    height: 8px;
                    background-color: #1890FF;
                    border-radius: 50%;
                }
            }
            .ant-timeline-item-head-blue {
                color: #DBE0E4;
                border-color: #DBE0E4;
            }
            .ant-timeline-item-tail {
                inset-inline-start: 15px;
                inset-block-start: 28px;
                border-color: #DBE0E4;
            }
        }

        .ant-timeline-item-content {
            display: flex;
            flex-direction: column;
            margin-inline-start: 40px;
            inset-block-start: 0px;
            position: relative;
            cursor: pointer;
            z-index: 1;
            ${mediaResponseMin.sm`
                padding: 12px;
            `};
            &:hover {
                ${mediaResponseMin.sm`
                    &::before {
                        content: "";
                        position: absolute;
                        background-color: #F0F1F2;
                        border-radius: 8px;
                        width: 100%;
                        height: 100%;
                        z-index: -1;
                        left: 0px;
                        top: 0px;
                    }
                    .ant-timeline-actions {
                        display: inline-block;
                    }
                `}
            }
            ${mediaResponseMax.xs`
                margin-inline-start: 50px;
            `}
            .ant-timeline-item-title {
                font-size: 14px;
                font-weight: 500;
                width:80%;
                color: #1890FF;
                margin-bottom: 2px;
            }
            .ant-timeline-item-description {
                font-size: 14px;
                font-weight: 500;
                width:80%;
                color: #4C6278;
            }
            .ant-timeline-item-tags {
                display: flex;
                align-items: center;
                margin-top: 10px;

                .ant-tag-wrapper:not(:last-child) {
                    margin-right: 4px;
                }
            }
            .ant-timeline-actions {
                position: absolute;
                right: 0px;
                //margin-top: 13px;
                margin-right: 10px;
                span > svg {
                    color:#1890FF
                }
                ${mediaResponseMin.sm`
                    display: none;
                `};

                & > .anticon:first-child {
                    margin-right: 12px;
                }
            }
            .ant-timeline-actions-waypoint {
                position: absolute;
                right: 0px;
                margin-right: 10px;
                span > svg {
                  color:#1890FF
                }
                & > .anticon:first-child {
                  margin-right: 12px;
              }
          }
        }
    }


`;

export {
    StyledTimeline
};