import dataApi, { getQuery } from '../apis/data.api';

const postDocumentGroup = async (documentGroup, data) => {
  return dataApi.post(`document/groups/${documentGroup}/entities`, data);
};

const getDocumentsGroupTypes = async (group, params) => {
  return getQuery(`documents/groups/${group}/types`, params);
};

const getDocumentGroupWithTypeId = async (documentGroup, entityId, documentTypeId) => {
  return dataApi.get(`document/groups/${documentGroup}/entities/${entityId}/types/${documentTypeId}`);
};

const getDocumentGroupWithEntityId = async (documentGroup, entityId) => {
  return dataApi.get(`document/groups/${documentGroup}/entities/${entityId}`);
};

const getDocumentsTypesClasses = async (documentTypeId) => {
  return dataApi.get(`documents/types/${documentTypeId}/classes`);
};

const getDocumentUpload = async (documentTypeId, entityId, params, data) => {
  return dataApi.post(`documents/type/${documentTypeId}/entities/${entityId}/upload`, data, { params });
};

const deleteDocumentPage = async (documentId, pageId) => {
  return dataApi.delete(`document/${documentId}/pages/${pageId}`);
};

const getDocumentGroupWithEntityIdAndType = async (documentGroup, entityId, params) => {
  return getQuery(`document/groups/${documentGroup}/entities/${entityId}/types`, params);
};

const documentService = {
  postDocumentGroup,
  getDocumentsGroupTypes,
  getDocumentGroupWithTypeId,
  getDocumentGroupWithEntityId,
  getDocumentsTypesClasses,
  getDocumentUpload,
  deleteDocumentPage,
  getDocumentGroupWithEntityIdAndType,
};

export default documentService;
