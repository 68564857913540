import dataApi from '../apis/data.api';

const sendMessage = async (data) => {
  return dataApi.post('messages', data);
};

const messageService = {
  sendMessage,
};

export default messageService;
