import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import messageService from '../../core/services/message.service';

export const sendMessage = createAsyncThunk(
    'sendMessage',
    async (data, { rejectWithValue }) => {
        try {
            return await messageService.sendMessage(data);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

const initialState = {};

export const messageSlice = createSlice({
    name: 'message',
    initialState,
    reducers: {},
    extraReducers: (builder) => { }
});


