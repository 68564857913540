import styled from 'styled-components';

const StyledProgress = (ComponentName) => styled(ComponentName)`
    &.ant-progress {
        margin-top: ${(props) => props.mt ? props.mt : ''};
        .ant-progress-inner {
            width: 160px !important;
            height: 160px !important;

            .ant-progress-text {
                width: 100px;
                height: 100px;
                background-color: #F8F9FA;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                inset-inline-start: 19%;
                font-size: 20;
                font-weight: 700;
                color: #232328;
            }
        }
    }
`;

export { StyledProgress };