import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import documentService from '../../core/services/document.service';

export const postDocumentGroup = createAsyncThunk(
    'postDocumentGroup',
    async ({ documentGroup, data }, { rejectWithValue }) => {
        try {
            return await documentService.postDocumentGroup(documentGroup, data);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getDocumentsGroupTypes = createAsyncThunk(
    'getDocumentsGroupTypes',
    async ({ group, params }, { rejectWithValue }) => {
        try {
            return await documentService.getDocumentsGroupTypes(group, params);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getDocumentGroupWithTypeId = createAsyncThunk(
    'getDocumentGroupWithTypeId',
    async ({ documentGroup, entityId, documentTypeId }, { rejectWithValue }) => {
        try {
            return await documentService.getDocumentGroupWithTypeId(documentGroup, entityId, documentTypeId);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getDocumentGroupWithEntityId = createAsyncThunk(
    'getDocumentGroupWithEntityId',
    async ({ documentGroup, entityId }, { rejectWithValue }) => {
        try {
            return await documentService.getDocumentGroupWithEntityId(documentGroup, entityId);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getDocumentsTypesClasses = createAsyncThunk(
    'getDocumentsTypesClasses',
    async ({ documentTypeId }, { rejectWithValue }) => {
        try {
            return await documentService.getDocumentsTypesClasses(documentTypeId);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getDocumentUpload = createAsyncThunk(
    'getDocumentUpload',
    async ({ documentTypeId, entityId, params, data }, { rejectWithValue }) => {
        try {
            return await documentService.getDocumentUpload(documentTypeId, entityId, params, data);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const deleteDocumentPage = createAsyncThunk(
    'deleteDocumentPage',
    async ({ documentId, pageId }, { rejectWithValue }) => {
        try {
            return await documentService.deleteDocumentPage(documentId, pageId);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getDocumentGroupWithEntityIdAndType = createAsyncThunk(
    'getDocumentGroupWithEntityIdAndType',
    async ({ documentGroup, entityId, params }, { rejectWithValue }) => {
        try {
            return await documentService.getDocumentGroupWithEntityIdAndType(documentGroup, entityId, params);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

const initialState = {
    groupDocuments: [],
    documentGroupTypes: [],
    documentGroup: [],
    documentsTypesClasses: [],
    isLoading: false,
    selectedDocumentInfo: {}
};

export const documentSlice = createSlice({
    name: 'document',
    initialState,
    reducers: {
        setSelectedDocumentInfo: (state, action) => {
            state.selectedDocumentInfo = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(postDocumentGroup.fulfilled, (state, action) => {
            state.groupDocuments = action?.payload?.data[0]?.documents || [];
        });
        builder.addCase(getDocumentsGroupTypes.fulfilled, (state, action) => {
            state.documentGroupTypes = action?.payload?.data || [];
        });
        builder.addCase(getDocumentGroupWithEntityId.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getDocumentGroupWithEntityId.fulfilled, (state, action) => {
            state.isLoading = false;
            state.documentGroup = action?.payload?.data || [];
        });
        builder.addCase(getDocumentGroupWithEntityId.rejected, (state, action) => {
            state.isLoading = false;
            state.documentGroup = [];
        });
        builder.addCase(getDocumentsTypesClasses.fulfilled, (state, action) => {
            state.documentsTypesClasses = action?.payload?.data || [];
        });
        builder.addCase(getDocumentGroupWithEntityIdAndType.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getDocumentGroupWithEntityIdAndType.fulfilled, (state, action) => {
            state.isLoading = false;
            state.documentGroup = action?.payload?.data || [];
        });
        builder.addCase(getDocumentGroupWithEntityIdAndType.rejected, (state, action) => {
            state.isLoading = false;
            state.documentGroup = [];
        });
    }
});

export const { setSelectedDocumentInfo } = documentSlice.actions;

