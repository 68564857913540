import axios from 'axios';
import { Cookies } from 'react-cookie';
import {logoutWallet, walletRefresh} from 'store/slice/wallet.slice';
import { setToken } from 'core/utils/helpers/converter';
import { RoutePaths } from 'router/paths';

let cookie = new Cookies();
let store;

export const injectWalletStore = _store => {
    store = _store;
};

export const wallet = axios.create({
    baseURL: process.env.REACT_APP_WALLET_API_URL
});

wallet.interceptors.request.use(
    (config) => {
        const token = cookie.get('accessTokenWallet', { path: '/' });
        config.headers.Authorization = `Bearer ${token}`;
        return config;
    },
    (error) => Promise.reject(error)
);

wallet.interceptors.response.use(
    (response) => {
        return Promise.resolve(response);
    },
    (error) => {
        if (error.response) {
            if (error.response.status === 401) {
                return store.dispatch(walletRefresh()).then((res) => {
                    if (res.payload?.status === 200) {
                        setToken({
                            accessToken: res?.payload?.data?.access_token,
                            expiresIn: res?.payload?.data?.expires_in,
                        }, 'Wallet');
                        return wallet(error.config);
                    } else {
                        if(location.pathname === '/musteri/sepet') return store.dispatch(logoutWallet(() => location.pathname = RoutePaths.CUSTOMER.CART));
                        return store.dispatch(logoutWallet(() => location.pathname = RoutePaths.CUSTOMER.ADVANTAGE_WALLET));
                    }
                });
            } else {
                return Promise.reject(error.response.data);
            }
        }
        return Promise.reject(error);
    }
);

export default wallet;