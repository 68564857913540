import dataApi from '../apis/data.api';

const getCart = async () => {
    return dataApi.get('cart');
};

const putCart = async (data) => {
    return dataApi.put('cart', data);
};

const getCheckout = async (params) => {
    return dataApi.get('cart/checkout', { params });
};

const postCartWithQuoteId = async (data) => {
    return dataApi.post('cart/quotes', data);
};

const deleteCartLines = async (cartLineId) => {
    return dataApi.delete(`cart/lines/${cartLineId}`);
};

const postPaymentsCreditLimit = async (data, cartId) => {
    return dataApi.post(`carts/${cartId}/payments/credit-limit`, data);
};

const postPaymentsWireTransfer = async (data, cartId) => {
    return dataApi.post(`carts/${cartId}/payments/wire-transfer`, data);
};

const postPaymentsVirtualPos = async (data, cartId) => {
    return dataApi.post(`carts/${cartId}/payments/virtual-pos`, data);
};

const getCartSold = async (cartId) => {
    return dataApi.get(`cart/${cartId}/sold`);
};

const cartService = {
    getCart,
    putCart,
    getCheckout,
    postCartWithQuoteId,
    deleteCartLines,
    postPaymentsCreditLimit,
    postPaymentsWireTransfer,
    postPaymentsVirtualPos,
    getCartSold
};

export default cartService;

