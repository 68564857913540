import { createSlice } from '@reduxjs/toolkit';
import { getView, isClient } from 'core/utils/helpers/utils';

const initialState = {
    collapsed: isClient() && window.innerWidth > 1220 ? false : true,
    view: isClient() && getView(window.innerWidth),
    openDrawer: false,
    company: { key: 8, value: 'customer', name: 'Müşteri', path: '/musteri' }
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        toggleCollapsed(state) {
            state.collapsed = !state.collapsed;
        },
        toggleOpenDrawer(state) {
            state.openDrawer = !state.openDrawer;
        },
        toggleAll(state, action) {
            const { payload } = action;
            const view = getView(payload);
            state.collapsed = view !== 'DesktopView';
            state.view = view;
        },
        setView(state, action) {
            const { payload } = action;
            state.view = payload;
        },
        setCompany(state, action){
            const { payload } = action;
            state.company = payload;
        }
    },
});

export const {
    toggleCollapsed,
    toggleOpenDrawer,
    toggleAll,
    setView,
    setCompany
} = appSlice.actions;