import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { SearchOutlined } from '@ant-design/icons';
import { EtaSelect } from 'components/ui';
import { menuList } from 'components/layout/menu/menu.list';

const SearchBox = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [options, setOptions] = useState([]);
    const [value, setValue] = useState(null);
    const [searchText, setSearchText] = useState('');
    
    const {  company } = useSelector(state => state.app);

    useEffect(() => {
        if (location) {
            if( value === location.pathname ) {
                setTimeout(() => {
                    setValue(null);
                } , 300);
            }
        }
    }, [location]);

    const onSearch = (value) => {
        if (value?.length > 2) {
            const items = menuList(company).flatMap(element => [
                element,
                ...element.children.flatMap(element => [
                    element,
                    ...element.children.map(m => ({
                        key: m.key,
                        label: m.label,
                        path: m.path,
                        openKeys: [element.key]
                    }))
                ]),
            ]);
            var searchFilter = items.filter((f) => f.label.toLowerCase().includes(value.toLowerCase()));
            var searchItems = searchFilter.map((d) => ({
                value: d.key,
                label: d.label,
                path: d.path
            }));

            setOptions(searchItems);
        } else{
            setOptions([]);
        }
    };

    const onChange = (value) => {
        const item = options.filter(f => f.value === value);
        if (item.length > 0) {
            return navigate(item[0].path);
        }
    };

    return (
        <EtaSelect
            showSearch
            size="large"
            placeholder="Ara..."
            className="search-box"
            value={value}
            suffixIcon={<SearchOutlined style={{fontSize: '18px', color: '#4C6278'}}/>}
            defaultActiveFirstOption={false}
            notFoundContent={null}
            optionFilterProp="children"
            onSelect={setValue}
            onSearch={(value) => onSearch(value)}
            onChange={(value) => onChange(value)}
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            options={options}
        />
    );
};

export default SearchBox;