import dataApi from '../apis/data.api';

const getTypes = async (vehicleType) => {
  return dataApi.get(`trailers/types?vehicleType=${vehicleType}`);
};

const getTypeWityKey = async (key) => {
  return dataApi.get(`trailers/types/${key}`);
};

const getFloorTypes = async () => {
  return dataApi.get('trailers/floor-types');
};

const getFloorTypesWithKey = async (key) => {
  return dataApi.get(`trailers/floor-types/${key}`);
};

const getTypesProperties = async (params) => {
    return dataApi.get('trailers/types/properties', { params });
};

const getLastLocation = async (params) => {
  return dataApi.get('trailers/last-location', { params });
};


const trailerService = {
  getTypes,
  getTypeWityKey,
  getFloorTypes,
  getFloorTypesWithKey,
  getTypesProperties,
  getLastLocation
};

export default trailerService;
