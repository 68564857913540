import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { setCompany } from 'store/slice/app.slice';
import { login, logout } from 'store/slice/auth.slice';
import { getIntro, getJoinDemands, post, putJoinRoles } from 'store/slice/person.slice';
import { postTaxNumbersJoinDemands as customerJoinDemands } from 'store/slice/customer.slice';
import { postTaxNumbersJoinDemands as supplierJoinDemands } from 'store/slice/supplier.slice';
import { notifications } from 'core/utils/common/notifications';
import { RoutePaths } from 'router/paths';
import PersonType from 'core/enums/person-type';
import AccountType from 'core/enums/account-type';

export const useAuth = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const onLogin = useCallback(async (data) => {
        const action = await dispatch(login(data));
        if (!login.fulfilled.match(action)) {
            return notifications.error(action?.payload[0]?.message || action?.payload?.response?.data[0]?.message);
        }

        await onIntro(data.personType);
    }, [dispatch]);

    const onRegister = useCallback(async (data) => {
        const action = await dispatch(post(data));
        if (!post.fulfilled.match(action)) {
            return notifications.error(action?.payload[0]?.message);
        }

        await onIntro(data.personType);
    }, [dispatch]);

    const onIntro = useCallback(async (personType) => {
        const firstLogin = JSON.parse(sessionStorage.getItem('first-login'));

        const introAction = await dispatch(getIntro());
        if (!getIntro.fulfilled.match(introAction)) {
            return notifications.error(introAction?.payload[0]?.message);
        }

        const intro = introAction?.payload?.data?.data;
        if (intro?.personStatus === 6) {
            return dispatch(logout(() => navigate(RoutePaths.AUTH.LOGIN_BLOCKED)));
        }
        if (intro?.personType === PersonType.Internal) {
            return dispatch(logout(() => navigate(RoutePaths.AUTH.LOGIN, { state: { message: 'eTA Personeli olduğunuz için, portale giriş yapmaya yetkiniz yoktur.' } })));
        }
        
        if (intro?.accountIntro) {
            if (personType === PersonType.Customer){
                return intro?.accountIntro?.isCustomer && [8, 9].includes(intro?.personType) ? checkReturnUrl(RoutePaths.CUSTOMER.CUSTOMER) : navigate(RoutePaths.AUTH.NOT_COMPANY);}
            else if (personType === PersonType.Supplier)
                return intro?.accountIntro?.isSupplier && [1, 9].includes(intro?.personType) ? checkReturnUrl(RoutePaths.SUPPLIER.SUPPLIER) : navigate(RoutePaths.AUTH.NOT_COMPANY);
            else
                return onLogout();
        }
        const joinDemandsAction = await dispatch(getJoinDemands({accountJoinDemandStatus: 3, accountType: personType === PersonType.Customer ? AccountType.Customer : AccountType.Supplier }));
        if (!getJoinDemands.fulfilled.match(joinDemandsAction)) {
            return notifications.error(joinDemandsAction?.payload?.message);
        }
        
        const joinDemands = joinDemandsAction?.payload?.data?.data;
        if (joinDemands) {
            return navigate(firstLogin ? RoutePaths.AUTH.JOIN_DEMANDS : RoutePaths.ACCOUNT.JOIN_DEMANDS);
        } else {
            if (intro.personType == personType || intro.personType == PersonType.CustomerAndSupplier) {
                return navigate(firstLogin ? RoutePaths.AUTH.REGISTER_TO_COMPANY : RoutePaths.ACCOUNT.REGISTER_TO_COMPANY);
            } else {
                return navigate(RoutePaths.AUTH.NOT_COMPLETED);
            }
        }
    }, [dispatch]);

    const onSelectCompany = useCallback(async (key, isNavigate = false) => {
        switch (key) {
            case 1:
                dispatch(setCompany({ key: 1, value: 'supplier', name: 'Tedarikçi', path: '/tedarikci' }));
                if (isNavigate) {
                    navigate(RoutePaths.SUPPLIER.SUPPLIER);
                }
                break;
            case 8:
                dispatch(setCompany({ key: 8, value: 'customer', name: 'Müşteri', path: '/musteri' }));
                if (isNavigate) {
                    navigate(RoutePaths.CUSTOMER.CUSTOMER);
                }
                break;
        }
    }, [dispatch]);

    const onJoinDemands = useCallback(async (taxNumber, accountType) => {
        var firstLogin = JSON.parse(sessionStorage.getItem('first-login') || false);

        const joinDemandsService = accountType === AccountType.Customer ? customerJoinDemands : supplierJoinDemands;
        const joinDemandsAction = await dispatch(joinDemandsService(taxNumber));
        if (!joinDemandsService.fulfilled.match(joinDemandsAction)) {
            return notifications.error(joinDemandsAction?.payload[0]?.message);
        }
        const intro = await dispatch(getIntro({ requestNewToken: true }));
        if (!getIntro.fulfilled.match(intro)) {
            return navigate(RoutePaths.AUTH.LOGIN);
        }

        return navigate(firstLogin ? '/uyelik/firma-kayit-onayi' : '/hesap/firma-kayit-onayi');
    }, [dispatch, navigate]);

    const onJoinRoles = useCallback(async (path) => {
        const actionJoinRoles = await dispatch(putJoinRoles());
        if (!putJoinRoles.fulfilled.match(actionJoinRoles)) {
            return notifications.error(actionJoinRoles?.payload[0]?.message);
        }
        const intro = await dispatch(getIntro({ requestNewToken: true }));
        if (!getIntro.fulfilled.match(intro)) {
            return navigate(RoutePaths.AUTH.LOGIN);
        }

        return navigate(path);
    }, [dispatch, navigate]);

    const checkReturnUrl = (path) => {
        const pathname = location?.state?.from?.pathname;
        return pathname
            ? navigate(pathname)
            : navigate(path);
    };

    const onLogout = () => {
        dispatch(setCompany({}));
        return dispatch(logout(() => navigate(RoutePaths.AUTH.LOGIN)));
    };

    return {
        onLogin,
        onRegister,
        onSelectCompany,
        onJoinDemands,
        onJoinRoles,
        onLogout
    };
};

