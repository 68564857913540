import { combineReducers } from '@reduxjs/toolkit';
import { appSlice } from './slice/app.slice';
import { authSlice } from './slice/auth.slice';
import { personSlice } from './slice/person.slice';
import { agreementSlice } from './slice/agreement.slice';
import { locationSlice } from './slice/location.slice';
import { customerSlice } from './slice/customer.slice';
import { accountSlice } from './slice/account.slice';
import { bankSlice } from './slice/bank.slice';
import { supplierSlice } from './slice/supplier.slice';
import { vehicleSlice } from './slice/vehicle.slice';
import { trailerSlice } from './slice/trailer.slice';
import { quoteSlice } from './slice/quote.slice';
import { invoicingSlice } from './slice/invoicing.slice';
import { cartSlice } from './slice/cart.slice';
import { invoicesSlice } from './slice/invoice.slice';
import { auctionSlice } from './slice/auction.slice';
import { virtualPosSlice } from './slice/virtualpos.slice';
import { paymentSlice } from './slice/payment.slice';
import { shipmentSlice } from './slice/shipment.slice';
import { messageSlice } from './slice/message.slice';
import { documentSlice } from './slice/document.slice';
import { walletSlice } from './slice/wallet.slice';

const appReducer = combineReducers({
    app: appSlice.reducer,
    auth: authSlice.reducer,
    person: personSlice.reducer,
    agreement: agreementSlice.reducer,
    location: locationSlice.reducer,
    customer: customerSlice.reducer,
    account: accountSlice.reducer,
    bank: bankSlice.reducer,
    supplier: supplierSlice.reducer,
    vehicle: vehicleSlice.reducer,
    trailer: trailerSlice.reducer,
    quote: quoteSlice.reducer,
    invoicing: invoicingSlice.reducer,
    cart: cartSlice.reducer,
    invoices: invoicesSlice.reducer,
    auction: auctionSlice.reducer,
    virtualPos: virtualPosSlice.reducer,
    payment: paymentSlice.reducer,
    shipment: shipmentSlice.reducer,
    message: messageSlice.reducer,
    document: documentSlice.reducer,
    wallet: walletSlice.reducer,
});

const rootReducer = (state, action) => {
    if (action.type === 'logout/fulfilled') {
        return appReducer({}, action);
    }
    return appReducer(state, action);
};

export default rootReducer;