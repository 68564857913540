import { Layout, Space } from 'antd';
import styled from 'styled-components';
import { mediaResponseMax } from 'styles/mixins';

const { Header } = Layout;
const HeaderWrapper = styled(Header)`
    line-height: normal;
    padding: 12px 24px;

    ${mediaResponseMax.sm`
        padding-inline: 16px;
    `};

    &.dashboard {
        background-color: #0a2647 !important;

        ${mediaResponseMax.sm`
            padding-block: 18px;
        `};
    }
    &.auth {
        background-color: #fff;
        border-bottom: solid 1px #ddd;

        .anticon{
            color: #4c6278;
        }

        .header-action-user{
            .ant-avatar{
                background: #eee;
            }
            .user-text {
                label {
                    color: #4c6278;
                }
                small{
                    color: #4c6278;
                }
            }
        }
    }
   
    .header-logo {
        margin: 0;
        width: 191px;
        height: 36px;
        ${mediaResponseMax.sm`
            width: 148px;
            height: 28px;
        `};
    }

    .search-box {
      width: 280px;
      margin-right: 20px;

      .ant-select-selection-placeholder {
        color: #4C6278;
        font-weight: 500;
        font-size: 14px;
      }
    }
`;
const HeaderActionsWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 24px;

    .ant-badge {
        line-height: 1;
    }
    .anticon {
        cursor: pointer;
        font-size: 24px;
        color: #fff;
    }

    .header-action-user {
        display: flex;
        align-items: center;
        gap: 6px;

        .user-text {
            display: flex;
            flex-direction: column;

            label{
                font-size: 14px;
                font-weight: 600;
                line-height: 16px;
                margin-bottom: 4px;
                color: #fff;
            }
            small{
                display: flex;
                flex-direction: row;
                gap: 6px;
                font-size: 10px;
                font-weight: 700;
                color: #b5bec4;
                opacity: 0.80;
            }
        }
        .anticon-down{
            font-size: 12px;
            margin-left: 12px;
        }
    }

    

`;

const PopoverHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const NotificationContent = styled.div`
    .notification-header{
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        width:100%;
        padding: 14px;
        border-bottom: 2px solid #F3F3F3;
    }

    .notification-body{
        padding-right: 14px;
        padding-left: 14px;
        max-height: 500px;
        overflow-y: auto;

        .ant-list-item{
            cursor: pointer;
            
            &.is-read{
                cursor: default;
                opacity: 0.60;
                color: black;
            }

            .item-title{
                font-weight: 600;
                font-size: 13px;
                margin-bottom: 6px;
                padding-right: 50px;
            }
            .item-detail{
                font-size: 11px;
                margin-bottom: 6px;
            }
            .item-date{
                font-weight: 500;
                font-size: 10px;
                color: #808080;
            }
        }

        .ant-list-item-meta{
            margin-block-end:0 ;
        }

        .ant-list-item-meta-title{
            font-style: normal ;
            font-weight: 500 ;
            font-size: 12px ;
            line-height: 20px ;      
        }
    }

    .notification-footer{
        display: flex;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 14px;
        margin-bottom: 14px;
        color: #4C6278;
    }
`;

export {
    HeaderWrapper,
    HeaderActionsWrapper,
    PopoverHeaderWrapper,
    NotificationContent
};