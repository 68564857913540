import axios from 'axios';
import QueryString from 'qs';
import { Cookies } from 'react-cookie';
import { refresh, logout } from 'store/slice/auth.slice';
import { setToken } from 'core/utils/helpers/converter';
import { RoutePaths } from 'router/paths';

let cookie = new Cookies();
let store;

export const injectStore = _store => {
    store = _store;
};

export const data = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

data.interceptors.request.use(
    (config) => {
        const token = cookie.get('accessToken', { path: '/' });
        config.headers.Authorization = `Bearer ${token}`;
        return config;
    },
    (error) => Promise.reject(error)
);

data.interceptors.response.use(
    (response) => {
        return Promise.resolve(response);
    },
    (error) => {
        if (error.response) {
            if (error.response.status === 401) {
                if(error.config.url === 'auth/refresh-token') {
                    return store.dispatch(logout(() => location.pathname = RoutePaths.AUTH.LOGIN));
                }
                return store.dispatch(refresh()).then((res) => {
                    if (res.payload?.status === 200) {
                        setToken({
                            accessToken: res?.payload?.data?.accessToken,
                            expiresIn: res?.payload?.data?.expiresIn,
                        });
                        return data(error.config);
                    } else {
                        return store.dispatch(logout(() => location.pathname = RoutePaths.AUTH.LOGIN));
                    }
                });
            } else {
                return Promise.reject(error.response.data);
            }
        }
        return Promise.reject(error);
    }
);

const getQuery = async (url, params) => {
    return data.get(url, {
        params, paramsSerializer: (params) =>
            QueryString.stringify(params, { arrayFormat: 'repeat' })
    });
};

const postQuery = async (url, params) => {
    const queryString = QueryString.stringify(params, { arrayFormat: 'repeat' });
    const requestUrl = `${url}?${queryString}`;
    return data.post(requestUrl);
};

const putQuery = async (url, params) => {
    const queryString = QueryString.stringify(params, { arrayFormat: 'repeat' });
    const requestUrl = `${url}?${queryString}`;
    return data.put(requestUrl);
};

const deleteQuery = async (url, params) => {
    const queryString = QueryString.stringify(params, { arrayFormat: 'repeat' });
    const requestUrl = `${url}?${queryString}`;
    return data.delete(requestUrl);
};

export { getQuery, postQuery, putQuery, deleteQuery };

export default data;