import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';
import { Header, SiderMenu } from 'components/layout';

import NotificationHub from 'core/hubs/notification.hub';
import PersonHub from 'core/hubs/person.hub';

const { Content } = Layout;

const HomeLayout = () => {
  return (
    <Layout className="custom-layout">
      <Header />
      <Layout>
        <SiderMenu />
        <Content className="custom-content">
          <Outlet />
        </Content>
      </Layout>
      <NotificationHub />
      <PersonHub />
    </Layout>
  );
};

export default HomeLayout;