import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import locationService from '../../core/services/location.service';

export const getCountriesCities = createAsyncThunk(
    'getCountriesCities',
    async (params, { rejectWithValue }) => {
        try {
            const countryId = '03500d2f-8cfb-4e9c-809a-2569bd4b7082';
            return await locationService.getCountriesCities(countryId, params);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getCitiesTaxOffices = createAsyncThunk(
    'getCitiesTaxOffices',
    async ({cityId, id}, { rejectWithValue }) => {
        try {
            return await locationService.getCitiesTaxOffices(cityId, {id});
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getCitiesTowns = createAsyncThunk(
    'getCitiesTowns',
    async ({cityId, id}, { rejectWithValue }) => {
        try {
            return await locationService.getCitiesTowns(cityId, {id});
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getTownsDistricts = createAsyncThunk(
    'getTownsDistricts',
    async (townId, { rejectWithValue }) => {
        try {
            return await locationService.getTownsDistricts(townId);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getDistrictsStreets = createAsyncThunk(
    'getDistrictsStreets',
    async (districtId, { rejectWithValue }) => {
        try {
            return await locationService.getDistrictsStreets(districtId);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getLocationsById = createAsyncThunk(
    'getLocationsById',
    async (locationId, { rejectWithValue }) => {
        try {
            return await locationService.getLocationsById(locationId);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getLocationsTypes = createAsyncThunk(
    'getLocationsTypes',
    async (_, { rejectWithValue }) => {
        try {
            return await locationService.getLocationsTypes();
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

const initialState = {
    countriesCities: [],
    citiesTaxOffices: [],
    citiesTowns: [],
    townsDistricts: [],
    districtsStreets: [],
    selectedAdress: [],
    isFetchingAddress: false,
    locationTypes: [],
};

export const locationSlice = createSlice({
    name: 'location',
    initialState,
    reducers: {
        setSelectedAdressfromMap:(state,action)=>{
            state.selectedAdress=action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getCountriesCities.pending, (state) => {
            state.isFetchingAddress = true;
        });
        builder.addCase(getCountriesCities.fulfilled, (state, action) => {
            state.countriesCities = action?.payload?.data?.data;
            state.isFetchingAddress = false;
        });
        builder.addCase(getCountriesCities.rejected, (state, action) => {
            state.isFetchingAddress = false;
        });
        builder.addCase(getCitiesTaxOffices.pending, (state) => {
            state.isFetchingAddress = true;
        });
        builder.addCase(getCitiesTaxOffices.fulfilled, (state, action) => {
            state.citiesTaxOffices = action?.payload?.data?.data;
            state.isFetchingAddress = false;
        });
        builder.addCase(getCitiesTaxOffices.rejected, (state) => {
            state.isFetchingAddress = false;
        });
        builder.addCase(getCitiesTowns.fulfilled, (state, action) => {
            state.citiesTowns = action?.payload?.data?.data;
        });
        builder.addCase(getTownsDistricts.fulfilled, (state, action) => {
            state.townsDistricts = action?.payload?.data?.data;
        });
        builder.addCase(getDistrictsStreets.fulfilled, (state, action) => {
            state.districtsStreets = action?.payload?.data?.data;
        });
        builder.addCase(getLocationsById.pending, (state) => {
            state.isFetchingAddress = true;
        });
        builder.addCase(getLocationsById.fulfilled, (state) => {
            state.isFetchingAddress = false;
        });
        builder.addCase(getLocationsById.rejected, (state) => {
            state.isFetchingAddress = false;
        });
        builder.addCase(getLocationsTypes.fulfilled, (state, action) => {
            state.locationTypes = action?.payload?.data?.data;
        });
    }
});

export const {setSelectedAdressfromMap} = locationSlice.actions;