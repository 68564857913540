import styled from 'styled-components';

const StyledAlert = (ComponentName) => styled(ComponentName)`
    &.ant-alert {
        padding: 12px;
        margin-bottom: 24px;

        .anticon {
            margin-inline-end: 12px;
            font-size: 20px;
        }
        .ant-alert-content > .ant-alert-message {
            font-size: 13px;
            font-weight: 500;
            color: #4C6278;
            white-space: pre-line;
        }
    }
`;

export {
    StyledAlert
};