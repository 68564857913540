import { Table } from 'antd';
import { StyledTable } from './table.styles';

const StyledEtaTable = StyledTable(Table);

const EtaTable = ({children, ...props}) => {
    return (
        <StyledEtaTable {...props} scroll={{ x: 1000 }}>
            {children}
        </StyledEtaTable>
    );
};

export default EtaTable;