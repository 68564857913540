import React from 'react';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Col, Layout, Row } from 'antd';
import { AuthWrapper, AuthWallpaper } from 'pages/auth/auth.styles';
import { Header, Message } from 'components/layout';
import { EtaImage } from 'components/ui';
import PersonType from 'core/enums/person-type';

const AuthLayout = () => {
  const { company } = useSelector(state => state.app);

  return (
    <Layout className='auth-layout'>
      <Header />
      <Layout className='auth-content'>
        <Row justify="space-around">
          <Col xs={24} sm={24} md={12}>
            <AuthWrapper>
              <Outlet />
            </AuthWrapper>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <AuthWallpaper>
                <EtaImage className="wallpaper-image" source={`wallpaper-${company?.value}.png`} objectFit="cover" width="100%" height="100%" />
                { company.key == PersonType.Supplier
                  ? <div className='wallpaper-context'>
                        <h1>ARACIN VAR <br /> YÜK MÜ <br /> ARIYORSUN?</h1>
                        <p>Binlerce iş fırsatı, <br /> Borusan Lojistik eTA platformunda <br /> seni bekliyor.</p>
                    </div>
                  : <div className='wallpaper-context'>
                        <h1>YÜKÜN VAR <br /> ARAÇ MI <br /> ARIYORSUN?</h1>
                        <p>En hızlı ve en güvenilir araç tedariği, <br /> Borusan Lojistik eTA platformunda <br /> bir tık uzağında!</p>
                    </div>
                }
            </AuthWallpaper>
          </Col>
        </Row>
        <Message />
      </Layout>
    </Layout>
  );
};

export default AuthLayout;