import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import bankService from '../../core/services/bank.service';

export const getBanks = createAsyncThunk(
    'getBanks',
    async (params, { rejectWithValue }) => {
        const data = { ...params, limit:100 };
        try {
            return await bankService.get(data);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getBranches = createAsyncThunk(
    'getBranches',
    async ({ accountId, cityId = null, data }, { rejectWithValue }) => {
        try {
            return await bankService.getBranches(accountId, cityId, data);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getEtaBankAccounts = createAsyncThunk(
    'getEtaBankAccounts',
    async (_, { rejectWithValue }) => {
        try {
            return await bankService.getEtaBankAccounts();
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

const initialState = {
    banks: [],
    branches: [],
    etaBankAccounts: [],
    isFetching: false
};

export const bankSlice = createSlice({
    name: 'bank',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getBanks.fulfilled, (state, action) => {
            state.banks = action?.payload?.data?.data;
        });
        builder.addCase(getBranches.fulfilled, (state, action) => {
            state.branches = action?.payload?.data?.data;
        });
        builder.addCase(getEtaBankAccounts.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(getEtaBankAccounts.fulfilled, (state, action) => {
            state.etaBankAccounts = action?.payload?.data?.data || [];
            state.isFetching = false;
        });
        builder.addCase(getEtaBankAccounts.rejected, (state) => {
            state.isFetching = false;
        });
    }
});
