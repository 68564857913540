import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import shipmentService from '../../core/services/shipment.service';

export const getShipmentCancelReason = createAsyncThunk(
    'getShipmentCancelReason',
    async (accountType, { rejectWithValue }) => {
        try {
            return await shipmentService.getShipmentCancelReason(accountType);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const putShipmentCancel = createAsyncThunk(
    'putShipmentCancel',
    async ({ shipmentId, data }, { rejectWithValue }) => {
        try {
            return await shipmentService.putShipmentCancel(shipmentId, data);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

const initialState = {
    shipmentCancelReasons: [],
    isCancelLoading: false,
};

export const shipmentSlice = createSlice({
    name: 'shipment',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(putShipmentCancel.pending, (state, action) => {
            state.isCancelLoading = true;
        });
        builder.addCase(getShipmentCancelReason.fulfilled, (state, action) => {
            state.shipmentCancelReasons = action?.payload?.data?.data || [];
            state.isCancelLoading = false;
        });
        builder.addCase(putShipmentCancel.rejected, (state, action) => {
            state.isCancelLoading = false;
        });
        builder.addCase(putShipmentCancel.fulfilled, (state, action) => {
            state.isCancelLoading = false;
        });
    }
});


