import styled from 'styled-components';

const StyledTextArea = (ComponentName) => styled(ComponentName)`
    &.ant-input-affix-wrapper-lg{
        .ant-input-data-count{
            font-size: 12px;
            line-height: 1.5;
        }
    }
`;

export {
    StyledTextArea
};