import styled from 'styled-components';

const StyledTable = (ComponentName) => styled(ComponentName)`
    .ant-table {
        
        .ant-table-title {
            padding: 8px 16px;
            border: 1px solid #d9d9d9;

            .ant-typography{
                font-size: 14px;
                font-weight: 600;
                margin: 0;
                color: #000;
            }
        }

        .ant-table-container {
            border-left: 1px solid #d9d9d9;
            border-right: 1px solid #d9d9d9;
            overflow: hidden;
            
            .ant-table-thead > tr > th {
                white-space: nowrap;
                color: #4c6278;
            }

            .ant-table-tbody > tr:last-child > td{
                border-bottom: none;
            }

            &:first-child {
                border-top: 1px solid #d9d9d9;
            }

            &:last-child {
                border-bottom: 1px solid #d9d9d9;
                border-end-start-radius: 8px;
                border-end-end-radius: 8px;
            }
        }

        .ant-table-footer{
            overflow: hidden;
            border: 1px solid #d9d9d9;
        }
        
        .ant-table-cell {
            &.ant-table-selection-column{
                padding-inline-start: 16px;
                padding-inline-end: 16px;
            }

        }

        .ant-table-expanded-row{
            .ant-table-container {
                border-color: #f0f0f0;
            }
            .ant-table-wrapper {
                border-radius: 0;
            }
        }

        .information {
            display: flex;
            flex-direction: column;
            gap: 5px;
            
            .buttons {
                display: flex;
                flex-direction: row;
                gap: 5px;
            }
            .text { 
                .name {
                    font-size: 12px;
                    font-weight: 600;

                    &.orange{
                        color: #f46424;
                    }
                    &.blue{
                        color: #1890FF;
                    }

                }                
                .date {
                    font-size: 11px;
                    font-weight: 400;
                    line-height: 18px;
                    white-space: nowrap;

                    &.link{
                        color: #1890FF;
                    }
                }
            }
         
        }

        .status {
            font-size: 12px;
            font-weight: 400;
            
            .route {
                display: flex;
                align-items: center;
                margin-top: 10px;
                line-height: 16px;
                color: #1890FF;

                .anticon {
                    font-size: 11px;
                    margin: 0 6px;
                }
            }
            .date {
                display: flex;
                align-items: center;
                margin-top: 2px;
                line-height: 20px;
                white-space: nowrap;
            }
        }

        .price{
            display: flex;
            flex-direction: column;

            strong{
                font-weight: 500;
            }  
            small{
                font-size: 11px;
                color: #aaa;
            }
        }

        .line-clamp{
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;

            &.line-clamp-2{
                -webkit-line-clamp: 2;
            }
            &.line-clamp-3{
                -webkit-line-clamp: 3;
            }
            &.line-clamp-4{
                -webkit-line-clamp: 4;
            }
        }

        .ant-typography{
            &.ant-typography-warning{
                color: #f46424;
            }
        }
    }

    .ant-pagination {
        .ant-pagination-item {
            border-radius: 2px;
            border-color: #D9D9D9;

            &:hover {
                border-color: #f26b51;
                background-color: #fff;
            }
            a {
                font-size: 14px;
                font-weight: 400;
                color: #000;
            }
        }
        .ant-pagination-item-active {
            border-color: #1890FF;
            a {
                color: #1890FF;
            }
        }
        .ant-pagination-prev,
        .ant-pagination-next {
            border-radius: 2px;
            .ant-pagination-item-link {
                border-radius: 2px;
                border-color: #D9D9D9;
            }
        }
        .ant-pagination-options > .ant-select > .ant-select-selector {
            border-radius: 2px;
        }
    }

    &.ant-table-with-header{
        overflow: hidden;
        border-radius: 8px;

        .ant-table-title{
            padding: 0;

            .ant-space-header {
                display: flex;
                justify-content: space-between;
                padding: 8px 16px;  
                border-bottom: 1px solid #d9d9d9;
            }

            .ant-tabs{
                .ant-tabs-nav{
                    padding-left: 16px;
                    padding-right: 16px;
                }
            }
        }
    }

    &.ant-table-expandable{
        .ant-table-title{
            padding: 8px 16px;
        }

        .ant-table-row{
            cursor: pointer;
        }

        .ant-table-row-level-0 { 
            .ant-table-cell ,
            .ant-table-cell-row-hover{
                font-weight: 500;
                background-color: rgb(241, 245, 249) !important;
            }
                
            .ant-table-cell-with-append{
                position: unset;
            
                .anticon,
                .ant-table-row-expand-icon {
                    position: absolute;
                    right: 16px;
                    font-size: 10px;
                    padding: 4px;
                    z-index: 2;
                }
            }
        }
    }

    &.ant-table-wrap-header th.ant-table-cell{
        white-space: unset !important;
        vertical-align: middle;
    }
`;

export {
    StyledTable
};
