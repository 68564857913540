import styled from 'styled-components';
import { mediaResponseMax } from 'styles/mixins';

const AuthWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 100px auto 50px;
    padding: 20px;
    max-width: 400px;

    ${mediaResponseMax.sm`
        margin-top: 50px;
    `} 

    h1 {
        font-size: 32px;
        font-weight: 600;
        margin-bottom: 8px;
        color: #000000;
        ${mediaResponseMax.xs`
            font-size: 24px;
            font-weight: 700;
        `}
    }

    h2 {
        font-size: 14px;
        font-weight: 400;
        margin: 0 0 28px;
        color: #000000;
        ${mediaResponseMax.xs`
            font-size: 14px;
            font-weight: 400;
        `};

        .link{
            color: #f46424;
            text-decoration: underline;
        }
    }

    .login-form-forget {
        font-size: 13px;
        font-weight: 600;
        color: #F46424;
        padding-bottom: 4px;
        border-bottom: 1px solid #F46424;
        ${mediaResponseMax.sm`
            margin: unset;
        `};
    }

    .link{
        color: #f46424;
        text-decoration: underline;
    }
    .checkbox-item{
        .ant-checkbox{
            margin-bottom: auto;
            margin-top: 4px;
        }
        & > span {
            font-size: 12px;
            font-weight: 500;
            color: #4C6278;

            & > span {
                font-size: 12px;
                font-weight: 400;
                color: #F46424;
                text-decoration: underline;
                cursor:pointer;
            }
        }
    }
`;

const AuthRegister = styled.div`
    max-width: 560px;
`;

const RegisterDescription = styled.div`
  margin-bottom: 20px;

  span {
    font-size: 24px;
    font-weight: 600;

    svg {
      color: #f46424;
      font-size: 32px;
      margin-right: 10px;
    }
  }

  p {
    margin-top: 10px;
    font-size: 11px;
    text-align: justify;
    letter-spacing: 0.5px;
  }
`;

const RegisterActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;

  a {
    color: #666666;
    text-decoration: underline;
    font-size: 10px;
    font-weight: 500;
  }
`;

const AuthWallpaper = styled.div`
    position: sticky;
    padding: 20px;
    border-radius: 0;
    top: 64px;
    height: calc(100vh - 64px);

    .wallpaper-image{
        border-radius: 150px 50px 50px;
        overflow: hidden;

        ${mediaResponseMax.sm`
            border-radius: 40px;
        `}

        ${mediaResponseMax.xs`
            border-radius: 30px;
        `}
    }

    .wallpaper-context {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: calc(50% - 160px);
        right: 120px;
        left: 120px;

        ${mediaResponseMax.md`
            top: calc(50% - 150px);
            right: 100px;
            left: 100px;
        `}

        ${mediaResponseMax.sm`
            top: calc(50% - 110px);
            right: 80px;
            left: 80px;
        `}
        ${mediaResponseMax.xs`
            top: calc(50% - 80px);
            right: 60px;
            left: 60px;
        `}

        h1{
            font-size: 52px;
            line-height: 1.2;
            font-weight: 800;
            color: #FFFFFF;
            white-space: pre-line;
            margin-top: 0;
            
            ${mediaResponseMax.md`
                font-size: 48px;
            `}
            ${mediaResponseMax.sm`
                font-size: 36px;
            `}
            ${mediaResponseMax.xs`
                font-size: 24px;
            `}
        }

        p{
            font-size: 16px;
            font-weight: 400;
            color: #fff;
            white-space: pre-line;
            padding-left: 14px;
            margin-bottom: 0;
            border-left: 2px solid #F46424;

            ${mediaResponseMax.md`
                font-size: 14px;
            `}
            ${mediaResponseMax.xs`
                font-size: 12px;
            `}
        }
    }
`;

export {
    AuthWrapper,
    RegisterDescription,
    AuthRegister,
    AuthWallpaper,
    RegisterActionWrapper
};