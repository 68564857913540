import { Layout } from 'antd';
import styled from 'styled-components';
import { mediaResponseMax } from 'styles/mixins';

const { Sider } = Layout;

const SiderWrapper = styled(Sider)`
    top: 64px;
    bottom: 0;
    left: 0;
    position: fixed !important;
    background-color: #0a2647 !important;
    z-index: 9;

    &.ant-layout-sider-has-trigger{
        padding-bottom: 0;
    }
    
    &.ant-layout-sider-collapsed {
        ${mediaResponseMax.md`
            min-width: 0px !important;
            max-width: 0px !important;
            width: 0px !important;
        
            .ant-layout-sider-children {
                display: none;
            }
        `};
    }

    .ant-layout-sider-trigger {
        position: absolute;
        width: 28px !important;
        height: 28px;
        background-color: #fff;
        border-radius: 50%;
        box-shadow: 0px 0px 8px 0px #00000026;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 13px;
        right: -14px;
        cursor: pointer;
        transition: all 0.3s ease;

        .anticon {
            font-size: 12px;
            color: #1890ff;
        }

        ${mediaResponseMax.md`
            display: none;
        `};
    }

    .ant-layout-sider-children {
        position: relative;
        overflow-y: scroll;
        padding: 16px 2px 16px 12px;

        ::-webkit-scrollbar {
            width: 10px;
        }
        
        ::-webkit-scrollbar-track {
            border-radius: 10px;
        }
        
        ::-webkit-scrollbar-thumb {
            border-radius: 10px;
            border: solid 2px #0a2647;
            background: rgba(255,255,255,.2); 
        }
        ::-webkit-scrollbar-thumb:hover {
            background: rgba(255,255,255,.4); 
        }
    }


    .ant-menu {

        .ant-menu-item-group {
            &:not(:last-child) {
                margin-bottom: 15px;
            }
        }

        .ant-menu-item-group-title {
            font-size: 12px;
            font-weight: 400;
            color: #94a1ae;
            text-transform: uppercase;
        }

        .ant-menu-sub {
            margin-left: 40px;
            
            .ant-menu-item {
                width: auto !important;

                .ant-menu-title-content {
                    flex: unset !important;
                }

                &:first-child {
                    margin-top: 12px;
                }

                &:last-child {
                    margin-bottom: 20px;
                }
            }

            .ant-menu-item-selected {
                background-color: transparent !important;

                &::before {
                    visibility: visible;
                }

                a {
                    color: #fff !important;
                }
            }

            li {
                position: relative;
                height: auto !important;

                a {
                    color: #94a1ae;
                }

                &:hover {
                    background-color: transparent !important;
                }

                &::before {
                    position: absolute;
                    content: "";
                    width: 1px;
                    height: 16px;
                    background-color: #fff;
                    left: 0px;
                    visibility: hidden;
                }
            }
        }

        .ant-menu-submenu-title,
        .ant-menu-item {
            margin: unset;
            width: 100%;
            color: #fff;
            font-size: 13px;
            font-weight: 400;
            padding-left: 12px !important;

            &:hover {
                background-color: rgba(255, 255, 255, 0.1) !important;
                color: #fff !important;
            }

            & > img,
            .ant-menu-item-icon {
                margin-right: 12px;
            }

            .ant-menu-title-content {
                display: flex;
                align-items: center;
                margin-inline-start: 0px !important;
            }

        }

        .ant-menu-item-selected {
            background-color: rgba(255, 255, 255, 0.1) !important;
            color: #fff;
        }

        .ant-menu-submenu-open > .ant-menu-submenu-title {
            background-color: rgba(255, 255, 255, 0.1);
        }

        .ant-menu-item-selected::after {
            border-right: none;
        }

        .menu-last-child {
            box-shadow: none;
        }

        .anticon {
            color: #fff;
            font-size: 20px !important;
            margin-right: 12px;
        }

        &.ant-menu-dark, 
        &.ant-menu-dark>.ant-menu {
            background: transparent;
        }


        &.ant-menu-dark.ant-menu-inline .ant-menu-sub.ant-menu-inline, 
        &.ant-menu-dark>.ant-menu.ant-menu-inline .ant-menu-sub.ant-menu-inline{
            background: transparent;
        }
    }

    .ant-menu-submenu-popup > .ant-menu-sub > .ant-menu-item > .ant-menu-title-content > a {
        font-size: 14px;
        font-weight: 500;
    }

    .ant-menu-inline-collapsed {
        > .ant-menu-item-group
        > .ant-menu-item-group-title {
            display: none;
        }

        .ant-menu-submenu > .ant-menu-submenu-title,
        .ant-menu-item {
            padding: unset !important;
            display: flex;
            align-items: center;
            justify-content: center;

            .ant-menu-submenu-arrow,
            .ant-menu-title-content {
                display: none !important;
            }
        }
    }

  
`;


export { SiderWrapper };