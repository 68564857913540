import dataApi from '../apis/data.api';

const login = async (data) => {
    return await dataApi.post('auth/login', data);
};

const refreshToken = async (data) => {
    return await dataApi.post('auth/refresh-token', data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

const postResetPassword = async (phoneNumber) => {
    return await dataApi.post(`auth/reset-password/${phoneNumber}`);
};

const putResetPassword = async (data) => {
    return await dataApi.put('auth/reset-password', data);
};

const authService = {
    login,
    refreshToken,
    postResetPassword,
    putResetPassword,
};

export default authService;