import dataApi from '../apis/data.api';

const getCountriesCities = async (countryId, params) => {
  return dataApi.get(`locations/countries/${countryId}/cities`, { params });
};

const getCitiesTaxOffices = async (cityId, params) => {
  return dataApi.get(`locations/cities/${cityId}/tax-offices`, { params });
};

const getCitiesTowns = async (cityId, params) => {
  return dataApi.get(`locations/cities/${cityId}/towns`, { params });
};

const getTownsDistricts = async (townId) => {
  return dataApi.get(`locations/towns/${townId}/districts`);
};

const getDistrictsStreets = async (districtId) => {
  return dataApi.get(`locations/districts/${districtId}/streets`);
};

const getLocationsById = async (locationId) => {
  return dataApi.get(`locations/${locationId}`);
};

const getLocationsTypes = async () => {
    return dataApi.get('locations/types');
};

const locationService = {
  getCountriesCities,
  getCitiesTaxOffices,
  getCitiesTowns,
  getTownsDistricts,
  getDistrictsStreets,
  getLocationsById,
  getLocationsTypes
};

export default locationService;
