import dataApi, { getQuery } from '../apis/data.api';

const get = async () => {
  return dataApi.get('supplier');
};

const post = async (data) => {
  return dataApi.post('suppliers', data);
};

const put = async (data) => {
  return dataApi.put('suppliers', data);
};

const postAuctionsBid = async (auctionId, data) => {
  return dataApi.post(`supplier/auctions/${auctionId}/bids`, data);
};

const getBids = async (params) => {
  return getQuery('supplier/auctions/bids', params);
};

const deleteAuctionBid = async (auctionBidId) => {
  return dataApi.delete(`supplier/auctions/bids/${auctionBidId}`);
};

const getShipments = async (params) => {
  return getQuery('supplier/shipments', params);
};

const getShipmentById = (shipmentId) => {
  return getQuery(`supplier/shipments/${shipmentId}`);
};

const getPayments = async (params) => {
  return getQuery('supplier/payments', params);
};

const getShipmentCount = async (params) => {
  return getQuery('supplier/shipments/count', params);
};

const getProcuredShipmentsCount = async (params) => {
  return getQuery('supplier/procured-shipments/count', params);
};

const getNotificationEmails = async () => {
  return dataApi.get('supplier/notification-emails');
};

const putNotificationEmails = async (data) => {
  return dataApi.put('supplier/notification-emails', data);
};

const postLoadDemands = async (data) => {
  return dataApi.post('supplier/shipments/load-demands', data);
};

const postAuctionsAssignment = async (auctionBidId) => {
  return dataApi.post(`supplier/auctions/${auctionBidId}/assignment`);
};

const deleteAuctionsAssignment = async (auctionBidId) => {
  return dataApi.delete(`supplier/auctions/${auctionBidId}/assignment`);
};

const postTaxNumbersJoinDemands = async (taxNumber) => {
  return dataApi.post(`supplier/tax-numbers/${taxNumber}/join-demands`);
};

const getTrailers = async (params) => {
  return getQuery('supplier/trailers', params);
};

const getTrailerById = (trailerId) => {
  return getQuery(`supplier/trailers/${trailerId}`);
};

const getQuoteBidsTransactions = async (params) => {
  return getQuery('supplier/quote-bids-transactions', params);
};

const supplierService = {
  get,
  post,
  put,
  postAuctionsBid,
  getBids,
  deleteAuctionBid,
  getShipments,
  getShipmentById,
  getPayments,
  getShipmentCount,
  getProcuredShipmentsCount,
  getNotificationEmails,
  putNotificationEmails,
  postLoadDemands,
  postAuctionsAssignment,
  deleteAuctionsAssignment,
  postTaxNumbersJoinDemands,
  getTrailers,
  getTrailerById,
  getQuoteBidsTransactions
};

export default supplierService;
