import { Cookies } from 'react-cookie';
import { jwtDecode } from 'jwt-decode';

let cookie = new Cookies();

const decodeToken = (name) => {
    let token = cookie.get(name, { path: '/' });
    return token ? jwtDecode(token) : {};
};

const hasAnyRole = (values) => {
    let { role } = decodeToken('accessToken');
    return values.some((s => role?.includes(s)));
};

const setToken = (token, suffix = '') => {
    let date = new Date();
    let expireDate = new Date(date.getTime() + (token.expiresIn * 1000));
    cookie.set(`accessToken${suffix}`, token.accessToken, {expires: expireDate, path: '/'});
    if(token.refreshToken) {
        cookie.set(`refreshToken${suffix}`, token.refreshToken, {path: '/'});
    }   
};

export {
    decodeToken,
    hasAnyRole,
    setToken
};
