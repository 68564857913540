import styled from 'styled-components';

const StepsStyled = (ComponentName) => styled(ComponentName)`
    .ant-steps-item-icon {
        .ant-steps-icon {
            font-size: 16px;
            font-weight: 600;
        }  
    }
  
    .ant-steps-item-title {
        font-size: 14px;
        font-weight: 500;
    }

    .ant-steps-item-description {
        display: -webkit-box;
        font-size: 11px;
        font-weight: 400;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;  
        overflow: hidden;
    }

    .ant-steps-item-wait,
    .ant-steps-item-process {
        .ant-steps-item-container {
            .ant-steps-item-icon {
                .ant-steps-icon {
                    color: #94a1ae;
                }
            }
            .ant-steps-item-content{
                .ant-steps-item-title {
                    color: #94a1ae;

                    &::after {
                        background-color: #94a1ae;
                    }
                }
                .ant-steps-item-description {
                    color: #94a1ae;
                }
            }
        }
    }

    .ant-steps-item-active {
        .ant-steps-item-container {
            .ant-steps-item-icon {
                background-color: #1890ff;
                border-color: #1890ff;

                .ant-steps-icon {
                    color: #fff;
                }
            }
            .ant-steps-item-content {
                .ant-steps-item-title {
                    color: #1890ff;

                    &::after {
                        background-color: #1890ff;
                    }
                }
                
                .ant-steps-item-description {
                    color: #4c6278;
                }
            }
        }
    }

    .ant-steps-item-finish {
        .ant-steps-item-container{
            .ant-steps-item-icon {
                background-color: #ffffff;
                border-color: #1890FF;

                .anticon {
                    color: #1890FF;
                }
            }
            .ant-steps-item-content {
                .ant-steps-item-title {
                    color: #1890ff;
                    
                    &::after {
                        background-color: #1890ff;
                    }
                }
                .ant-steps-item-description {
                    color: #4c6278;
                }
            }
        }
    }

    &.ant-steps-quote-create{
        padding-bottom: 16px;
        margin-bottom: 16px;
        border-bottom: 2px solid #eee;

        &.ant-steps-vertical{
            flex-direction: row;
            align-items: center;
            justify-content: center;

            .ant-steps-item{
                flex: none;

                .ant-steps-item-content{
                    display: none;
                }
            }
        
        }

        &.ant-steps-horizontal{
            &:not(.ant-steps-label-vertical){
                .ant-steps-item-description{
                    max-width: 240px !important;
                }
            }
        }
    }

    &.ant-steps-cart{  
        position: sticky;
        top: 64px;
        left: 0;
        right: 0;
        padding: 16px 32px;
        margin-bottom: 16px;
        margin-top: -32px;
        margin-left: -32px;
        width: calc(100% + 64px);
        background-color: #f5f5f5;
        border-bottom: 2px solid #eee;
        z-index: 3;

        .ant-steps-item-wait,
        .ant-steps-item-process {
            .ant-steps-item-container {
                .ant-steps-item-icon {
                    border-color: #94a1ae;
                
                }
            }
        }

        .ant-steps-item-finish {
            .ant-steps-item-container {
                .ant-steps-item-icon {
                    border-color: #1890ff;

                    .ant-steps-icon{
                       .ant-steps-icon-dot{
                            background: #94a1ae;
                       }
                    }
                }
            }
        }

        .ant-steps-item-active {
            .ant-steps-item-container {
                .ant-steps-item-icon {
                    border-color: #1890ff;

                    .ant-steps-icon{
                       .ant-steps-icon-dot{
                            background: #1890ff;
                       }
                    }
                }
            }
        }
        
        &.ant-steps-inline{
            padding: 12px;
            margin-top: -12px;
            margin-left: -12px;
            width: calc(100% + 24px);

            .ant-steps-item-container {
                .ant-steps-item-content {
                    .ant-steps-item-title {
                        color: #000;
                    }
                }
            }

            &.ant-steps-dot{
                .ant-steps-item{
                    flex: 1;
                }
            }
            
            .ant-steps-item-container:focus,
            .ant-steps-item-container:hover{
                background: none;
            }
        }
    }

    &.ant-steps-cart-detail{
        .ant-steps-item-wait,
        .ant-steps-item-process {
              .ant-steps-item-container {
                
                  .ant-steps-item-icon {
                    .ant-steps-icon-dot{
                        background: #ed502d ;
                    }
                }
                 .ant-steps-item-content {
                     .ant-steps-item-title{
                        font-size: 14px;
                        font-weight: 600;
                        color: #ed502d;
                    }

                     .ant-steps-item-description {
                        font-size: 12px;
                        color: #4c6278;
                        
                        .steps-detail-sub-title{
                            margin-bottom: 8px;
                        }
                    }
                }
            }
        }
    } 
`;

export {
    StepsStyled
};