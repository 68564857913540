import styled from 'styled-components';

const StyledTimePicker = (ComponentName) => styled(ComponentName)`
    display: flex;
    flex-direction: column;
    .ant-picker {
        border-radius: 4px;
        .ant-picker-input > input {
            font-size: 14px;
            font-weight: 500;
            color: #000;
        }
    }
    .ant-picker-large {
        padding: 10px 15px;
    }
    .ant-picker-focused {
        box-shadow: unset;
    }
`;

export { StyledTimePicker };