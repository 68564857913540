import { EtaImage } from 'components/ui';
import {
    BellOutlined,
    CreditCardOutlined,
    HeartOutlined,
    UserOutlined,
    NotificationOutlined,
    CarOutlined,
    HourglassOutlined,
    QuestionCircleOutlined,
    AppstoreOutlined
} from '@ant-design/icons';
import { decodeToken, hasAnyRole } from 'core/utils/helpers/converter';
import { RoutePaths } from 'router/paths';
import { shownLastLocation } from 'constants/customer';

export const menuList = (company, intro) => {
    const { accountId } = decodeToken('accessToken');
    const isShowPointMenu = !intro?.accountIntro?.isCustomerContracted && intro?.accountIntro?.isPointGainRegistered;

    const menu = [
        {
            key: 'customer',
            label: 'Ana Menü',
            children: [
                {
                    key: 'account',
                    label: 'Hesabım',
                    icon: <UserOutlined />,
                    children: [
                        {
                            key: 'profile',
                            label: 'Kullanıcı Profilim',
                            path: RoutePaths.ACCOUNT.PROFILE,
                        },
                        hasAnyRole(['CustomerAdmin', 'CustomerPersonel']) ?
                            {
                                key: 'company',
                                label: 'Firma Profilim',
                                path: RoutePaths.CUSTOMER.ACCOUNT.COMPANY,
                            } :
                            {
                                key: 'join-demands',
                                label: 'Firma Profilim',
                                path: accountId ? RoutePaths.ACCOUNT.JOIN_DEMANDS : RoutePaths.ACCOUNT.REGISTER_TO_COMPANY,
                            }
                        ,
                        {
                            key: 'agreements',
                            label: 'Sözleşmelerim',
                            path: RoutePaths.ACCOUNT.AGREEMENTS,
                        },
                        {
                            key: 'address-book',
                            label: 'Adres Defterim',
                            path: RoutePaths.CUSTOMER.ACCOUNT.ADDRESS_BOOK,
                        },
                        {
                            key: 'store-cards',
                            label: 'Kayıtlı Kartlarım',
                            path: RoutePaths.CUSTOMER.ACCOUNT.STORE_CARDS,
                            disabled: !hasAnyRole(['CustomerAdmin', 'CustomerPersonel'])
                        },
                        // {
                        //     key: 'discount-vouchers',
                        //     label: 'İndirim Çeklerim',
                        //     active: false,
                        //     path: '/musteri/hesap/indirim-ceklerim',
                        //     disabled: !hasAnyRole(['CustomerAdmin', 'CustomerPersonel'])
                        // },
                        {
                            key: 'invoicing-choices',
                            label: 'Faturalama Tercihlerim',
                            path: RoutePaths.CUSTOMER.ACCOUNT.INVOICING_CHOICES,
                            disabled: !hasAnyRole(['CustomerAdmin'])
                        },
                        {
                            key: 'users',
                            label: 'Kullanıcılarım',
                            path: RoutePaths.CUSTOMER.ACCOUNT.USERS,
                            disabled: !hasAnyRole(['CustomerAdmin'])
                        },
                    ]
                },
                {
                    key: 'quotes',
                    label: 'Araç Taleplerim',
                    icon: <EtaImage source="truck-xsmall.svg" width={20} height={20} />,
                    active: false,
                    path: RoutePaths.CUSTOMER.QUOTES,
                    children: []
                },
                intro?.accountIntro?.isCustomerContracted &&
                {
                    key: 'bulk-demand',
                    label: 'Toplu Taleplerim',
                    icon: <AppstoreOutlined />,
                    active: false,
                    path: RoutePaths.CUSTOMER.BULK_DEMAND,
                    children: []
                },
                {
                    key: 'templates',
                    label: 'Favorilerim',
                    icon: <HeartOutlined />,
                    active: false,
                    path: RoutePaths.CUSTOMER.FAVORITES,
                    children: []
                },
                {
                    key: 'shipments',
                    label: 'Seferlerim',
                    icon: <EtaImage source="route.svg" width={20} height={20} />,
                    active: false,
                    path: RoutePaths.CUSTOMER.SHIPMENTS,
                    disabled: !hasAnyRole(['CustomerAdmin', 'CustomerPersonel']),
                    children: []
                },
                /*{
                    key: 'pending-approval-process',
                    label: 'Onay Bekleyen İşlemlerim',
                    icon: <EtaImage source="pending-approval-process.svg" width={20} height={20} />,
                    active: false,
                    children: [
                        {
                            key: 'escalation-approval',
                            label: 'Eskalasyon Onayı',
                            path: RoutePaths.PENDING_APPROVAL_PROCESS.ESCALATION_APPROVAL,
                        },
                        {
                            key: 'contract-approval',
                            label: 'Sözleşme Onayı',
                            path: RoutePaths.PENDING_APPROVAL_PROCESS.CONTRACT_APPROVAL,
                        },
                        {
                            key: 'invoice-approval',
                            label: 'Fatura Onayı',
                            path: RoutePaths.PENDING_APPROVAL_PROCESS.INVOICE_APPROVAL,
                        },
                    ]
                },*/
                isShowPointMenu &&
                {
                    key: 'advantage-wallet',
                    label: 'Eta Puan',
                    icon: <EtaImage source="medal-2.svg" width={20} height={20} />,
                    active: false,
                    path: RoutePaths.CUSTOMER.ADVANTAGE_WALLET,
                    children: [],
                    disabled: !hasAnyRole(['CustomerAdmin', 'CustomerPersonel'])
                },
                {
                    key: 'invoices',
                    label: 'Faturalarım',
                    icon: <EtaImage source="audit-outlined.svg" width={20} height={20} />,
                    active: false,
                    path: RoutePaths.CUSTOMER.INVOICES,
                    children: [],
                    disabled: !hasAnyRole(['CustomerAdmin']),
                },
                {
                    key: 'balance-and-payments',
                    label: 'Bakiye ve Ödemelerim',
                    icon: <CreditCardOutlined />,
                    active: false,
                    path: RoutePaths.CUSTOMER.BALANCE_AND_PAYMENTS,
                    children: [],
                    disabled: !hasAnyRole(['CustomerAdmin'])
                },
                shownLastLocation.includes(intro?.accountIntro?.accountId) &&
                {
                    key: 'trackings',
                    label: 'Dorse Takip',
                    icon: <CarOutlined />,
                    path: RoutePaths.CUSTOMER.LOCATION_TRACKING,
                    children: [],
                }
            ]
        },
        {
            key: 'supplier',
            label: 'Ana Menü',
            children: [
                {
                    key: 'account',
                    label: 'Hesabım',
                    icon: <UserOutlined />,
                    children: [
                        {
                            key: 'profile',
                            label: 'Kullanıcı Profilim',
                            path: RoutePaths.ACCOUNT.PROFILE,
                        },
                        
                        hasAnyRole(['SupplierAdmin', 'Trucker']) ?
                            {
                                key: 'company',
                                label: 'Firma Profilim',
                                path: RoutePaths.SUPPLIER.ACCOUNT.COMPANY,
                            } :
                            {
                                key: 'join-demands',
                                label: 'Firma Profilim',
                                path: accountId ? RoutePaths.ACCOUNT.JOIN_DEMANDS : RoutePaths.ACCOUNT.REGISTER_TO_COMPANY,
                            }
                        ,
                        {
                            key: 'agreements',
                            label: 'Sözleşmelerim',
                            path: RoutePaths.ACCOUNT.AGREEMENTS,
                        },
                        {
                            key: 'users',
                            label: 'Kullanıcılarım',
                            path: RoutePaths.SUPPLIER.ACCOUNT.USERS,
                            disabled: !hasAnyRole(['SupplierAdmin'])
                        }
                    ]
                },
                {
                    key: 'auctions',
                    label: 'İhaleler',
                    icon: <NotificationOutlined />,
                    path: RoutePaths.SUPPLIER.AUCTIONS,
                    children: [],
                    disabled: !hasAnyRole(['SupplierAdmin', 'Trucker'])
                },
                {
                    key: 'bids',
                    label: 'Teklifler',
                    icon: <HourglassOutlined />,
                    disabled: !hasAnyRole(['SupplierAdmin', 'Trucker']),
                    children: [
                        {
                            key: 'auction-bids',
                            label: 'Mevcut Teklifler',
                            path: RoutePaths.SUPPLIER.AUCTION_BIDS,
                        },
                        {
                            key: 'quote-bids',
                            label: 'SMS Teklifleri',
                            path: RoutePaths.SUPPLIER.QUOTE_BIDS,
                            disabled: !hasAnyRole(['SupplierAdmin']),
                        }
                    ],
                },
                {
                    key: 'shipments',
                    label: 'Seferlerim',
                    icon: <EtaImage source="route.svg" width={20} height={20} />,
                    path: RoutePaths.SUPPLIER.SHIPMENTS,
                    children: [],
                    disabled: !hasAnyRole(['SupplierAdmin', 'Trucker'])
                },
                {
                    key: 'vehicles',
                    label: 'Araçlarım',
                    icon: <CarOutlined />,
                    path: RoutePaths.SUPPLIER.VEHICLES,
                    children: [],
                    disabled: !hasAnyRole(['SupplierAdmin', 'Trucker'])
                },
                {
                    key: 'invoices',
                    label: 'Faturalarım',
                    icon: <EtaImage source="audit-outlined.svg" width={20} height={20} />,
                    path: RoutePaths.SUPPLIER.INVOICES,
                    children: [],
                    disabled: !hasAnyRole(['SupplierAdmin'])
                },
                {
                    key: 'payments',
                    label: 'Ödemelerim',
                    icon: <CreditCardOutlined />,
                    path: RoutePaths.SUPPLIER.PAYMENTS,
                    children: [],
                    disabled: !hasAnyRole(['SupplierAdmin'])
                }
            ]
        },
    ];

    const otherMenu = [
        {
            key: company?.value,
            label: 'Diğer',
            children: [
                {
                    key: 'notification',
                    label: 'Bildirimlerim',
                    icon: <BellOutlined />,
                    active: false,
                    path: RoutePaths.NOTIFICATIONS,
                    children: []
                },
                company.value === 'customer' &&
                {
                    key: 'how-eta-works',
                    label: 'eTA Nasıl Çalışır?',
                    icon: <QuestionCircleOutlined/>,
                    active: false,
                    children: [
                        {
                            key: 'process-guide',
                            label: 'İşlem Rehberi',
                            path: RoutePaths.CUSTOMER.HOW_DOES_ETA_WORK.PROCESS_GUIDE,
                        },
                    ]
                },
                {
                    key: 'logout',
                    label: 'Çıkış Yap',
                    icon: <EtaImage source="sign-out-light.svg" width={20} height={20} />,
                    active: false,
                    path: '/logout',
                    children: []
                }
            ]
        }
    ];

    const getFilteredMenu = (menu, company) => {
        const removedMenuFalseItems = menu?.map((item) => {
            if (item.children) {
                const filteredChildren = item.children.filter((child) => child);
                return { ...item, children: filteredChildren };
            }
            return item;
        });

        return removedMenuFalseItems.filter(f => f.key === company.value)?.map(m => m.children)[0];
    };

    return [
        {
            key: 'favorites',
            label: 'Sık Kullanılanlar',
            children: [
                company.value === 'customer'
                    ? {
                        key: 'quote-create',
                        label: 'Araç Talep Et',
                        icon: <EtaImage source="truck-small.svg" width={20} height={14} />,
                        active: false,
                        path: RoutePaths.CUSTOMER.QUOTE_CREATE.VEHICLE,
                        children: [],
                    }
                    : {
                        key: 'load-demands',
                        label: 'Yük İste',
                        icon: <EtaImage source="truck-small.svg" width={20} height={14} />,
                        active: false,
                        path: RoutePaths.SUPPLIER.LOAD_DEMANDS,
                        disabled: !hasAnyRole(['SupplierAdmin', 'Trucker']),
                        children: [],
                    },
                {
                    key: company.value,
                    label: 'Ana Sayfa',
                    icon: <EtaImage source="dashboard.svg" width={20} height={20} />,
                    active: false,
                    path: company.path,
                    children: [],
                },
            ]
        },
        {
            key: 'main-menu',
            label: 'Ana Menü',
            children: getFilteredMenu(menu, company)
        },
        {
            key: 'other',
            label: 'Diğer',
            children: getFilteredMenu(otherMenu, company)
        }
    ];
};
