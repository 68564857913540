import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useHubs } from 'core/hubs/provider';
import { RoutePaths } from 'router/paths';
import { useAuth } from 'hooks/auth/use-auth';

const PersonHub = () => {
    const navigate = useNavigate();
    const { onLogout } = useAuth();
    const { hubStart, hubConnection, hubDispose } = useHubs();
    const { isAuth } = useSelector(state => state.auth);

    useEffect(() => {
        if (isAuth) {
            hubStart('persons');

            const connection = hubConnection('persons');
            if (connection) {
                connection.on('NotifyMessageReceived', message => {
                    if (message.types.includes(1) || message.types.includes(2)) {
                        return navigate(RoutePaths.REFRESH_AUTHORIZED);
                    } else if (message.types.includes(3)) {
                        return navigate(RoutePaths.AUTH.LOGIN_BLOCKED);
                    } else {
                        return onLogout();
                    }
                });
                
            }
        }
        else{
            hubDispose('persons');
        }
    }, [isAuth]);
};

export default PersonHub;