import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import vehicleService from '../../core/services/vehicle.service';

export const postVehicles = createAsyncThunk(
    'postVehicles',
    async (data, { rejectWithValue }) => {
        try {
            return await vehicleService.postVehicles(data);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const putVehicles = createAsyncThunk(
    'putVehicles',
    async ({ vehicleId, data }, { rejectWithValue }) => {
        try {
            return await vehicleService.putVehicles(vehicleId, data);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);
export const getWithVehicleId = createAsyncThunk(
    'getWithVehicleId',
    async (vehicleId, { rejectWithValue }) => {
        try {
            return await vehicleService.getWithVehicleId(vehicleId);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getStatuses = createAsyncThunk(
    'getStatuses',
    async (_, { rejectWithValue }) => {
        try {
            return await vehicleService.getStatuses();
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getTypes = createAsyncThunk(
    'getTypes',
    async (_, { rejectWithValue }) => {
        try {
            return await vehicleService.getTypes();
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getTypesWithKey = createAsyncThunk(
    'getTypesWithKey',
    async (key, { rejectWithValue }) => {
        try {
            return await vehicleService.getTypesWithKey(key);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getDetailedTypes = createAsyncThunk(
    'getDetailedTypes',
    async (_, { rejectWithValue }) => {
        try {
            return await vehicleService.getDetailedTypes({ isQuotable: true });
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getVehicleBrands = createAsyncThunk(
    'getVehicleBrands',
    async (_, { rejectWithValue }) => {
        try {
            return await vehicleService.getVehicleBrands();
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getVehicleLastLocation = createAsyncThunk(
    'getVehicleLastLocation',
    async (plateNumber, { rejectWithValue }) => {
        try {
            return await vehicleService.getVehicleLastLocation(plateNumber);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

const initialState = {
    vehicle: [],
    statuses: [],
    vehicleTypes: [],
    typesWithKey: [],
    selectedVehicle: [],
    detailedVehicleTypes: [],
    isVehicleTypesLoading: false,
    currentStep: 0,
    activeKey: '',
    editVehicleAddressId: false,
    draftId: false,
    selected: false,
    wayPoints: {},
    vehicleBrandList: [],
    vehicleLastLocation: {}
};

export const vehicleSlice = createSlice({
    name: 'vehicle',
    initialState,
    reducers: {
        setSelectedVehicle: (state, action) => {
            state.selectedVehicle = action.payload;
        },
        setCurrentStep: (state, action) => {
            state.currentStep = action.payload;
        },
        setActiveKey: (state, action) => {
            state.activeKey = action.payload;
        },
        setEditVehicleAddress: (state, action) => {
            state.editVehicleAddressId = action.payload;
        },
        setDraftId: (state, action) => {
            state.draftId = action.payload;
        },
        setSelected: (state, action) => {
            state.selected = action.payload;
        },
        setWayPoints: (state, action) => {
            state.wayPoints = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getWithVehicleId.fulfilled, (state, action) => {
            state.vehicle = action?.payload?.data?.data || [];
        });
        builder.addCase(getStatuses.fulfilled, (state, action) => {
            state.statuses = action?.payload?.data?.data || [];
        });
        builder.addCase(getTypes.fulfilled, (state, action) => {
            state.vehicleTypes = action?.payload?.data?.data || [];
        });
        builder.addCase(getTypesWithKey.fulfilled, (state, action) => {
            state.typesWithKey = action?.payload?.data?.data || [];
        });
        builder.addCase(getDetailedTypes.fulfilled, (state, action) => {
            state.detailedVehicleTypes = action?.payload?.data?.data || [];
            state.isVehicleTypesLoading = false;
        });
        builder.addCase(getDetailedTypes.pending, (state, action) => {
            state.isVehicleTypesLoading = true;
        });
        builder.addCase(getDetailedTypes.rejected, (state, action) => {
            state.isVehicleTypesLoading = false;
        });
        builder.addCase(getVehicleBrands.fulfilled, (state, action) => {
            state.vehicleBrandList = action?.payload?.data?.data || [];
        });
        builder.addCase(getVehicleLastLocation.pending, (state) => {
            state.vehicleLastLocation = null;
        });
        builder.addCase(getVehicleLastLocation.rejected, (state) => {
            state.vehicleLastLocation = null;
        });
        builder.addCase(getVehicleLastLocation.fulfilled, (state, action) => {
            state.vehicleLastLocation = action?.payload?.data?.data;
        });
    }
});

export const {
    setSelectedVehicle,
    setCurrentStep,
    setActiveKey,
    setEditVehicleAddress,
    setDraftId,
    setSelected,
    setWayPoints,
} = vehicleSlice.actions;


