import dataApi from '../apis/data.api';

const getInvoicesTypes = () => {
  return dataApi.get('invoices/types');
};

const invoicesService = {
  getInvoicesTypes,
};

export default invoicesService;