import dataApi, { getQuery } from '../apis/data.api';

const get = async () => {
  return dataApi.get('account');
};

const deleteAccount = async () => {
  return dataApi.delete('account');
};

const getTypes = async () => {
  return dataApi.get('accounts/types');
};
const getBalance = async () => {
  return dataApi.get('account/balance');
};

const getBankAccounts = async (params) => {
  return dataApi.get('account/bank-accounts', { params });
};

const getPersons = async (params) => {
  return getQuery('account/persons', params);
};
const getPersonsSearch = async (params) => {
  return dataApi.get('account/persons/search', { params });
};

const getVehicles = async (params) => {
  return dataApi.get('account/vehicles', { params });
};

const getVehiclesById = async (vehicleId) => {
  return dataApi.get(`account/vehicles/${vehicleId}`);
};

const getLocations = async (params) => {
  return dataApi.get('account/locations', { params });
};

const postLocations = async (data) => {
  return dataApi.post('account/locations', data);
};

const getTaxNumbersCheck = async (taxNumber, params) => {
    return dataApi.get(`accounts/tax-numbers/${taxNumber}/check`, { params });
};

const getJoinDemands = async (params) => {
  return getQuery('account/join-demands', params);
};

const getJoinDemandStatuses = async () => {
  return dataApi.get('account/join-demand-statuses');
};

const putAsSupplier = async (data) => {
  return dataApi.put('account/as-supplier', data);
};

const putAsCustomer = async (data) => {
  return dataApi.put('account/as-customer', data);
};

const deleteJoinDemands = async (accountJoinDemandId) => {
  return dataApi.delete(`account/join-demands/${accountJoinDemandId}`);
};

const patchJoinDemands = async (accountJoinDemandId) => {
  return dataApi.patch(`account/join-demands/${accountJoinDemandId}`);
};

const getCompanyTypes = async () => {
  return dataApi.get('accounts/company-types');
};

const putLocations = async (locationId, data) => {
  return dataApi.put(`account/locations/${locationId}`, data);
};

const getInvoices = (params) => {
  return getQuery('account/invoices', params);
};

const getInvoicingChoicesLines = async () => {
  return dataApi.get('account/invoicing-choices/lines');
};

const deleteLocations = async (locationId) => {
  return dataApi.delete(`account/locations/${locationId}`);
};

const deletePerson = async (personId) => {
  return dataApi.delete(`account/persons/${personId}`);
};

const getPersonsRoles = async (personId) => {
  return dataApi.get(`account/persons/${personId}/roles`);
};

const putPersonsRoles = async (personId, data) => {
  return dataApi.put(`account/persons/${personId}/roles`, data);
};

const putPersonsType = async (personId, data) => {
  return dataApi.put(`account/persons/${personId}/type`, data);
};

const getTaxNumbersMaskedName = async (taxNumber) => {
  return dataApi.get(`tax-numbers/${taxNumber}/masked-name`);
};

const accountService = {
  get,
  deleteAccount,
  getTypes,
  getBalance,
  getBankAccounts,
  getPersons,
  getPersonsSearch,
  getVehicles,
  getVehiclesById,
  getLocations,
  postLocations,
  getTaxNumbersCheck,
  getJoinDemands,
  getJoinDemandStatuses,
  putAsSupplier,
  putAsCustomer,
  deleteJoinDemands,
  getCompanyTypes,
  putLocations,
  getInvoicingChoicesLines,
  getInvoices,
  patchJoinDemands,
  deleteLocations,
  deletePerson,
  getPersonsRoles,
  putPersonsRoles,
  putPersonsType,
  getTaxNumbersMaskedName,
};

export default accountService;
