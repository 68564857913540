import { useLocation, Navigate } from 'react-router-dom';
import { decodeToken, hasAnyRole } from 'core/utils/helpers/converter';
import { useSelector } from 'react-redux';
import { useAuth } from 'hooks/auth/use-auth';
import { RoutePaths } from 'router/paths';
import PersonType from 'core/enums/person-type';

const ProtectedRoute = (props) => {
  const location = useLocation();
  const { company } = useSelector(state => state.app);
  const { onSelectCompany } = useAuth();

  var { accountId, personId } = decodeToken('accessToken');
  var { userId } = decodeToken('refreshToken');

  if (!personId) {
    if (!userId) {
      return <Navigate to={RoutePaths.AUTH.LOGIN} replace state={{ from: location }} />;
    }

    return <Navigate to={RoutePaths.REFRESH_AUTHORIZED} replace state={{ from: location }} />;
  }

  if (props.roles && !hasAnyRole(props.roles)) {
    return <Navigate to={RoutePaths.UNAUTHORIZED} replace state={{ from: location }} />;
  }

  if (!accountId && hasAnyRole(['CustomerTemporary', 'SupplierTemporary'])) {
    return <Navigate to={RoutePaths.ACCOUNT.REGISTER_TO_COMPANY} replace state={{ from: location }} />;
  }

  if (!location.pathname.includes(company.path)) {
    onSelectCompany(company.key == PersonType.Customer ? PersonType.Supplier : PersonType.Customer);
  }

  return props.children;
};

export default ProtectedRoute;