import dataApi, { getQuery } from '../apis/data.api';

const getAuctions = async (params) => {
  return getQuery('auctions', params );
};

const getAuctionById = async (auctionId) => {
  return dataApi.get(`auctions/${auctionId}`);
};

const getSearchTemplates = async (params) => {
    return dataApi.get('search-templates', {params} );
};

const postSearchTemplates = async (data) => {
    return dataApi.post('search-templates', data );
};

const deleteSearchTemplates = async (templateId) => {
    return dataApi.delete(`search-templates/${templateId}`);
};

const putSearchTemplates = async (templateId, data) => {
    return dataApi.put(`search-templates/${templateId}`, data);
};

const auctionService = {
    getAuctions,
    getAuctionById,
    getSearchTemplates,
    postSearchTemplates,
    deleteSearchTemplates,
    putSearchTemplates
};

export default auctionService;