import authApi from '../apis/auth.api';
import walletApi from '../apis/wallet.api';
import dataApi from '../apis/data.api';

const walletLogin = async (data) => {
    return await authApi.post('connect/token', data);
};

const getCustomerPersonWallet = async () => {
    return await walletApi.get('customer-person-wallet');
};

const postCustomerPersonWallet = async (data) => {
    return await walletApi.post('customer-person-wallet', data);
};

const getQrCode = async () => {
    return await walletApi.get('customer-person-wallet/qr-code');
};

const getMerchants = async (params) => {
    return await walletApi.get('customer-person-wallet/merchants', { params });
};

const getMerchantFilters = async () => {
    return await walletApi.get('customer-person-wallet/merchant-filters');
};

const getCampaigns = async () => {
    return await walletApi.get('customer-person-wallet/campaigns');
};

const getTransactions = async (params) => {
    return await walletApi.get('customer-person-wallet/transactions', { params });
};

const getTransactionStatusTypes = async () => {
    return await walletApi.get('customer-person-wallet/transaction/status-types');
};

const postCoupon = async (data) => {
    return await walletApi.post('customer-person-wallet/coupon', data);
};

const getCoupons = async (params) => {
    return await walletApi.get('customer-person-wallet/coupons', { params });
};

const getCouponFilters = async () => {
    return await walletApi.get('customer-person-wallet/coupon-filters');
};

const getHowToUseCoupon = async (merchantId) => {
    return await walletApi.get(`customer-person-wallet/${merchantId}/how-to-use-coupon`);
};

const getLoginPoints = async (personId) => {
    return await dataApi.get(`customer-person-wallets/${personId}/login-points`);
};

const getLoginPointsInfo = async () => {
    return await dataApi.get('customer-person-wallets/login-points-info');
};

const walletService = {
    walletLogin,
    getCustomerPersonWallet,
    postCustomerPersonWallet,
    getQrCode,
    getMerchants,
    getMerchantFilters,
    getCampaigns,
    getTransactions,
    getTransactionStatusTypes,
    postCoupon,
    getCoupons,
    getCouponFilters,
    getHowToUseCoupon,
    getLoginPoints,
    getLoginPointsInfo
};

export default walletService;