import dayjs from 'dayjs';

export const MIN_DESKTOP_WIDTH = 1171;
export const isMobile = () => typeof window !== 'undefined' && window.outerWidth < MIN_DESKTOP_WIDTH;
export const isClient = () => typeof window !== 'undefined';

export const formatTime = (time) => {
    let seconds = time % 60;
    let minutes = Math.floor(time / 60);
    minutes = minutes.toString().length === 1 ? '0' + minutes : minutes;
    seconds = seconds.toString().length === 1 ? '0' + seconds : seconds;
    return minutes + ':' + seconds;
};
export function getView(width) {
    let newView = 'MobileView';
    if (width > 1220) {
        newView = 'DesktopView';
    } else if (width > 767) {
        newView = 'TabView';
    }
    return newView;
}

export const formatStringFirstLetterCapitalize = (str) => {
    const arr = str.toLowerCase().split(' ');
    for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const str1 = arr.join(' ');
    return str1;
};

export const rangeDateTime = (date1, date2) => {
    return `${dayjs(date1).format('DD.MM.YYYY HH:mm')}-${dayjs(date2).format('HH:mm')}`;
};

export const getUserDraft = () => {
    const { person } = JSON.parse(localStorage.getItem('state')) || {};
    if (person?.intro?.accountIntro) {
        const accountId = person?.intro?.accountIntro?.accountId;
        return `draft-${accountId}`;
    }
};

export const toTitleCase = (str) => {
    const titleCase = str
        .toLowerCase()
        .split(' ')
        .map(word => {
            return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(' ');

    return titleCase;
};

export const validEmail = new RegExp(
    /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/
);

export const keysToCamelCase = (o) => {
    if (isObject(o)) {
      const n = {};
  
      Object.keys(o)
        .forEach((k) => {
          n[toCamel(k)] = keysToCamel(o[k]);
        });
  
      return n;
    } else if (isArray(o)) {
      return o.map((i) => {
        return keysToCamel(i);
      });
    }
  
    return o;
  };

  export const compareTurkishCharacterEquality = (str1, str2) => {
    return str1?.replace(/İ/g, 'I').replace(/â/g, 'a').toLowerCase() === str2?.replace(/İ/g, 'I').replace(/â/g, 'a').toLowerCase();

};

export const createFrigoDegreeList = (a, b) => {
    const frigoDegreeList = [];
    for (let i = a; i <= b; i++) {
        frigoDegreeList.push(String(i));
    }
    return frigoDegreeList;
};

export const toPointString = (value, culture = 'tr-TR', fraction = 0) => {
    return new Intl.NumberFormat(culture, { minimumFractionDigits: fraction }).format(value); 
};

export const toPercentString = (value, culture = 'tr-TR', fraction = 0) => {
    return new Intl.NumberFormat(culture, { style: 'percent', minimumFractionDigits: fraction }).format(value);
};

export const toCurrencyString = (value, culture = 'tr-TR', currency ='TRY') => {
    return new Intl.NumberFormat(culture, { style: 'currency', currency: currency }).format(value);
};
