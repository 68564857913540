import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import quoteService from 'core/services/quote.service';
import dayjs from 'dayjs';

export const getLoadingTypes = createAsyncThunk(
    'getLoadingTypes',
    async (_, { rejectWithValue }) => {
        try {
            return await quoteService.getLoadingTypes();
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getFreightTypes = createAsyncThunk(
    'getFreightTypes',
    async (_, { rejectWithValue }) => {
        try {
            return await quoteService.getFreightTypes();
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getCargoTypes = createAsyncThunk(
    'getCargoTypes',
    async (_, { rejectWithValue }) => {
        try {
            return await quoteService.getCargoTypes();
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getTonnageScopes = createAsyncThunk(
    'getTonnageScopes',
    async (params, { rejectWithValue }) => {
        try {
            return await quoteService.getTonnageScopes(params);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getGoodsWorthScopes = createAsyncThunk(
    'getGoodsWorthScopes',
    async (params, { rejectWithValue }) => {
        try {
            return await quoteService.getGoodsWorthScopes(params);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getPickupDays = createAsyncThunk(
    'getPickupDays',
    async (_, { rejectWithValue }) => {
        try {
            return await quoteService.getPickupDays();
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getPickupTimes = createAsyncThunk(
    'getPickupTimes',
    async (params, { rejectWithValue }) => {
        try {
            return await quoteService.getPickupTimes(params);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const postQuotesBulk = createAsyncThunk(
    'postQuotesBulk',
    async (data, { rejectWithValue }) => {
        try {
            return await quoteService.postQuotesBulk(data);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getQuotesTemplates = createAsyncThunk(
    'getQuotesTemplates',
    async (_, { rejectWithValue }) => {
        try {
            return await quoteService.getQuotesTemplates();
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const postQuotesTemplates = createAsyncThunk(
    'postQuotesTemplates',
    async (data, { rejectWithValue }) => {
        try {
            return await quoteService.postQuotesTemplates(data);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getUnits = createAsyncThunk(
    'getUnits',
    async (_, { rejectWithValue }) => {
        try {
            return await quoteService.getUnits();
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getQuotesStatuses = createAsyncThunk(
    'getQuotesStatuses',
    async (data, { rejectWithValue }) => {
        try {
            return await quoteService.getQuotesStatuses(data);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getQuotes = createAsyncThunk(
    'getQuotes',
    async (params, { rejectWithValue }) => {
        try {
            return await quoteService.getQuotes(params);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);
export const getQuotesById = createAsyncThunk(
    'getQuotesById',
    async (quoteId, { rejectWithValue }) => {
        try {
            return await quoteService.getQuotesById(quoteId);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const deleteQuoteTemplatesById = createAsyncThunk(
    'deleteQuoteTemplatesById',
    async (quoteTemplateId, { rejectWithValue }) => {
        try {
            return await quoteService.deleteQuoteTemplatesById(quoteTemplateId);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const deleteQuotes = createAsyncThunk(
    'deleteQuotes',
    async ({ data, quoteId }, { rejectWithValue }) => {
        try {
            return await quoteService.deleteQuotes(data, quoteId);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getQuoteCount = createAsyncThunk(
    'getQuoteCount',
    async (params, { rejectWithValue }) => {
        try {
            return await quoteService.getQuoteCount(params);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const postQuotesWithExcel = createAsyncThunk(
    'postQuotesWithExcel',
    async (data, { rejectWithValue }) => {
        try {
            return await quoteService.postQuotesWithExcel(data);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getSalesDayAvailability = createAsyncThunk(
    'getSalesDayAvailability',
    async (params, { rejectWithValue }) => {
        try {
            return await quoteService.getSalesDayAvailability(params);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getQuotesOrders = createAsyncThunk(
    'getQuotesOrders',
    async (params, { rejectWithValue }) => {
        try {
            return await quoteService.getQuotesOrders(params);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getQuotesOrdersDetails = createAsyncThunk(
    'getQuotesOrdersDetails',
    async (data, { rejectWithValue }) => {
        try {
            return await quoteService.getQuotesOrdersDetails(data?.params, data?.path);
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const defaultFilteredDate = {
    startDate: dayjs().format('YYYY-MM-DD'),
    finishDate: dayjs().add(1, 'month').format('YYYY-MM-DD'),
};

const initialState = {
    loadingTypes: [],
    freightTypes: [],
    cargoTypes: [],
    tonnageScopes: [],
    goodsWorthScopes: [],
    isLoading: false,
    pickupDays: {},
    pickupTimes: {},
    postQuetesResponse: [],
    quotesTemplates: [],
    quotesTemplatesPageProperty: {},
    units: [],
    quotesStasuses: [],
    quotesList: [],
    quotesPageProperty: {},
    quoteById: {},
    filteredObject: defaultFilteredDate,
    isDetail: false,
    quoteHookFilteredObject: defaultFilteredDate,
    quoteCount: [],
    updateQuote: null,
    bulkOrders: [],
    bulkOrdersPageProperty: {},
    bulkOrdersDetails: [],
    bulkOrdersDetailsPageProperty: {},
    activeTab: 'error',
    isDetailLoading: false,
};

export const quoteSlice = createSlice({
    name: 'quote',
    initialState,
    reducers: {
        setFilteredObject: (state, action) => {
            state.filteredObject = action.payload;
        },
        setIsDetail: (state, action) => {
            state.isDetail = action.payload;
        },
        setQuoteHookFilteredObject: (state, action) => {
            state.quoteHookFilteredObject = action.payload;
        },
        setUpdateQuote: (state, action) => {
            state.updateQuote = action.payload;
        },
        setActiveTab: (state, action) => {
            state.activeTab = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getLoadingTypes.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getLoadingTypes.fulfilled, (state, action) => {
            state.loadingTypes = action?.payload?.data?.data || [];
            state.isLoading = false;
        });
        builder.addCase(getLoadingTypes.rejected, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(getFreightTypes.fulfilled, (state, action) => {
            state.freightTypes = action?.payload?.data?.data || [];
        });
        builder.addCase(getCargoTypes.fulfilled, (state, action) => {
            state.cargoTypes = action?.payload?.data?.data || [];
        });
        builder.addCase(getTonnageScopes.fulfilled, (state, action) => {
            state.tonnageScopes = action?.payload?.data?.data || [];
        });
        builder.addCase(getGoodsWorthScopes.fulfilled, (state, action) => {
            state.goodsWorthScopes = action?.payload?.data?.data || [];
        });
        builder.addCase(getPickupDays.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getPickupDays.fulfilled, (state, action) => {
            state.pickupDays = action?.payload?.data?.data || {};
            state.isLoading = false;
        });
        builder.addCase(getPickupDays.rejected, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(getPickupTimes.fulfilled, (state, action) => {
            state.pickupTimes = action?.payload?.data?.data || {};
        });
        builder.addCase(getPickupTimes.rejected, (state) => {
            state.pickupTimes = {};
        });
        builder.addCase(postQuotesBulk.fulfilled, (state, action) => {
            state.postQuetesResponse = action?.payload?.data?.data || [];
        });
        builder.addCase(getQuotesTemplates.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getQuotesTemplates.fulfilled, (state, action) => {
            state.quotesTemplates = action?.payload?.data?.data || [];
            state.quotesTemplatesPageProperty = {
                totalCount: action?.payload?.data?.totalCount,
                limit: action?.payload?.data?.limit,
                next: action?.payload?.data?.next,
                prev: action?.payload?.data?.prev,
            } || {};
            state.isLoading = false;
        });
        builder.addCase(getQuotesTemplates.rejected, (state) => {
            state.quotesTemplates = [];
            state.isLoading = false;
        });
        builder.addCase(getUnits.fulfilled, (state, action) => {
            state.units = action?.payload?.data?.data || [];
        });
        builder.addCase(getQuotesStatuses.fulfilled, (state, action) => {
            state.quotesStasuses = action?.payload?.data?.data || [];
        });
        builder.addCase(getQuotes.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getQuotes.fulfilled, (state, action) => {
            state.quotesList = action?.payload?.data?.data || [];
            state.quotesPageProperty = {
                totalCount: action?.payload?.data?.totalCount,
                limit: action?.payload?.data?.limit,
                next: action?.payload?.data?.next,
                prev: action?.payload?.data?.prev,
            } || {};
            state.isLoading = false;
        });
        builder.addCase(getQuotes.rejected, (state, action) => {
            state.quotesList = [];
            state.quotesPageProperty = {};
            state.isLoading = false;
        });
        builder.addCase(getQuotesById.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getQuotesById.fulfilled, (state, action) => {
            state.quoteById = action?.payload?.data?.data || [];
            state.isLoading = false;
        });
        builder.addCase(getQuotesById.rejected, (state, action) => {
            state.quoteById = {};
            state.isLoading = false;
        });
        builder.addCase(getQuoteCount.fulfilled, (state, action) => {
            state.quoteCount = action?.payload?.data?.data;
        });
        builder.addCase(getQuotesOrders.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getQuotesOrders.fulfilled, (state, action) => {
            state.bulkOrders = action?.payload?.data?.data || [];
            state.bulkOrdersPageProperty = {
                totalCount: action?.payload?.data?.totalCount,
                limit: action?.meta?.arg?.pageSize ?? 10,
                currentPage: action?.meta?.arg?.pageNumber ?? 1,
            } || {};
            state.isLoading = false;
        });
        builder.addCase(getQuotesOrders.rejected, (state, action) => {
            state.bulkOrders = [];
            state.isLoading = false;
        });
        builder.addCase(getQuotesOrdersDetails.pending, (state, action) => {
            state.isDetailLoading = true;
            state.bulkOrdersDetails = [];
            state.bulkOrdersDetailsPageProperty = {};
        });
        builder.addCase(getQuotesOrdersDetails.fulfilled, (state, action) => {
            state.bulkOrdersDetails = action?.payload?.data?.data || [];
            state.bulkOrdersDetailsPageProperty = {
                totalCount: action?.payload?.data?.totalCount,
                limit: action?.meta?.arg?.params?.pageSize ?? 10,
                currentPage: action?.meta?.arg?.params?.pageNumber ?? 1,
            } || {};
            state.isDetailLoading = false;
        });
        builder.addCase(getQuotesOrdersDetails.rejected, (state, action) => {
            state.bulkOrdersDetails = [];
            state.bulkOrdersDetailsPageProperty = {};
            state.isDetailLoading = false;
        });
    }
});

export const {
    setFilteredObject,
    setIsDetail,
    setQuoteHookFilteredObject,
    setUpdateQuote,
    setActiveTab
} = quoteSlice.actions;