import { useEffect } from 'react';
import { Col, Row, Space } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { EtaButton, EtaImage } from 'components/ui';
import { useDispatch, useSelector } from 'react-redux';
import { toggleCollapsed, setView } from 'store/slice/app.slice';
import { isMobile } from 'core/utils/helpers/utils';
import { HeaderWrapper } from './header.styles';
import HeaderActions from './header-actions';
import SearchBox from './search-box';
import { RoutePaths } from 'router/paths';

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { view, company } = useSelector(state => state.app);
  const { intro } = useSelector(state => state.person);
  const { isAuth } = useSelector(state => state.auth);

  const handleResize = () => {
    const mobile = isMobile();
    if (mobile) return dispatch(setView('MobileView'));
    return dispatch(setView('DesktopView'));
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
  });
  
  return (
    <HeaderWrapper className={!location?.pathname?.includes('uyelik') ? 'custom-header dashboard' : 'custom-header auth'} style={{ position: 'sticky', top: 0, zIndex: 999 }} >
      <Row justify="space-between" align="middle" gutter={12} wrap={false}>
        { isAuth && intro?.name && view === 'MobileView' &&
          <Col>
            <MenuOutlined onClick={() => dispatch(toggleCollapsed())} style={{ color: '#fff', fontSize: 24 }} />
          </Col>
        }
        <Col flex={1}>
          {
            isAuth && intro?.name
              ? <Link to={company.path} replace>
                  <EtaImage className="header-logo" source="logo.svg" />
                </Link>
              : <a href={process.env.REACT_APP_WEB_URL}>
                  <EtaImage className="header-logo" source="logo.svg" />
                </a>
          }
        </Col>
        <Col>
          { isAuth && intro?.name
              ? <Space>
                { view === 'DesktopView' && <SearchBox /> }
                <HeaderActions />
              </Space>
            : <Space>
                <EtaButton size="large" onClick={() => navigate(RoutePaths.AUTH.REGISTER)}>Kayıt Ol</EtaButton>
                {
                    view === 'DesktopView' &&
                    <EtaButton size="large" type="primary" onClick={() => navigate(RoutePaths.AUTH.LOGIN)}>Giriş
                      Yap</EtaButton>
                }
              </Space>
          }
        </Col>
      </Row>
    </HeaderWrapper>
  );
};

export default Header;