import dataApi, { getQuery } from '../apis/data.api';

const getLoadingTypes = async () => {
    return dataApi.get('quotes/loading-types');
};

const getFreightTypes = async () => {
    return dataApi.get('quotes/freight-types');
};

const getCargoTypes = async () => {
    return dataApi.get('quotes/cargo-types');
};

const getTonnageScopes = async (params) => {
    return dataApi.get('quotes/tonnage-scopes', { params });
};

const getGoodsWorthScopes = async (params) => {
    return dataApi.get('quotes/goods-worth-scopes', { params });
};

const getPickupDays = async () => {
    return dataApi.get('quotes/pickup-days');
};

const getPickupTimes = async (params) => {
    return dataApi.get('quotes/pickup-times', { params });
};

const postQuotesBulk = async (data) => {
    return dataApi.post('quotes/bulk', data);
};

const getQuotesTemplates = async () => {
    return dataApi.get('quotes/templates');
};

const postQuotesTemplates = async (data) => {
    return dataApi.post('quotes/templates', data);
};

const getUnits = async () => {
    return dataApi.get('quotes/units');
};

const getQuotesStatuses = async (data) => {
    return dataApi.post('quotes/statuses', data);
};

const getQuotes = (params) => {
    return getQuery('quotes', params);
};

const getQuotesById = async (quoteId) => {
    return dataApi.get(`quotes/${quoteId}`);
};

const deleteQuoteTemplatesById = async (quoteTemplateId) => {
    return dataApi.delete(`quotes/templates/${quoteTemplateId}`);
};

const deleteQuotes = async (data, quoteId) => {
    return dataApi.delete(`quotes/${quoteId}`, { data });
};

const getQuoteCount = async (params) => {
    return getQuery('quotes/count', params);
};

const postQuotesWithExcel = async (data) => {
    return dataApi.post('quotes/bulk-excel', data);
};
const getSalesDayAvailability = async () => {
    return dataApi.get('quotes/sales-day-availability');
};

const getQuotesOrders = async (params) => {
    return dataApi.get('quotes/orders', {params});
};

const getQuotesOrdersDetails = async (params, path) => {
    return dataApi.get(`quotes/orders/details/${path}`, {params});
};

const quoteService = {
    getLoadingTypes,
    getFreightTypes,
    getCargoTypes,
    getTonnageScopes,
    getGoodsWorthScopes,
    getPickupDays,
    getPickupTimes,
    postQuotesBulk,
    getQuotesTemplates,
    postQuotesTemplates,
    getUnits,
    getQuotesStatuses,
    getQuotes,
    getQuotesById,
    deleteQuoteTemplatesById,
    deleteQuotes,
    getQuoteCount,
    postQuotesWithExcel,
    getSalesDayAvailability,
    getQuotesOrders,
    getQuotesOrdersDetails
};

export default quoteService;