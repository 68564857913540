import { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { ConfigProvider } from 'antd';
import { Loading } from './components/layout';
import { etaTheme } from './styles/theme';
import HubsProvider from 'core/hubs/provider';
import Router from 'router';
import trTR from 'antd/lib/locale/tr_TR';
import dayjs from 'dayjs';
import 'dayjs/locale/tr';
import './styles/main.scss';

dayjs.locale('tr');

function App() {
  return (
    <ConfigProvider locale={trTR} theme={{token: etaTheme}} >
      <CookiesProvider>
        <HubsProvider>
          <BrowserRouter>
            <Suspense fallback={<Loading />}>
              <Router />
            </Suspense>
          </BrowserRouter>
        </HubsProvider>
      </CookiesProvider>
    </ConfigProvider>
  );
}

export default App;
