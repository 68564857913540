import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import invoicingService from 'core/services/invoicing.service';

export const getTypes = createAsyncThunk(
    'getTypes',
    async (_, {rejectWithValue}) => {
        try {
            return await invoicingService.getTypes();
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getConstraintsStages = createAsyncThunk(
    'getConstraintsStages',
    async (_, {rejectWithValue}) => {
        try {
            return await invoicingService.getConstraintsStages();
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getConstraintsPeriods = createAsyncThunk(
    'getConstraintsPeriods',
    async (_, {rejectWithValue}) => {
        try {
            return await invoicingService.getConstraintsPeriods();
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

export const getConstraintsDays = createAsyncThunk(
    'getConstraintsDays',
    async (_, {rejectWithValue}) => {
        try {
            return await invoicingService.getConstraintsDays();
        } catch (error) {
            return rejectWithValue(error?.errors);
        }
    }
);

const initialState = {
    types: [],
    stages: [],
    periods: [],
    days: [],
    isFetching: false,
};

export const invoicingSlice = createSlice({
    name: 'invoicing',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getTypes.pending, (state, action) => {
            state.isFetching = true;
        });
        builder.addCase(getTypes.fulfilled, (state, action) => {
            state.types = action?.payload?.data?.data || [];
            state.isFetching = false;
        });
        builder.addCase(getTypes.rejected, (state, action) => {
            state.isFetching = false;
        });
        builder.addCase(getConstraintsStages.fulfilled, (state, action) => {
            state.stages = action?.payload?.data?.data || [];
        });
        builder.addCase(getConstraintsPeriods.fulfilled, (state, action) => {
            state.periods = action?.payload?.data?.data || [];
        });
        builder.addCase(getConstraintsDays.fulfilled, (state, action) => {
            state.days = action?.payload?.data?.data || [];
        });
    }
});


